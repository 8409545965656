import arrowLeftShortDisable from '../../assets/icons/arrow-left-short-disable.svg';
import arrowLeftShortEnable from '../../assets/icons/arrow-left-short-enable.svg';
import './Button.css';

interface buttonProperties {
  arrowLeftShortIcon?: boolean;
  centerMode?: boolean;
  darkMode?: boolean;
  enable?: boolean;
  isGoBack?: boolean;
  isSubmit?: boolean;
  normalText?: boolean;
  normalWidth?: boolean;
  onClick: () => void;
  onSubmit?: (input: any) => void;
  style?: any;
  textValue: string;
}

function Button(props: buttonProperties) {
  // todo: Add a stylesheet & styles for focus/hover/active
  //   todo: fix margin
  const buttonProps = {
    className: props.normalWidth
      ? 'button button--normal-width'
      : 'button button--small-width',
    onClick: props.onClick,
    style: {
      background: props.darkMode
        ? '#085C71'
        : props.enable
        ? '#20AAC0'
        : '#DBDBDB',
      border: 0,
      type: props.isSubmit ? 'submit' : ('button' as 'submit' | 'button'),
      ...props.style,
    },
  };
  return (
    <button disabled={!props.enable} {...buttonProps}>
      {props.isGoBack ? (
        <span className={'button--icon__container left'}>
          <img
            className={'button--icon'}
            src={props.enable ? arrowLeftShortEnable : arrowLeftShortDisable}
            alt={'Arrow Left Short'}
          />
        </span>
      ) : undefined}
      <span
        style={{
          justifyContent: props.centerMode
            ? 'center'
            : props.isGoBack
            ? 'end'
            : 'start',
        }}
        className={'button--text__container'}
      >
        <span
          style={{
            color: props.darkMode
              ? '#FFFFFF'
              : props.enable
              ? '#242424'
              : '#C0C0C0',
          }}
          className={
            props.normalText
              ? 'button--text button--text__normal-font-size'
              : 'button--text button--text__small-font-size'
          }
        >
          {props.textValue}
        </span>
      </span>
      {props.arrowLeftShortIcon ? (
        <span className={'button--icon__container'}>
          <img
            className={'button--icon'}
            src={props.enable ? arrowLeftShortEnable : arrowLeftShortDisable}
            alt={'Arrow Left Short'}
          />
        </span>
      ) : undefined}
    </button>
  );
}

export default Button;
