import friedChicken from '../../assets/images/food/fried_chicken.png';
import salmon from '../../assets/images/food/salmon.png';
import macAndCheese from '../../assets/images/food/mac_and_cheese.png';
import banana from '../../assets/images/food/banana.png';
import tomato from '../../assets/images/food/tomato.png';
import bologna from '../../assets/images/food/bologna.png';
import apple from '../../assets/images/food/apple.png';
import groundBeef from '../../assets/images/food/ground_beef.png';
import candyBar from '../../assets/images/food/candy_bar.png';
import cookies from '../../assets/images/food/cookies.png';
import soda from '../../assets/images/food/soda.png';
import jelly from '../../assets/images/food/jelly.png';
import peanutButter from '../../assets/images/food/peanut_butter.png';
import cereal from '../../assets/images/food/cereal.png';
import sausage from '../../assets/images/food/sausage.png';
import egg from '../../assets/images/food/egg.png';
import potatoChips from '../../assets/images/food/potato_chips.png';
import broccoli from '../../assets/images/food/broccoli.png';
import _1Milk from '../../assets/images/food/1_milk.png';
import icebergLettuce from '../../assets/images/food/iceberg_lettuce.png';
import wheatBread from '../../assets/images/food/wheat_bread.png';
import freshGreen from '../../assets/images/food/fresh_green.png';
import carrots from '../../assets/images/food/carrots.png';
import pasta from '../../assets/images/food/pasta.png';
import tomatoSauce from '../../assets/images/food/tomato_sauce.png';
import whiteBread from '../../assets/images/food/white_bread.png';
import blackBeans from '../../assets/images/food/black_beans.png';
import cheddarCheese from '../../assets/images/food/cheddar_cheese.png';
import _juice from '../../assets/images/food/100_juice.png';
import brownRice from '../../assets/images/food/brown_rice.png';
import frozenPizza from '../../assets/images/food/frozen_pizza.png';
import blackCoffee from '../../assets/images/food/black_coffee.png';
import cannedTuna from '../../assets/images/food/canned_tuna.png';
import cannedCollard from '../../assets/images/food/canned_collard.png';
import blackEyedPeas from '../../assets/images/food/black_eyed_peas.png';
import soyMilk from '../../assets/images/food/soy_milk.png';
import onion from '../../assets/images/food/onion.png';
import { Product } from '../../types';

export const foodTiger: { products: Product[] } = {
  products: [
    {
      key: 1,
      name: 'Fried Chicken',
      calories: 259,
      fat: 15,
      saturatedFat: 10,
      cholesterol: 0.086,
      sodium: 1000,
      carbohydrates: 8,
      fiber: 0.5,
      sugar: 0,
      protein: 21,
      nutrition: {
        protein: 3,
        fat: 5,
      },
      badFoodNutrition: {
        satFat: 10,
        sodium: 1000,
      },
      price: 0.75,
      imgPath: friedChicken,
      wicType: [],
      premade: true,
      quantity: 0,
    },
    {
      key: 2,
      name: 'Salmon',
      calories: 155,
      fat: 7,
      saturatedFat: 1,
      cholesterol: 0.06,
      sodium: 570,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein: 22,
      nutrition: {
        protein: 3,
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 1,
        sodium: 85,
      },
      price: 2.19,
      imgPath: salmon,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 3,
      name: 'Mac and Cheese',
      calories: 204,
      fat: 9,
      saturatedFat: 2,
      cholesterol: 0.014,
      sodium: 570,
      carbohydrates: 24,
      fiber: 1.5,
      sugar: 6,
      protein: 8,
      nutrition: {
        protein: 1,
        fat: 2,
        grain: 3,
        dairy: 1,
      },
      badFoodNutrition: {
        satFat: 2,
        sodium: 570,
        sugar: 6,
      },
      price: 0.21,
      imgPath: macAndCheese,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 4,
      name: 'Banana',
      calories: 105,
      fat: 0.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 2,
      carbohydrates: 27,
      fiber: 3,
      sugar: 14,
      protein: 1,
      nutrition: {
        fruit: 2,
      },
      badFoodNutrition: {
        sodium: 2,
        sugar: 14,
      },
      price: 0.28,
      imgPath: banana,
      wicType: ['fruit'],
      premade: false,
      quantity: 0,
    },
    {
      key: 5,
      name: 'Tomato',
      calories: 22,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 7,
      carbohydrates: 5,
      fiber: 1.5,
      sugar: 3,
      protein: 1,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 7,
        sugar: 3,
      },
      price: 0.99,
      imgPath: tomato,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 6,
      name: 'Bologna',
      calories: 88,
      fat: 8,
      saturatedFat: 3,
      cholesterol: 0.018,
      sodium: 250,
      carbohydrates: 1,
      fiber: 0,
      sugar: 0,
      protein: 3,
      nutrition: {
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 3,
        sodium: 250,
      },
      price: 0.13,
      imgPath: bologna,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 7,
      name: 'Apple',
      calories: 95,
      fat: 0.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 1,
      carbohydrates: 25,
      fiber: 4.5,
      sugar: 11,
      protein: 0,
      nutrition: {
        fruit: 2,
      },
      badFoodNutrition: {
        sodium: 1,
        sugar: 11,
      },
      price: 0.35,
      imgPath: apple,
      wicType: ['fruit'],
      premade: false,
      quantity: 0,
    },
    {
      key: 8,
      name: 'Ground Beef',
      calories: 196,
      fat: 10,
      saturatedFat: 9,
      cholesterol: 0.076,
      sodium: 75,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein: 24,
      nutrition: {
        protein: 3,
        fat: 2,
      },
      badFoodNutrition: {
        satFat: 9,
        sodium: 75,
      },
      price: 0.84,
      imgPath: groundBeef,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 9,
      name: 'Candy Bar',
      calories: 232,
      fat: 14,
      saturatedFat: 8,
      cholesterol: 0.003,
      sodium: 35,
      carbohydrates: 25,
      fiber: 2,
      sugar: 25,
      protein: 4,
      nutrition: {
        fat: 3,
      },
      badFoodNutrition: {
        satFat: 8,
        sodium: 35,
        sugar: 25,
      },
      price: 0.61,
      imgPath: candyBar,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 10,
      name: 'Cookies',
      calories: 167,
      fat: 7,
      saturatedFat: 2,
      cholesterol: 0,
      sodium: 140,
      carbohydrates: 26,
      fiber: 1,
      sugar: 14,
      protein: 2,
      nutrition: {
        grain: 1,
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 2,
        sodium: 130,
        sugar: 14,
      },
      price: 0.15,
      imgPath: cookies,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 11,
      name: 'Soda',
      calories: 152,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 45,
      carbohydrates: 40,
      fiber: 0,
      sugar: 39,
      protein: 0,
      nutrition: {
        extra: 1,
      },
      badFoodNutrition: {
        sodium: 45,
        sugar: 39,
      },
      price: 0.39,
      imgPath: soda,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 12,
      name: 'Jelly',
      calories: 56,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 5,
      carbohydrates: 14,
      fiber: 0,
      sugar: 12,
      protein: 0,
      nutrition: {
        extra: 1,
      },
      badFoodNutrition: {
        sodium: 5,
        sugar: 12,
      },
      price: 0.04,
      imgPath: jelly,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 13,
      name: 'Peanut Butter',
      calories: 190,
      fat: 16,
      saturatedFat: 3,
      cholesterol: 0,
      sodium: 135,
      carbohydrates: 6,
      fiber: 2,
      sugar: 3,
      protein: 8,
      nutrition: {
        protein: 1,
        fat: 3,
      },
      badFoodNutrition: {
        satFat: 3,
        sodium: 135,
        sugar: 3,
      },
      price: 0.16,
      imgPath: peanutButter,
      wicType: ['protein'],
      premade: false,
      quantity: 0,
    },
    {
      key: 14,
      name: 'Cereal',
      calories: 155,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 10,
      carbohydrates: 37,
      fiber: 1,
      sugar: 12,
      protein: 2,
      nutrition: {
        grain: 2,
      },
      badFoodNutrition: {
        sodium: 10,
        sugar: 12,
      },
      price: 0.19,
      imgPath: cereal,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 15,
      name: 'Sausage',
      calories: 286,
      fat: 23,
      saturatedFat: 5,
      cholesterol: 0.047,
      sodium: 410,
      carbohydrates: 3.5,
      fiber: 0,
      sugar: 0,
      protein: 16,
      nutrition: {
        protein: 2,
        fat: 4,
      },
      badFoodNutrition: {
        satFat: 5,
        sodium: 410,
      },
      price: 0.66,
      imgPath: sausage,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 16,
      name: 'Egg',
      calories: 91,
      fat: 7,
      saturatedFat: 1,
      cholesterol: 0.169,
      sodium: 70,
      carbohydrates: 1,
      fiber: 0,
      sugar: 0,
      protein: 6,
      nutrition: {
        protein: 1,
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 1,
        sodium: 70,
      },
      price: 0.16,
      imgPath: egg,
      wicType: ['protein'],
      premade: false,
      quantity: 0,
    },
    {
      key: 17,
      name: 'Potato Chips',
      calories: 154,
      fat: 10,
      saturatedFat: 2,
      cholesterol: 0,
      sodium: 210,
      carbohydrates: 14,
      fiber: 1,
      sugar: 1,
      protein: 2,
      nutrition: {
        fat: 2,
      },
      badFoodNutrition: {
        satFat: 2,
        sodium: 210,
        sugar: 1,
      },
      price: 0.14,
      imgPath: potatoChips,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 18,
      name: 'Broccoli',
      calories: 31,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 30,
      carbohydrates: 6,
      fiber: 2.5,
      sugar: 1,
      protein: 2.5,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 30,
        sugar: 1,
      },
      price: 0.28,
      imgPath: broccoli,
      wicType: ['vegetable'],
      premade: false,
      quantity: 0,
    },
    {
      key: 19,
      name: '1% Milk',
      calories: 102,
      fat: 2,
      saturatedFat: 1,
      cholesterol: 0.012,
      sodium: 105,
      carbohydrates: 12,
      fiber: 0,
      sugar: 12,
      protein: 8,
      nutrition: {
        dairy: 1,
        protein: 1,
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 1,
        sodium: 105,
        sugar: 12,
      },
      price: 0.18,
      imgPath: _1Milk,
      wicType: ['dairy'],
      premade: false,
      quantity: 0,
    },
    {
      key: 20,
      name: 'Iceberg Lettuce',
      calories: 8,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 10,
      carbohydrates: 2,
      fiber: 1,
      sugar: 1,
      protein: 1,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 10,
        sugar: 1,
      },
      price: 0.2,
      imgPath: icebergLettuce,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 21,
      name: 'Wheat Bread',
      calories: 78,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 140,
      carbohydrates: 14,
      fiber: 1,
      sugar: 1,
      protein: 3,
      nutrition: {
        grain: 1,
      },
      badFoodNutrition: {
        sodium: 140,
        sugar: 1,
      },
      price: 0.2,
      imgPath: wheatBread,
      wicType: ['grain'],
      premade: false,
      quantity: 0,
    },
    {
      key: 22,
      name: 'Fresh Green Beans',
      calories: 31,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 5,
      carbohydrates: 7,
      fiber: 3,
      sugar: 3,
      protein: 2,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        satFat: 0,
        sodium: 5,
        sugar: 3,
      },
      price: 0.54,
      imgPath: freshGreen,
      wicType: ['vegetable'],
      premade: false,
      quantity: 0,
    },
    {
      key: 23,
      name: 'Carrots',
      calories: 52,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 42,
      carbohydrates: 12,
      fiber: 4,
      sugar: 2,
      protein: 1,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 42,
        sugar: 2,
      },
      price: 0.25,
      imgPath: carrots,
      wicType: ['vegetable'],
      premade: false,
      quantity: 0,
    },
    {
      key: 24,
      name: 'Pasta',
      calories: 221,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 43,
      fiber: 2.5,
      sugar: 2,
      protein: 8,
      nutrition: {
        grain: 2,
      },
      badFoodNutrition: {
        sugar: 2,
      },
      price: 0.1,
      imgPath: pasta,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 25,
      name: 'Tomato Sauce',
      calories: 90,
      fat: 3,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 280,
      carbohydrates: 13,
      fiber: 4,
      sugar: 2,
      protein: 2,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 280,
        sugar: 2,
      },
      price: 0.51,
      imgPath: tomatoSauce,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 26,
      name: 'White Bread',
      calories: 80,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 180,
      carbohydrates: 15,
      fiber: 1,
      sugar: 5,
      protein: 3,
      nutrition: {
        grain: 1,
      },
      badFoodNutrition: {
        sodium: 180,
        sugar: 5,
      },
      price: 0.07,
      imgPath: whiteBread,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 27,
      name: 'Black Beans',
      calories: 164,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 30,
      fiber: 12,
      sugar: 1,
      protein: 11,
      nutrition: {
        protein: 1,
        vegetable: 1,
      },
      badFoodNutrition: {
        sugar: 1,
      },
      price: 0.12,
      imgPath: blackBeans,
      wicType: ['protein'],
      premade: false,
      quantity: 0,
    },
    {
      key: 28,
      name: 'Cheddar Cheese',
      calories: 171,
      fat: 14,
      saturatedFat: 6,
      cholesterol: 0.045,
      sodium: 200,
      carbohydrates: 1,
      fiber: 0,
      sugar: 0,
      protein: 10.5,
      nutrition: {
        dairy: 1,
        protein: 1,
        fat: 3,
      },
      badFoodNutrition: {
        satFat: 6,
        sodium: 200,
      },
      price: 0.64,
      imgPath: cheddarCheese,
      wicType: ['dairy'],
      premade: false,
      quantity: 0,
    },
    {
      key: 29,
      name: '100% Juice',
      calories: 114,
      fat: 0.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 20,
      carbohydrates: 28,
      fiber: 0.5,
      sugar: 27,
      protein: 0,
      nutrition: {
        fruit: 1,
      },
      badFoodNutrition: {
        sodium: 20,
        sugar: 27,
      },
      price: 0.29,
      imgPath: _juice,
      wicType: ['fruit'],
      premade: false,
      quantity: 0,
    },
    {
      key: 30,
      name: 'Brown Rice',
      calories: 218,
      fat: 2,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0.002,
      carbohydrates: 46,
      fiber: 3.5,
      sugar: 0,
      protein: 4.5,
      nutrition: {
        grain: 3,
      },
      price: 0.29,
      imgPath: brownRice,
      wicType: ['grain'],
      premade: false,
      quantity: 0,
    },
    {
      key: 31,
      name: 'Frozen Pizza',
      calories: 533,
      fat: 24,
      saturatedFat: 8,
      cholesterol: 28,
      sodium: 940,
      carbohydrates: 58,
      fiber: 4,
      sugar: 2,
      protein: 21,
      nutrition: {
        grain: 4,
        fat: 5,
        protein: 3,
      },
      badFoodNutrition: {
        satFat: 8,
        sodium: 940,
        sugar: 2,
      },
      price: 0.93,
      imgPath: frozenPizza,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 32,
      name: 'Black Coffee',
      calories: 0,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein: 0,
      nutrition: {
        extra: 1,
      },
      price: 0.21,
      imgPath: blackCoffee,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 33,
      name: 'Canned Tuna',
      calories: 158,
      fat: 7,
      saturatedFat: 0,
      cholesterol: 0.026,
      sodium: 270,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein: 23,
      nutrition: {
        protein: 3,
      },
      badFoodNutrition: {
        sodium: 270,
      },
      price: 0.56,
      imgPath: cannedTuna,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 34,
      name: 'Canned Collard Greens',
      calories: 40,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 720,
      carbohydrates: 7,
      fiber: 2,
      sugar: 4,
      protein: 2,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 720,
        sugar: 4,
      },
      price: 0.25,
      imgPath: cannedCollard,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 35,
      name: 'Oatmeal',
      calories: 150,
      fat: 3,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 27,
      fiber: 4,
      sugar: 1,
      protein: 5,
      nutrition: {
        grain: 2,
      },
      badFoodNutrition: {
        sugar: 1,
      },
      price: 0.14,
      imgPath: brownRice,
      wicType: ['grain'],
      premade: false,
      quantity: 0,
    },
    {
      key: 36,
      name: 'Black Eyed Peas',
      calories: 70,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 300,
      carbohydrates: 13,
      fiber: 4,
      sugar: 2,
      protein: 5,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 300,
        sugar: 2,
      },
      price: 0.32,
      imgPath: blackEyedPeas,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 37,
      name: 'Soy Milk',
      calories: 110,
      fat: 4.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 95,
      carbohydrates: 9,
      fiber: 2,
      sugar: 6,
      protein: 8,
      nutrition: {
        dairy: 1,
      },
      badFoodNutrition: {
        sodium: 95,
        sugar: 6,
      },
      price: 0.54,
      imgPath: soyMilk,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 38,
      name: 'Onion',
      calories: 34,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 3,
      carbohydrates: 8.5,
      fiber: 2,
      sugar: 3,
      protein: 1,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 3,
        sugar: 3,
      },
      price: 0.22,
      imgPath: onion,
      wicType: ['vegetable'],
      premade: false,
      quantity: 0,
    },
  ],
};
