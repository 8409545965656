import { useEffect, useRef, useState } from 'react';
import SessionManager from '../../assets/classes/SessionManager';
import {
  getNutritionConsumed,
  parsePlayer,
} from '../../assets/functions/PlayerFunctions';
import './Dashboard.css';
import timeProgressLine0 from '../../assets/icons/time-progress-line_0.png';
import timeProgressLine25 from '../../assets/icons/time-progress-line_25.png';
import timeProgressLine50 from '../../assets/icons/time-progress-line_50.png';
import timeProgressLine75 from '../../assets/icons/time-progress-line_75.png';
import timeProgressLine100 from '../../assets/icons/time-progress-line_100.png';

function TimeCheck(props: any) {
  const [hour, setHour] = useState(SessionManager.getHour());
  const [secs, setMinute] = useState(SessionManager.getMinute());
  const [time, setTime] = useState(SessionManager.getTime());
  const [timeToRepairCar, setTimeToRepairCar] = useState<number>(180);
  const carCouldBreakDown = useRef(Math.random() < 0.5);
  const [
    waitingTimeToReportCarOutOfService,
    setWaitingTimeToReportCarOutOfService,
  ] = useState<number>(Math.round(Math.random() * (180 - 60) + 60));
  let count = 0;

  useEffect(() => {
    const timerId = setInterval(() => {
      count++;
      const isTrueSet = SessionManager.isActive()
        ? SessionManager.isActive()
        : false;
      setTime(SessionManager.getTime());
      if (hour === 5 && !props.warningTime) {
        props.setWarningTime(true);
        props.setDisplayGameOver(true);
      }
      if (SessionManager.getIsOver() && !props.timeIsUp) {
        props.setTimeIsUp(true);
        props.setDisplayGameOver(true);
        SessionManager.setMinute(0);
        setHour(SessionManager.getHour());
        setMinute(0);
        SessionManager.setActive(false);
        return;
      }
      if (count % 2 === 0) {
        if (isTrueSet) {
          const player = parsePlayer(sessionStorage.getItem('player'));

          if (
            Number(
              getNutritionConsumed(
                parsePlayer(sessionStorage.getItem('player'))
              )
            ) >= 100 &&
            !props.nutrientsSuccessfullyObtained
          ) {
            props.setNutrientsSuccessfullyObtained(true);
            props.setDisplayGameOver(true);
          }
          if (SessionManager.getMinute() != 59) {
            SessionManager.setMinute(SessionManager.getMinute() + 1);
            setMinute(SessionManager.getMinute());
            setHour(SessionManager.getHour());
          } else {
            SessionManager.setMinute(0);
            setMinute(0);
            if (SessionManager.getHour() === 11) setTime('pm');
            setHour(SessionManager.getHour() + 1);
            SessionManager.setHour(SessionManager.getHour() + 1);
            sessionStorage.setItem(
              'meals',
              Number(sessionStorage.getItem('meals')) -
                Math.floor(Math.random() * 10) +
                ''
            );
            if (Number(sessionStorage.getItem('meals')) <= 0) {
              sessionStorage.setItem('meals', '0');
            }
          }

          if (
            carCouldBreakDown.current &&
            waitingTimeToReportCarOutOfService !== 0
          ) {
            setWaitingTimeToReportCarOutOfService(
              waitingTimeToReportCarOutOfService - 1
            );
          }

          if (
            player.playerInfo.hasCar &&
            carCouldBreakDown.current &&
            waitingTimeToReportCarOutOfService === 0 &&
            !props.carHasBeenRepaired
          ) {
            if (!props.carHasIssues && !props.carHasBeenRepaired) {
              props.setCarHasIssues(true);
              props.setDisplayCarModule(true);
              props.setCarEnabled(false);
            }

            if (props.carHasIssues && !props.carHasBeenRepaired) {
              setTimeToRepairCar(timeToRepairCar - 1);
            }

            if (
              props.carHasIssues &&
              timeToRepairCar < 1 &&
              !props.carHasBeenRepaired
            ) {
              props.setCarHasBeenRepaired(true);
              props.setDisplayCarModule(true);
              props.setCarEnabled(true);
            }
          }

          SessionManager.setActive(isTrueSet);
        }
      }
    }, 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div className={`dashboard__time-check__pod--container ${props.class}`}>
      <div
        style={{
          backgroundImage: `url(${
            hour === 8 && time === 'am'
              ? timeProgressLine0
              : (hour === 9 && time === 'am') ||
                (hour === 10 && time === 'am') ||
                (hour === 11 && time === 'am')
              ? timeProgressLine25
              : (hour === 12 && time === 'pm') ||
                (hour === 1 && time === 'pm') ||
                (hour === 2 && time === 'pm')
              ? timeProgressLine50
              : (hour === 3 && time === 'pm') ||
                (hour === 4 && time === 'pm') ||
                (hour === 5 && time === 'pm')
              ? timeProgressLine75
              : timeProgressLine100
          })`,
          backgroundSize: 'cover',
        }}
        className={`dashboard__time-check__pod--sub-container ${props.class}`}
      >
        <p className={'dashboard__time-check__pod__text'}>
          {hour}:{secs < 10 && 0}
          {secs} {time}
        </p>
      </div>
    </div>
  );
}

export default TimeCheck;
