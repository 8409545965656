import SessionManager from '../../assets/classes/SessionManager';
import {
  checkVoucher,
  dateOfBirth,
  formatter,
  fullName,
  parsePlayer,
  totalAvailable,
  totalIncome,
} from '../../assets/functions/PlayerFunctions';
import CloseIcon from '../misc/CloseIcon';
import HealthStatus from '../misc/HealthStatus';
import NutritionChart from '../misc/NutritionChart';
import './LedgerSlide.css';

function LedgerSlide(props: any) {
  const player = parsePlayer(sessionStorage.getItem('player'));

  function renderSlice() {
    switch (props.name) {
      case 'NutritionCheck':
        return (
          <div
            className={
              'ledger-slide__rectangle--body ledger-slide__rectangle--body--nutrition'
            }
          >
            <h3>Nutrition status</h3>
            <div
              className={
                'ledger-slide__rectangle--body__content ledger-slide__rectangle--body__content--nutrition'
              }
            >
              <p className={'nutrition--instruction__text'}>
                Acquire the following calories and nutritious food.
              </p>
              <br />
              <NutritionChart />
              <HealthStatus status={player.playerInfo.healthState} />
            </div>
          </div>
        );
      case 'BudgetCheck':
        const budget = `$${formatter.format(Number(totalAvailable(player)))}`;
        const cash = `Cash: $${formatter.format(player.money)}`;
        const eitc = `EITC: $${formatter.format(Number(player.eitcAcquired))}`;
        const ctc = `CTC: $${formatter.format(Number(player.ctcAcquired))}`;
        const snap = `SNAP: $${formatter.format(Number(player.snapFunds))}`;

        return (
          <div className={'ledger-slide__rectangle--body'}>
            <h3>Budget</h3>
            <div className={'ledger-slide__rectangle--body__content'}>
              <div
                className={
                  'ledger-slide__rectangle--body__content__budget--container'
                }
              >
                <h1>{budget}</h1>
                <div
                  className={
                    'ledger-slide__rectangle--body__content__budget--cash-snap-total--container'
                  }
                >
                  <p className={'board-overview--cash-snap-total'}>{cash}</p>
                  <p className={'board-overview--cash-snap-total'}>{eitc}</p>
                  <p className={'board-overview--cash-snap-total'}>{ctc}</p>
                  <p className={'board-overview--cash-snap-total'}>
                    {player.snapAcquired > 0 ? snap : `SNAP: $ 0.00`}
                  </p>
                </div>
                <span
                  className={'board-overview--body__budget-content__line'}
                />
                <div
                  className={
                    'ledger-slide__rectangle--body__content__budget--wic-bus-ticket--container'
                  }
                >
                  <h3>WIC: {checkVoucher(player)}</h3>
                  <h3>Bus ticket: {player.busTickets}</h3>
                </div>
              </div>
              <div>
                <p
                  className={'board-overview--cash-snap-total'}
                  style={{ textAlign: 'center' }}
                >
                  <br />
                  <br />
                  WIC benefit includes one food item for each:
                  <br />
                  fruit, vegetable, grain, dairy and protein.
                </p>
              </div>
            </div>
          </div>
        );
      case 'UserCheck':
        return (
          <div className={'ledger-slide__rectangle--body'}>
            <h3 style={{ marginBottom: '1%' }}>Profile</h3>
            <div
              className={
                'ledger-slide__rectangle--body__content__user-check--container location-module__form--container'
              }
            >
              <div className={'location-module__form__group'}>
                <div className={'location-module__form__field'}>
                  <h1>Name</h1>
                  <p>{fullName(player)}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>SSN</h1>
                  <p>{player.playerInfo.ssn}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>DOB</h1>
                  <p>{dateOfBirth(player)}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>Age</h1>
                  <p>{player.playerInfo.age}</p>
                </div>
              </div>
              <div className={'location-module__form__group'}>
                <div className={'location-module__form__field'}>
                  <h1>Address</h1>
                  <p>{player.playerInfo.address}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>City</h1>
                  <p>{player.playerInfo.city}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>State</h1>
                  <p>{player.playerInfo.state}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>Zip Code</h1>
                  <p>{player.playerInfo.zip}</p>
                </div>
              </div>
              <div className={'location-module__form__group'}>
                <div className={'location-module__form__field'}>
                  <h1>Monthly Income</h1>
                  <p>{formatter.format(Number(totalIncome(player)))}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>Married</h1>
                  <p>{player.playerInfo.married ? 'Yes' : 'No'}</p>
                </div>
                <div className={'location-module__form__field'}>
                  <h1>Phone Number</h1>
                  <p>{player.playerInfo.phone}</p>
                </div>
              </div>
              <div className={'location-module__form__group'}>
                <div className={'location-module__form__field'}>
                  <h1>Description</h1>
                  <p>{player.playerInfo.fullDescription}</p>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }

  const handleClose = () => {
    props.blockLedgerSlide(false, '');

    SessionManager.setActive(true);
  };

  return (
    <div className={'ledger-slide--container'}>
      <div className={'ledger-slide__rectangle'}>
        <span className={'ledger-slide__close--icon--container'}>
          <CloseIcon name="budget ledger" onClick={handleClose} />
        </span>
        {renderSlice()}
      </div>
      <span
        className={'board-overview--background'}
        onClick={function () {
          props.blockLedgerSlide(false, '');

          SessionManager.setActive(true);
        }}
      />
    </div>
  );
}

export default LedgerSlide;
