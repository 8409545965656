import blackCoffee from '../../assets/images/food/black_coffee.png';
import frozenPizza from '../../assets/images/food/frozen_pizza.png';
import _25juice from '../../assets/images/food/25_juice.png';
import whiteBread from '../../assets/images/food/white_bread.png';
import _2Milk from '../../assets/images/food/2_milk.png';
import potatoChips from '../../assets/images/food/potato_chips.png';
import peanutButter from '../../assets/images/food/peanut_butter.png';
import soda from '../../assets/images/food/soda.png';
import cookies from '../../assets/images/food/cookies.png';
import candyBar from '../../assets/images/food/candy_bar.png';
import banana from '../../assets/images/food/banana.png';
import hotDog from '../../assets/images/food/hot_dog.png';
import hamSandwich from '../../assets/images/food/ham_sandwich.png';
import burrito from '../../assets/images/food/burrito.png';
import { Product } from '../../types';

// todo(@victoria): Remove `wic` from all products; only
export const cornerStore: { products: Product[] } = {
  products: [
    {
      key: 1,
      name: 'Black Coffee',
      calories: 0,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein: 0,
      nutrition: {
        extra: 1,
      },
      price: 0.64,
      imgPath: blackCoffee,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 2,
      name: 'Frozen Pizza',
      calories: 533,
      fat: 24,
      saturatedFat: 8,
      cholesterol: 0.028,
      sodium: 940,
      carbohydrates: 58,
      fiber: 4,
      sugar: 2,
      protein: 21,
      nutrition: {
        grain: 4,
        fat: 5,
        protein: 3,
      },
      badFoodNutrition: {
        satFat: 8,
        sodium: 940,
        sugar: 2,
      },
      price: 1.69,
      imgPath: frozenPizza,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 3,
      name: '25% Juice',
      calories: 114,
      fat: 0.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 28,
      fiber: 1,
      sugar: 20,
      protein: 0,
      nutrition: {
        fruit: 1,
      },
      badFoodNutrition: {
        sugar: 20,
      },
      price: 0.8,
      imgPath: _25juice,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 4,
      name: 'White Bread',
      calories: 80,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 180,
      carbohydrates: 15,
      fiber: 1,
      sugar: 5,
      protein: 3,
      nutrition: {
        grain: 1,
      },
      badFoodNutrition: {
        sodium: 180,
        sugar: 5,
      },
      price: 0.18,
      imgPath: whiteBread,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 5,
      name: '2% Milk',
      calories: 122,
      fat: 5,
      saturatedFat: 3,
      cholesterol: 0.02,
      sodium: 120,
      carbohydrates: 12,
      fiber: 0,
      sugar: 14,
      protein: 8,
      nutrition: {
        dairy: 1,
        protein: 1,
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 3,
        sodium: 120,
        sugar: 14,
      },
      price: 0.66,
      imgPath: _2Milk,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 6,
      name: 'Potato Chips',
      calories: 154,
      fat: 0.5,
      saturatedFat: 2,
      cholesterol: 0,
      sodium: 210,
      carbohydrates: 14.5,
      fiber: 1,
      sugar: 1,
      protein: 2,
      nutrition: {
        fat: 2,
      },
      badFoodNutrition: {
        satFat: 2,
        sodium: 210,
        sugar: 1,
      },
      price: 1.25,
      imgPath: potatoChips,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 7,
      name: 'Peanut Butter',
      calories: 190,
      fat: 16,
      saturatedFat: 3,
      cholesterol: 0,
      sodium: 135,
      carbohydrates: 6,
      fiber: 2,
      sugar: 3,
      protein: 8,
      nutrition: {
        protein: 1,
        fat: 3,
      },
      badFoodNutrition: {
        satFat: 3,
        sodium: 135,
        sugar: 3,
      },
      price: 0.34,
      imgPath: peanutButter,
      wicType: ['protein'],
      premade: false,
      quantity: 0,
    },
    {
      key: 8,
      name: 'Soda',
      calories: 152,
      fat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 45,
      carbohydrates: 40,
      fiber: 0,
      sugar: 39,
      protein: 0,
      nutrition: {
        extra: 1,
      },
      badFoodNutrition: {
        sodium: 45,
        sugar: 39,
      },
      price: 1.99,
      imgPath: soda,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 9,
      name: 'Cookies',
      calories: 167,
      fat: 7,
      saturatedFat: 2,
      cholesterol: 0,
      sodium: 130,
      carbohydrates: 25.5,
      fiber: 1,
      sugar: 14,
      protein: 2,
      nutrition: {
        grain: 1,
        fat: 1,
      },
      badFoodNutrition: {
        satFat: 2,
        sodium: 130,
        sugar: 14,
      },
      price: 2.39,
      imgPath: cookies,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 10,
      name: 'Candy Bar',
      calories: 232,
      fat: 14,
      saturatedFat: 8,
      cholesterol: 0.003,
      sodium: 35,
      carbohydrates: 25,
      fiber: 2,
      sugar: 25,
      protein: 461,
      nutrition: {
        fat: 3,
      },
      badFoodNutrition: {
        satFat: 8,
        sodium: 35,
        sugar: 25,
      },
      price: 1.29,
      imgPath: candyBar,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 11,
      name: 'Banana',
      calories: 105,
      fat: 0.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 2,
      carbohydrates: 27,
      fiber: 3,
      sugar: 14,
      protein: 1,
      nutrition: {
        fruit: 2,
      },
      badFoodNutrition: {
        sodium: 2,
        sugar: 14,
      },
      price: 0.79,
      imgPath: banana,
      wicType: ['fruit'],
      premade: false,
      quantity: 0,
    },
    {
      key: 12,
      name: 'Hot Dog',
      calories: 185,
      fat: 17,
      saturatedFat: 8,
      cholesterol: 0.034,
      sodium: 250,
      carbohydrates: 2,
      fiber: 0,
      sugar: 8,
      protein: 6,
      nutrition: {
        protein: 1,
        fat: 3,
      },
      badFoodNutrition: {
        satFat: 8,
        sodium: 250,
        sugar: 8,
      },
      price: 1.59,
      imgPath: hotDog,
      wicType: [],
      premade: true,
      quantity: 0,
    },
    {
      key: 13,
      name: 'Ham Sandwich',
      calories: 352,
      fat: 15,
      saturatedFat: 5,
      cholesterol: 0.001,
      sodium: 730,
      carbohydrates: 33,
      fiber: 0,
      sugar: 2,
      protein: 21,
      nutrition: {
        protein: 3,
        fat: 3,
        grain: 2,
        dairy: 1,
      },
      badFoodNutrition: {
        satFat: 5,
        sodium: 730,
        sugar: 2,
      },
      price: 4.29,
      imgPath: hamSandwich,
      wicType: [],
      premade: true,
      quantity: 0,
    },
    {
      key: 14,
      name: 'Burrito',
      calories: 379,
      fat: 11,
      saturatedFat: 8,
      cholesterol: 0,
      sodium: 1000,
      carbohydrates: 58,
      fiber: 8,
      sugar: 5,
      protein: 14,
      nutrition: {
        protein: 2,
        fat: 2,
        grain: 4,
        dairy: 1,
      },
      badFoodNutrition: {
        satFat: 8,
        sodium: 1000,
        sugar: 5,
      },
      price: 2.29,
      imgPath: burrito,
      wicType: [],
      premade: true,
      quantity: 0,
    },
  ],
};
