import { useEffect, useState } from 'react';
import appleIcon from './../../assets/icons/apple.svg';
import {
  parsePlayer,
  getNutritionConsumed,
} from '../../assets/functions/PlayerFunctions';
import './Dashboard.css';

function NutritionCheck(props: any) {
  const [nutritionConsumed, setNutritionConsumed] = useState(0);
  const player = parsePlayer(sessionStorage.getItem('player'));

  useEffect(() => {
    const interval = setInterval(() => {
      setNutritionConsumed(getNutritionConsumed(player));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [player]);

  return (
    <div
      className={`dashboard__nutrition-check__pod--container ${props.class}`}
      onClick={() => {
        props.activateSlide(true, 'NutritionCheck');
      }}
    >
      <div className={'dashboard__pod__icon--container'}>
        <img
          className={'dashboard__pod__icon'}
          src={appleIcon}
          alt="Apple Icon"
        />
      </div>
      <div className={'dashboard__pod__body--container'}>
        <progress max={100} value={nutritionConsumed} />
      </div>
    </div>
  );
}

export default NutritionCheck;
