import ellipseBlack from '../../assets/icons/ellipse-black.svg';
import ellipseGray from '../../assets/icons/ellipse-gray.svg';
import './Point.css';

function Point(props: any) {
  return (
    <div className={'point--container'}>
      {Array(props.numberOfPoints)
        .fill(0)
        .map((_, index) => (
          <img
            key={index}
            className={'point--circle'}
            src={index === props.currentPoint - 1 ? ellipseBlack : ellipseGray}
            alt={'Point'}
          />
        ))}
    </div>
  );
}

export default Point;
