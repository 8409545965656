import avatar from '../../assets/icons/avatar.png';
import { LocationID } from '../../constants';
import './Avatar.css';

function Avatar(props: any) {
  const avatarPositionClassName: string = (function f(): string {
    switch (props.avatarModule) {
      case LocationID.house:
        return 'avatar--position__home';
      case LocationID.communityKitchen:
        return 'avatar--position__community-kitchen';
      case LocationID.cornerStore:
        return 'avatar--position__corner-store';
      case LocationID.localNeighborhoodBusStop:
        return 'avatar--position__local-neighborhood-bus-stop';
      case LocationID.foodTiger:
        return 'avatar--position__food-tiger';
      case LocationID.foodTigerBusStop:
        return 'avatar--position__food-tiger-bus-stop';
      case LocationID.snapOffice:
        return 'avatar--position__snap-office';
      case LocationID.vitaServices:
        return 'avatar--position__vita-services';
      case LocationID.snapVitaBusStop:
        return 'avatar--position__snap-vita-bus-stop';
      case LocationID.foodPantry:
        return 'avatar--position__food-pantry';
      case LocationID.wicClinic:
        return 'avatar--position__wic-clinic';
      default:
        return 'avatar--position__pantry-wic-bus-stop';
    }
  })();

  return (
    <img
      className={`avatar--icon ${avatarPositionClassName}`}
      src={avatar}
      alt={'Avatar'}
    />
  );
}

export default Avatar;
