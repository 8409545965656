import * as React from 'react';

import {
  formatter,
  parsePlayer,
  addNutrition,
  addNutritionV2,
  persistPlayer,
  subtractCash,
  subtractSnap,
  updateVoucher,
} from '../../assets/functions/PlayerFunctions';
import { Status } from '../../assets/functions/Status';
import deselected from '../../assets/icons/deselected.svg';
import errorX from '../../assets/icons/error-x.svg';
import success from '../../assets/icons/success.svg';
import { LocationID } from '../../constants';
import Button from '../misc/Button';
import CheckmarkIcon from '../misc/CheckmarkIcon';
import CloseIcon from '../misc/CloseIcon';
import { WICNutrients } from '../../constants';
import { Product, WICNutrient, WICNutrientsType } from '../../types';
import { capitalize } from '../../utils';
import './Checkout.css';

enum CheckoutPage {
  PaymentOptions = 1,
  Checkout,
  WICCheckout,
  ActionComplete,
  WicActionComplete,
}

type Props = {
  setDisplayCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  productsForPayment: Product[];
  setDisplayStoreBuilder: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsForPayment: React.Dispatch<React.SetStateAction<Product[]>>;
  setWicCart: React.Dispatch<React.SetStateAction<any>>;
  wicCart: WICNutrientsType;
};

const Checkout: React.FC<Props> = ({
  setDisplayCheckout,
  productsForPayment,
  setDisplayStoreBuilder,
  setProductsForPayment,
  setWicCart,
  wicCart,
}) => {
  const player = parsePlayer(sessionStorage.getItem('player'));
  const [page, setPage] = React.useState<CheckoutPage>(
    CheckoutPage.PaymentOptions
  );
  const [actionMessage, setActionMessage] = React.useState<
    string | undefined
  >();
  const [actionSuccessfullyCompleted, setActionSuccessfullyCompleted] =
    React.useState<boolean>(false);
  const [paymentMethodUsed, setPaymentMethodUsed] =
    React.useState<CheckoutPage>(CheckoutPage.PaymentOptions);
  const [cashInput, setCashInput] = React.useState<number>(0);
  const [snapInput, setSnapInput] = React.useState<number>(0);

  React.useEffect(() => {
    const newWicCart = { ...wicCart };
    productsForPayment.forEach((product) => {
      if (
        product.quantity > 0 &&
        product.wicType.length > 0 &&
        checkVoucherItem(product.wicType[0])
      ) {
        newWicCart[product.wicType[0]] = true;
      }
    });
    setWicCart(newWicCart);
  }, []);

  // Here we store the products for payment.
  function getPurchaseAmount(): string {
    let purchaseAmount = 0;

    productsForPayment.forEach((product) => {
      if (product.quantity !== 0) {
        purchaseAmount += product.price * product.quantity;
      }
    });

    return purchaseAmount.toFixed(2);
  }

  function resetProductQuantity(): void {
    productsForPayment.forEach((product) => {
      product.quantity = 0;
    });
  }

  function validateNonSNAPProducts(): any {
    const nonSNAPTypes: any = [];
    productsForPayment.forEach((product) => {
      if (product.quantity > 0 && product.premade) {
        nonSNAPTypes.push(product);
      }
    });
    return nonSNAPTypes;
  }

  function renderWICVoucherButton() {
    return (
      <Button
        arrowLeftShortIcon
        enable={player.hadWic}
        normalText
        onClick={() => {
          setPage(CheckoutPage.WICCheckout);
        }}
        textValue={'WIC voucher'}
      />
    );
  }

  function renderOtherFundsButton() {
    return (
      <Button
        arrowLeftShortIcon
        enable
        normalText
        onClick={() => setPage(CheckoutPage.Checkout)}
        textValue={'Other funds'}
      />
    );
  }

  function setMessage(result: boolean) {
    if (result) {
      if (player.location === LocationID.foodPantry)
        setActionMessage('Thank you for visiting the Food Pantry.');
      else setActionMessage('You completed your purchase. Have a nice day.');
    } else {
      setActionMessage(
        'You don’t have enough funds to complete this purchase.'
      );
    }
  }

  function processPayment() {
    let result = false;

    if (
      (snapInput === 0 && cashInput === 0) ||
      (cashInput + snapInput > 0 &&
        cashInput + snapInput < Number(getPurchaseAmount()))
    ) {
      setActionSuccessfullyCompleted(false);
      setActionMessage('Total amount does not match.');
    } else if (snapInput > 0 || cashInput > 0) {
      const items = validateNonSNAPProducts();
      if (items.length > 0 && snapInput > Number(getSnapQualifiedCartTotal())) {
        setActionMessage(Status.snapOnPremade);
        setActionSuccessfullyCompleted(false);
      } else {
        if (player.snapFunds < snapInput || player.money < cashInput) {
          return false;
        }
        let total = 0.0;
        productsForPayment.map((product) =>
          product.quantity !== 0
            ? (total += product.price * product.quantity)
            : null
        );
        if (Number(total.toFixed(2)) <= cashInput + snapInput) {
          for (const i in productsForPayment) {
            if (productsForPayment[i].quantity > 0) {
              addNutrition(
                productsForPayment[i],
                player,
                productsForPayment[i].quantity
              );
            }
          }
          if (cashInput > 0 && snapInput <= 0) {
            subtractCash(total, player);
          } else if (cashInput <= 0 && snapInput > 0) {
            subtractSnap(total, player);
          } else {
            subtractCash(cashInput, player);
            subtractSnap(snapInput, player);
          }
          persistPlayer(player);
          result = true;
        }

        setActionSuccessfullyCompleted(result);
        setMessage(result);
      }
    } else {
      setActionSuccessfullyCompleted(false);
      setMessage(false);
    }

    setPaymentMethodUsed(page);
    setPage(CheckoutPage.ActionComplete);

    if (result) {
      resetProductQuantity();
    }
  }

  function renderPurchaseButton() {
    return (
      <Button
        arrowLeftShortIcon
        centerMode
        enable
        normalText
        onClick={() => {
          processPayment();
        }}
        textValue="Purchase"
      />
    );
  }

  const onClose = () => {
    resetProductQuantity();
    setDisplayCheckout(false);
  };

  function renderCloseButton(isInButtonGroup?: boolean) {
    return (
      <Button
        arrowLeftShortIcon
        centerMode={!isInButtonGroup}
        darkMode={!isInButtonGroup}
        enable
        normalText
        normalWidth={!isInButtonGroup}
        onClick={onClose}
        textValue={'Leave store'}
      />
    );
  }

  const getSnapQualifiedCartTotal: () => string = () => {
    let snapTotal = 0;
    productsForPayment.forEach((product) => {
      if (product.quantity > 0 && !product.premade) {
        snapTotal += product.price * product.quantity;
      }
    });
    return snapTotal.toFixed(2);
  };

  const balanceDue = () =>
    (Number(getPurchaseAmount()) - cashInput - snapInput).toFixed(2);

  const checkVoucherItem = (nutrient: WICNutrient) => {
    if (nutrient === WICNutrients.grain && player.voucher?.grainUsed)
      return false;
    if (nutrient === WICNutrients.protein && player.voucher?.proteinUsed)
      return false;
    if (nutrient === WICNutrients.dairy && player.voucher?.dairyUsed)
      return false;
    if (nutrient === WICNutrients.fruit && player.voucher?.fruitUsed)
      return false;
    if (nutrient === WICNutrients.vegetable && player.voucher?.vegetableUsed)
      return false;
    return true;
  };

  const processWICPayment = () => {
    const newWicCart: WICNutrientsType = { ...wicCart };
    const newProducts = [...productsForPayment];

    productsForPayment.forEach((product, index) => {
      if (
        product.quantity > 0 &&
        product.wicType.length > 0 &&
        newWicCart[product.wicType[0]] &&
        checkVoucherItem(product.wicType[0])
      ) {
        updateVoucher(product.wicType[0], player);
        newWicCart[product.wicType[0]] = false;
        addNutrition(
          productsForPayment[index],
          player,
          productsForPayment[index].quantity
        );
        player.usedWIC = true;
        persistPlayer(player);
        newProducts[index].quantity -= 1;
      }
    });
    setWicCart(newWicCart);
    setProductsForPayment(newProducts);
    setPage(CheckoutPage.WicActionComplete);
  };

  const renderCheckoutPage = () => {
    if (player.location === LocationID.foodPantry) {
      persistPlayer(addNutritionV2(productsForPayment, player));
      resetProductQuantity();

      return (
        <div
          className={
            'checkout--wrapper checkout--wrapper--justify-content--center'
          }
        >
          <div className={'board-overview--wrapper'}>
            <div className={'board-overview--body'}>
              <img
                className={'board-overview--icon'}
                src={success}
                alt="Action Complete Icon"
              />
              <div className={'board-overview--body__main-content'}>
                <h3 className={'checkout__action-complete__action-message'}>
                  Thank you for visiting the Food Pantry.
                </h3>
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--normal-height'
                }
              >
                {renderCloseButton()}
              </div>
            </div>
          </div>
        </div>
      );
    }

    switch (page) {
      case CheckoutPage.PaymentOptions:
        return (
          <div className="checkout--wrapper checkout--wrapper--half-height">
            <div className="checkout--text--container">
              <h2 className="checkout--title__text">
                Your cart total: ${getPurchaseAmount()}
              </h2>
            </div>
            <div
              className={
                'button--container--column board-overview--button--container--normal-height'
              }
            >
              <div className="button--container--row">
                <Button
                  darkMode
                  enable
                  isGoBack
                  normalText
                  onClick={() => {
                    setDisplayCheckout(false);
                    setDisplayStoreBuilder(true);
                  }}
                  textValue="Go Back"
                />
                {renderWICVoucherButton()}
                {renderOtherFundsButton()}
              </div>
            </div>
          </div>
        );
      case CheckoutPage.Checkout:
        return (
          <div className="checkout--wrapper">
            <div className="checkout--text--container">
              <h2 className="checkout--title__text">
                Your cart total: ${getPurchaseAmount()}
              </h2>
              <h2
                className={
                  Number(balanceDue()) > 0
                    ? 'checkout--balance_title__text'
                    : 'checkout--title__text'
                }
              >
                Balance still due: ${balanceDue()}
              </h2>
              <p className="checkout--instruction__text">
                How would you like to pay?
              </p>
            </div>
            <div className="checkout--snap-cash--container">
              <div>
                <p className="checkout__payment__input--title">SNAP</p>
                <input
                  className="checkout__payment__input"
                  disabled={Number(player.snapFunds) <= 0}
                  min="0"
                  onChange={(event) => {
                    setSnapInput(
                      event.target.value === '.'
                        ? 0
                        : Number(event.target.value)
                    );
                  }}
                  step=".01"
                  type="number"
                />
                <p className="checkout__payment__input--available">
                  SNAP available: ${formatter.format(player.snapFunds)}
                </p>
                <p className="checkout__payment__input--available">
                  SNAP-qualified cart: ${getSnapQualifiedCartTotal()}
                </p>
              </div>
              <div>
                <p className="checkout__payment__input--title">Cash</p>
                <input
                  className="checkout__payment__input"
                  disabled={Number(player.money) <= 0}
                  min="0"
                  onChange={(event) => {
                    setCashInput(
                      event.target.value === '.'
                        ? 0
                        : Number(event.target.value)
                    );
                  }}
                  step=".01"
                  type="number"
                />
                <p className="checkout__payment__input--available">
                  Cash available: ${formatter.format(player.money)}
                </p>
              </div>
            </div>
            <div className="button--container--row">
              <Button
                darkMode
                enable
                isGoBack
                normalText
                onClick={() => {
                  setDisplayCheckout(false);
                  setDisplayStoreBuilder(true);
                }}
                textValue="Go Back"
              />
              {renderPurchaseButton()}
            </div>
          </div>
        );
      case CheckoutPage.WICCheckout:
        return (
          <div className="checkout--wrapper">
            <div className="checkout--text--container">
              <h2 className="checkout--title__text">WIC checkout</h2>
              <p className="checkout--instruction__text">
                Redeem for one WIC food item in each category.
              </p>
            </div>
            <div>
              {Object.keys(wicCart).map((wicNutrient, index: number) => {
                const hasUsedVoucher = !checkVoucherItem(
                  wicNutrient as WICNutrient
                );

                return (
                  <div
                    className={`checkout__wic-checkout__radio-button--container${
                      hasUsedVoucher ? ' disabled' : ''
                    }`}
                    key={index}
                  >
                    <span>
                      {hasUsedVoucher || wicCart[wicNutrient as WICNutrient] ? ( // todo (@victoria): Figure out how to not have to typecast this
                        <CheckmarkIcon
                          color={hasUsedVoucher ? '#B2B3B3' : '#20AAC0'}
                        />
                      ) : (
                        <img
                          className={
                            'checkout__wic-checkout__radio-button__icon'
                          }
                          src={deselected}
                          alt={'Check Icon'}
                        />
                      )}
                    </span>
                    <p
                      className={'checkout__wic-checkout__radio-button__title'}
                    >
                      {capitalize(wicNutrient as WICNutrient)}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="checkout__wic-checkout__warning">
              <div className="checkout__wic-checkout__warning-subtext">
                Not all items on your receipt can be purchased with WIC funds.
              </div>
              <div className="checkout__wic-checkout__warning-text">
                Do you wish to purchase only WIC items above and leave items in
                your shopping cart?
              </div>
            </div>
            <div
              style={{ marginBottom: '5%' }}
              className={'button--container--row'}
            >
              <Button
                darkMode
                enable
                isGoBack
                normalText
                onClick={() => {
                  setDisplayCheckout(false);
                  setDisplayStoreBuilder(true);
                }}
                textValue={'No, go back'}
              />
              <Button
                arrowLeftShortIcon
                enable
                normalText
                normalWidth
                onClick={() => {
                  processWICPayment();
                }}
                textValue="Yes, purchase WIC items only"
              />
            </div>
          </div>
        );
      case CheckoutPage.WicActionComplete:
        return (
          <div
            className={
              'checkout--wrapper checkout--wrapper--justify-content--center'
            }
          >
            <div className={'board-overview--wrapper'}>
              <div className={'board-overview--body'}>
                <img
                  className={'board-overview--icon'}
                  src={success}
                  alt="Action Complete Icon"
                />
                <div className={'board-overview--body__main-content'}>
                  <h3 className={'checkout__action-complete__action-message'}>
                    You completed your WIC purchase.
                  </h3>
                  <p>Do you wish to go back to purchase your non-WIC items?</p>
                </div>
                <div className={'button--container--row checkout-fail'}>
                  <Button
                    darkMode
                    enable
                    isGoBack
                    normalText
                    onClick={() => {
                      setDisplayCheckout(false);
                      setDisplayStoreBuilder(true);
                    }}
                    textValue="Go back to cart"
                  />
                  {renderCloseButton(true)}
                </div>
              </div>
            </div>
          </div>
        );
      case CheckoutPage.ActionComplete:
        return (
          <div className="checkout--wrapper checkout--wrapper--justify-content--center">
            <div className="board-overview--wrapper">
              <div className="board-overview--body">
                <img
                  className="board-overview--icon"
                  src={actionSuccessfullyCompleted ? success : errorX}
                  alt="Action Complete Icon"
                />
                <div className="board-overview--body__main-content">
                  <h3 className="checkout__action-complete__action-message">
                    {actionMessage}
                  </h3>
                </div>
                <div className="button--container--row checkout-fail">
                  {actionSuccessfullyCompleted ? null : (
                    <Button
                      darkMode
                      enable
                      isGoBack
                      normalText
                      onClick={() => {
                        setPage(paymentMethodUsed);
                        setSnapInput(0);
                        setCashInput(0);
                      }}
                      textValue="Go back to cart"
                    />
                  )}
                  {renderCloseButton(true)}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  const handleClose = () => {
    resetProductQuantity();
    setDisplayCheckout(false);
  };

  return (
    <div className="checkout--container">
      <div className="location-module--wrapper__close--icon--container">
        <CloseIcon name="checkout modal" onClick={handleClose} />
      </div>
      {renderCheckoutPage()}
    </div>
  );
};

export default Checkout;
