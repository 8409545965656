import * as React from 'react';

import {
  addTime,
  calculateTravelTime,
  location,
} from '../../assets/classes/TravelCalculator';
import getPlaceByModule from '../../assets/functions/Place';
import {
  parsePlayer,
  persistPlayer,
  setLocation,
  subtractCash,
} from '../../assets/functions/PlayerFunctions';
import errorX from '../../assets/icons/error-x.svg';
import success from '../../assets/icons/success.svg';
import { LocationID, TravelType } from '../../constants';

import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import TrasportationOptions from '../misc/TransportationOptions';
import SessionManager from '../../assets/classes/SessionManager';
import './TransportationModule.css';

enum TransportationModulePage {
  Start = 1,
  Action,
  ActionComplete,
}

function TransportationModule(props: any) {
  const [page, setPage] = React.useState<TransportationModulePage>(
    TransportationModulePage.Start
  );
  const [actionMessage, setActionMessage] = React.useState<
    string | undefined
  >();
  const [actionSuccessfullyCompleted, setActionSuccessfullyCompleted] =
    React.useState<boolean>(false);
  const [reportedMishap, setReportedMishap] = React.useState<boolean>(false);
  let ttWalk = '';
  let ttCar = '';
  let tBus: number;
  let tWalk: number;
  let tCar: number;
  const player = parsePlayer(sessionStorage.getItem('player'));

  function isAvatarAtBusStop(): boolean {
    return (
      player.location === LocationID.localNeighborhoodBusStop ||
      player.location === LocationID.snapVitaBusStop ||
      player.location === LocationID.foodTigerBusStop ||
      player.location === LocationID.pantryWicBusStop
    );
  }

  function isDestinyAPlace(): boolean {
    return (
      props.module !== LocationID.localNeighborhoodBusStop &&
      props.module !== LocationID.snapVitaBusStop &&
      props.module !== LocationID.foodTigerBusStop &&
      props.module !== LocationID.pantryWicBusStop
    );
  }

  function renderProceedButton() {
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={true}
        darkMode={false}
        enable={true}
        normalText={true}
        normalWidth={true}
        onClick={() => {
          setPage(TransportationModulePage.Action);
        }}
        textValue={'Proceed'}
      />
    );
  }

  function renderContinueButton(mishap = false) {
    const handleContinueClick = () => {
      if (
        props.module === LocationID.localNeighborhoodBusStop ||
        props.module === LocationID.snapVitaBusStop ||
        props.module === LocationID.foodTigerBusStop ||
        props.module === LocationID.pantryWicBusStop
      ) {
        const probability: number = Math.random();
        const busHasIssues: boolean = probability < 0.5; // 50% of probability.

        if (
          !reportedMishap &&
          mishap &&
          busHasIssues &&
          actionSuccessfullyCompleted
        ) {
          let message: string;

          if (probability < 0.33) {
            message =
              'There is traffic on the road and now the bus is stuck. You lose 30 minutes.';
          } else if (probability < 0.66) {
            message =
              'There has been an accident on the road so now traffic is backed up. You lose 30 minutes.';
          } else {
            message =
              'The bus has broken down and is waiting for the repairman. You lose 30 minutes ';
          }

          setActionMessage(message);
          setActionSuccessfullyCompleted(false);
          setPage(TransportationModulePage.ActionComplete);
          setReportedMishap(true);
          // You lose 30 minutes.
          addTime(30);
        } else {
          props.setDisplayTransportationModule(false);
        }
      } else {
        setPage(TransportationModulePage.Action);
      }
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={true}
        darkMode={true}
        enable={true}
        normalText={true}
        normalWidth={true}
        onClick={handleContinueClick}
        textValue={'Continue'}
      />
    );
  }

  function processTrip(time: number) {
    props.setDisplayTransportationModule(false);

    setLocation(props.module);
    addTime(time);

    if (props.module === LocationID.house) {
      props.setDisplayHomeModule(true);
    } else {
      props.setDisplayPlace(true);
    }

    if (
      props.module === LocationID.localNeighborhoodBusStop ||
      props.module === LocationID.snapVitaBusStop ||
      props.module === LocationID.foodTigerBusStop ||
      props.module === LocationID.pantryWicBusStop
    ) {
      props.setShowBusInstructions(true);
    }
  }

  function renderCarButton() {
    const handleCarClick = () => {
      processTrip(tCar);
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={false}
        enable={player.playerInfo.hasCar && props.carEnabled}
        normalText={true}
        normalWidth={false}
        onClick={
          player.playerInfo.hasCar && props.carEnabled
            ? handleCarClick
            : () => {}
        }
        textValue={`Car ${ttCar}`}
      />
    );
  }

  function renderWalkButton() {
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={false}
        enable={true}
        normalText={true}
        normalWidth={false}
        onClick={() => {
          processTrip(tWalk);
        }}
        textValue={`Walk ${ttWalk}`}
      />
    );
  }

  function renderUseTicketButton() {
    const hasTickets: boolean = player.busTickets > 0;
    const handleUseTicketClick = () => {
      player.busTickets -= 1;
      persistPlayer(player);
      setActionMessage("You've used one bus ticket.");
      setActionSuccessfullyCompleted(true);
      setPage(TransportationModulePage.ActionComplete);
      addTime(tBus);
      setLocation(props.module);
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={false}
        enable={hasTickets}
        normalText={true}
        normalWidth={false}
        onClick={hasTickets ? handleUseTicketClick : () => {}}
        textValue={'Use ticket'}
      />
    );
  }

  function renderPurchaseButton() {
    const handlePurchaseClick = () => {
      const hasEnoughMoney: boolean = player.money > 2.5;

      if (hasEnoughMoney) {
        setActionMessage("You've purchased one bus ticket.");
        setActionSuccessfullyCompleted(true);
        subtractCash(2.5, player);
        persistPlayer(player);
        setLocation(props.module);
      } else {
        setActionMessage('Not enough cash.');
        setActionSuccessfullyCompleted(false);
      }

      setPage(TransportationModulePage.ActionComplete);
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={true}
        enable={true}
        normalText={true}
        normalWidth={false}
        onClick={handlePurchaseClick}
        textValue={'Purchase: $2.50'}
      />
    );
  }

  function formatTime(time: number) {
    if (time > 60) {
      return time / 60 + ' hrs';
    } else {
      return time + ' mins';
    }
  }

  function setPlaceTime() {
    tWalk = calculateTravelTime(
      player.location,
      location(props),
      TravelType.Walk
    );
    ttWalk = formatTime(tWalk);

    if (player.playerInfo.hasCar) {
      tCar = calculateTravelTime(
        player.location,
        location(props),
        TravelType.Car
      );
      ttCar = formatTime(tCar);
    }

    tBus = calculateTravelTime(
      player.location,
      location(props),
      TravelType.Bus
    );
  }

  function renderTransportationModulePage() {
    const place = getPlaceByModule(props.module);
    setPlaceTime();

    const handleClose = () => {
      props.setDisplayTransportationModule(false);
      SessionManager.setActive(true);
    };

    switch (page) {
      case TransportationModulePage.Start:
        if (props.module === player.location) {
          props.setDisplayTransportationModule(false);

          if (
            props.module === LocationID.house &&
            player.location === LocationID.house
          ) {
            props.setDisplayHomeModule(true);
          } else {
            props.setDisplayPlace(true);
          }

          return;
        }

        return (
          <div className={'board-overview--wrapper'}>
            <span className={'transportation-module__close--icon--container'}>
              <CloseIcon name="transportation module" onClick={handleClose} />
            </span>
            <div
              style={{ marginBottom: 0, marginTop: 0 }}
              className={'board-overview--body'}
            >
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                {place?.Name}
              </h1>
              <div className={'board-overview--body__text-content'}>
                <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                  {props.module === LocationID.house
                    ? `Home sweet home.`
                    : place?.Description}
                </p>
              </div>
              <div className={'board-overview--body__main-content'}>
                <img
                  className={'board-overview--icon'}
                  src={place?.Icon}
                  alt="Time Check Icon"
                />
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--normal-height'
                }
              >
                {isAvatarAtBusStop() && !isDestinyAPlace() ? (
                  <div className={'button--container--row'}>
                    {renderUseTicketButton()}
                    {renderPurchaseButton()}
                  </div>
                ) : (
                  renderProceedButton()
                )}
              </div>
            </div>
          </div>
        );
      case TransportationModulePage.Action:
        return (
          <div className={'board-overview--wrapper'}>
            <span className={'transportation-module__close--icon--container'}>
              <CloseIcon name="transportation module" onClick={handleClose} />
            </span>
            <div
              className={
                'board-overview--body board-overview--transportation-options'
              }
            >
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Transportation options
              </h1>
              <TrasportationOptions
                busContent={<p>Click on the nearest bus stop.</p>}
                busDisabled={player.busTickets === 0}
                carContent={renderCarButton()}
                carDisabled={!(player.playerInfo.hasCar && props.carEnabled)}
                showIndicators
                walkContent={renderWalkButton()}
              />
            </div>
          </div>
        );
      case TransportationModulePage.ActionComplete:
        return (
          <div className={'board-overview--wrapper'}>
            <span className={'transportation-module__close--icon--container'}>
              <CloseIcon name="transportation module" onClick={handleClose} />
            </span>
            <div className={'board-overview--body'}>
              <img
                className={'board-overview--icon'}
                src={actionSuccessfullyCompleted ? success : errorX}
                alt="Action Complete Icon"
              />
              <div
                className={
                  'board-overview--body__main-content board-overview--body__text-content'
                }
              >
                <h3
                  style={{
                    justifyContent: 'center',
                    paddingBottom: '15%',
                    textAlign: 'center',
                  }}
                >
                  {actionMessage}
                </h3>
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--normal-height'
                }
              >
                {renderContinueButton(true)}
              </div>
            </div>
          </div>
        );
    }
  }

  return (
    <div className={'board-overview--container'}>
      {renderTransportationModulePage()}
      <span className={'board-overview--background'} />
    </div>
  );
}

export default TransportationModule;
