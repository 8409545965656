export default function CarIcon({
  className,
  color,
  title,
}: {
  className: string;
  color: string;
  title: string;
}) {
  return (
    <svg
      className={className}
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <circle cx="82" cy="82" r="82" fill="#F1F1F1" />
      <path
        d="M116.109 70.9187L105.761 54.8224C103.835 51.8107 100.504 49.9917 96.9293 50H66.071C62.4968 49.9916 59.1653 51.8099 57.2397 54.8217L46.89 70.9187C44.3477 74.8712 42.9968 79.4727 43 84.172V106C43.002 107.857 43.7402 109.635 45.0527 110.948C46.3651 112.26 48.1438 112.999 50 113H57C58.8562 112.998 60.6348 112.26 61.9473 110.948C63.2598 109.635 63.9982 107.857 64 106V102.5H99V106C99.002 107.857 99.7402 109.635 101.053 110.948C102.365 112.26 104.144 112.999 106 113H113C114.856 112.998 116.635 112.26 117.947 110.948C119.26 109.635 119.998 107.857 120 106V84.167C120.003 79.469 118.652 74.8695 116.109 70.9184L116.109 70.9187ZM63.129 58.6062C63.7709 57.6036 64.881 56.9975 66.0717 57.0001H96.93C98.1214 56.9975 99.2315 57.6036 99.8733 58.6075L107.84 71H55.1612L63.129 58.6062ZM113.001 106H106.001V98.9995C106.001 98.0711 105.632 97.1812 104.975 96.5249C104.319 95.868 103.429 95.4995 102.501 95.4995H60.5006C59.5722 95.4995 58.6823 95.868 58.026 96.5249C57.3691 97.1811 57.0006 98.0711 57.0006 98.9995V106H50.0006V84.1662C50.0136 82.0569 50.4088 79.967 51.1672 77.9989H111.834C112.594 79.967 112.99 82.0569 113.003 84.1662L113.001 106Z"
        fill={color}
      />
      <path
        d="M64 86.7505C64 88.6835 62.433 90.2505 60.5 90.2505C58.567 90.2505 57 88.6834 57 86.7505C57 84.8175 58.567 83.2505 60.5 83.2505C62.433 83.2505 64 84.8175 64 86.7505Z"
        fill={color}
      />
      <path
        d="M106 86.7505C106 88.6835 104.433 90.2505 102.5 90.2505C100.567 90.2505 99 88.6834 99 86.7505C99 84.8175 100.567 83.2505 102.5 83.2505C104.433 83.2505 106 84.8175 106 86.7505Z"
        fill={color}
      />
    </svg>
  );
}
