import { useState } from 'react';
import TimeCheck from './TimeCheck';
import NutritionCheck from './NutritionCheck';
import BudgetCheck from './BudgetCheck';
import UserCheck from './UserCheck';
import Slide from './Slide';
import './Dashboard.css';
import Menu from '../menu/Menu';

function Dashboard(props: any) {
  const [displaySlide, setDisplaySlide] = useState<boolean>(false);
  const [name, setName] = useState<string | null>();

  function blockSlide(block: boolean, name?: string) {
    setDisplaySlide(block);
    setName(name);
  }

  function showLedgerInfo(block: boolean, ledgerName?: string) {
    props.showLedgerInfo(block, ledgerName);
  }

  return (
    <div className={'dashboard--container'}>
      {displaySlide ? <Slide blockSlide={blockSlide} name={name} /> : null}
      <TimeCheck
        activateSlide={showLedgerInfo}
        class={props.timeClass}
        setDisplayGameOver={props.setDisplayGameOver}
        setDisplayTransportationModule={props.setDisplayTransportationModule}
        setModule={props.setModule}
        timeIsUp={props.timeIsUp}
        setTimeIsUp={props.setTimeIsUp}
        warningTime={props.warningTime}
        setWarningTime={props.setWarningTime}
        nutrientsSuccessfullyObtained={props.nutrientsSuccessfullyObtained}
        setNutrientsSuccessfullyObtained={
          props.setNutrientsSuccessfullyObtained
        }
        setCarEnabled={props.setCarEnabled}
        setDisplayCarModule={props.setDisplayCarModule}
        carHasIssues={props.carHasIssues}
        setCarHasIssues={props.setCarHasIssues}
        carHasBeenRepaired={props.carHasBeenRepaired}
        setCarHasBeenRepaired={props.setCarHasBeenRepaired}
      />
      <NutritionCheck
        activateSlide={showLedgerInfo}
        class={props.nutritionClass}
      />
      <BudgetCheck activateSlide={showLedgerInfo} class={props.budgetClass} />
      <UserCheck activateSlide={showLedgerInfo} class={props.profileClass} />
      <Menu
        blockMenuSlide={props.blockMenuSlide}
        setCurrentMenuPage={props.setCurrentMenuPage}
        class={props.menuClass}
      />
    </div>
  );
}

export default Dashboard;
