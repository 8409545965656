import {
  parsePlayer,
  checkVoucher,
  formatter,
  totalAvailable,
} from '../../assets/functions/PlayerFunctions';
import logo from './../../assets/icons/budget.svg';
import ellipseBlue from '../../assets/icons/ellipse-blue.svg';
import ellipseOrange from '../../assets/icons/ellipse-orange.svg';
import { Player } from '../../types';

function BudgetCheck(props: any) {
  const player = parsePlayer(sessionStorage.getItem('player'));
  const budget = totalAvailable(player);
  const voucher = getVoucher(player);
  const bus = getBus(player);

  function getVoucher(player: Player) {
    return checkVoucher(player);
  }

  function getBus(player: Player) {
    return player.busTickets;
  }

  return (
    <div
      className={`dashboard__budget-check__pod--container ${props.class}`}
      onClick={() => {
        props.activateSlide(true, 'BudgetCheck');
      }}
    >
      <div className={'dashboard__pod__icon--container'}>
        <img
          className={'dashboard__pod__icon'}
          src={logo}
          alt="Currency Symbol Icon"
        />
      </div>
      <p className={'dashboard__budget-check__pod__text--normal'}>
        {formatter.format(Number(budget))}
      </p>
      <div className={'dashboard__budget-check__pod__ellipse--container'}>
        <div className={'dashboard__budget-check__pod__ellipse--sub-container'}>
          <img
            className={'dashboard__budget-check__pod__ellipse--icon'}
            src={ellipseBlue}
            alt="Ellipse Blue Icon"
          />
          <p className={'dashboard__budget-check__pod__ellipse--text'}>
            WIC: {voucher}
          </p>
        </div>
        <div className={'dashboard__budget-check__pod__ellipse--sub-container'}>
          <img
            className={'dashboard__budget-check__pod__ellipse--icon'}
            src={ellipseOrange}
            alt="Ellipse Orange Icon"
          />
          <p className={'dashboard__budget-check__pod__ellipse--text'}>
            Bus: {bus}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BudgetCheck;
