import macAndCheese from '../../assets/images/food/mac_and_cheese.png';
import whiteBread from '../../assets/images/food/white_bread.png';
import cannedTuna from '../../assets/images/food/canned_tuna.png';
import pasta from '../../assets/images/food/pasta.png';
import cannedTomato from '../../assets/images/food/canned_tomato.png';
import brownRice from '../../assets/images/food/brown_rice.png';
import blackBeans from '../../assets/images/food/black_beans.png';
import { Product } from '../../types';

export const foodPantry: { products: Product[] } = {
  products: [
    {
      key: 1,
      name: 'Mac and Cheese',
      calories: 204,
      fat: 9,
      saturatedFat: 2,
      cholesterol: 14,
      sodium: 570,
      carbohydrates: 24,
      fiber: 2,
      sugar: 6,
      protein: 8,
      nutrition: {
        protein: 1,
        fat: 2,
        grain: 3,
        dairy: 1,
      },
      badFoodNutrition: {
        satFat: 2,
        sodium: 570,
        sugar: 6,
      },
      price: 0,
      imgPath: macAndCheese,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 2,
      name: 'White Bread',
      calories: 80,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 180,
      carbohydrates: 15,
      fiber: 1,
      sugar: 5,
      protein: 3,
      nutrition: {
        grain: 1,
      },
      badFoodNutrition: {
        sodium: 180,
        sugar: 5,
      },
      price: 0,
      imgPath: whiteBread,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 3,
      name: 'Canned Tuna',
      calories: 158,
      fat: 7,
      saturatedFat: 1,
      cholesterol: 0.026,
      sodium: 270,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein: 23,
      nutrition: {
        protein: 3,
      },
      badFoodNutrition: {
        sodium: 270,
      },
      price: 0,
      imgPath: cannedTuna,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 4,
      name: 'Pasta',
      calories: 221,
      fat: 1,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 43,
      fiber: 3,
      sugar: 2,
      protein: 8,
      nutrition: {
        grain: 2,
      },
      badFoodNutrition: {
        sugar: 2,
      },
      price: 0,
      imgPath: pasta,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 5,
      name: 'Canned Tomato Sauce',
      calories: 90,
      fat: 3,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 380,
      carbohydrates: 13,
      fiber: 4,
      sugar: 2,
      protein: 2,
      nutrition: {
        vegetable: 1,
      },
      badFoodNutrition: {
        sodium: 380,
        sugar: 2,
      },
      price: 0,
      imgPath: cannedTomato,
      wicType: [],
      premade: false,
      quantity: 0,
    },
    {
      key: 6,
      name: 'Brown Rice',
      calories: 218,
      fat: 2,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 46,
      fiber: 4,
      sugar: 0,
      protein: 5,
      nutrition: {
        grain: 3,
      },
      price: 0,
      imgPath: brownRice,
      wicType: ['grain'],
      premade: false,
      quantity: 0,
    },
    {
      key: 7,
      name: 'Black Beans',
      calories: 164,
      fat: 0.5,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      carbohydrates: 30,
      fiber: 12,
      sugar: 1,
      protein: 11,
      nutrition: {
        protein: 1,
        vegetable: 1,
      },
      badFoodNutrition: {
        sugar: 1,
      },
      price: 0,
      imgPath: blackBeans,
      wicType: ['protein'],
      premade: false,
      quantity: 0,
    },
  ],
};
