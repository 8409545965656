import {
  LocationID,
  OtherNutrients,
  RequiredNutrients,
  WICNutrients,
} from './constants';

type RequiredBadFoods = {
  requiredSatFat: number;
  requiredSodium: number;
  requiredSugar: number;
};
type RequiredNutrientsType = {
  [key in RequiredNutrients]: number;
};
export type RequiredNutrient = keyof typeof RequiredNutrients;

type BadFoods = {
  satFat: number;
  sodium: number;
  sugar: number;
};
export type Nutrients = WICNutrients | OtherNutrients;
export type NutrientsType = {
  [key in Nutrients]: number;
};
export type Nutrient = keyof typeof WICNutrients | keyof typeof OtherNutrients;
export type WICNutrient = keyof typeof WICNutrients;
export type WICNutrientsType = {
  [key in WICNutrients]: boolean;
};

export type Product = {
  key: number;
  name: string;
  calories: number;
  fat: number;
  saturatedFat: number;
  cholesterol: number;
  sodium: number;
  carbohydrates: number;
  fiber: number;
  sugar: number;
  protein: number;
  nutrition: {
    [key in Nutrients]?: number;
  };
  badFoodNutrition?: {
    [key in Nutrients]?: number;
  };
  price: number;
  imgPath: string;
  wicType: WICNutrient[];
  premade: boolean;
  quantity: number;
};

export type PlayerInfo = {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: number;
  description: string;
  fullDescription: string;
  phone: string | null;
  ssn: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  primaryLanguage: string;
  age: number;
  birthMonth: number;
  birthDay: number;
  children: {
    name: string;
    age: number;
    ssn: string;
  }[];
  socialSecurityIncome: number;
  federalAssistance: boolean;
  jobIncome: number;
  additionalIncome: number;
  temporaryAssistance: number;
  numInHouse: number;
  startingBusTokens: number;
  busPass: boolean;
  hasCar: boolean;
  pregnant: boolean;
  single: boolean;
  married: boolean;
  jointTax: boolean;
  inUSSixMonth: boolean;
  allIncomeInUS: boolean;
  hourlyIncome: number;
  rentExpense: number;
  utilitiesExpense: number;
  medicalExpense: number;
  transportationExpense: number;
  phoneExpense: number;
  childcareExpense: number;
  taxesExpense: number;
  otherExpense: number;
  paysTaxes: boolean;
  healthState: string | null;
  qualifyFor: string;
} & RequiredNutrientsType &
  RequiredBadFoods;

export type Player = {
  busTickets: number;
  calories: number;
  carBrokenDown: boolean;
  hasKidBeenSick: boolean;
  communityKitchenBusTicket: boolean;
  ctcAcquired: number;
  eitcAcquired: number;
  hadWic: boolean;
  hasTemporaryRide: boolean;
  isHome: boolean;
  location: LocationID;
  money: number;
  onBus: boolean;
  playerInfo: PlayerInfo;
  snapAcquired: number;
  snapFunds: number;
  usedCommunityKitchen: boolean;
  usedFoodPantry: boolean;
  usedSnap: boolean;
  usedVita: boolean;
  usedWIC: boolean;
  vegetable: number;
  VITAServiceIsClosed: boolean;
  voucher: null | {
    fruitUsed: boolean;
    vegetableUsed: boolean;
    grainUsed: boolean;
    proteinUsed: boolean;
    dairyUsed: boolean;
  };
} & NutrientsType &
  BadFoods;

export enum MenuPage {
  Start = 1,
  Help,
  Navigation,
  Instruction,
  Restart,
  End,
}
