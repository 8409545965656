import { FundsType } from '../../constants';
import { Player } from '../../types';
import { addCash, addCTC, addEITC, addSnap } from './PlayerFunctions';

export function addFunds(player: Player, type: FundsType, amt: number): void {
  switch (type) {
    case FundsType.Cash:
      addCash(amt, player);
      break;
    case FundsType.Snap:
      addSnap(amt, player);
      break;
    case FundsType.Eitc:
      addEITC(amt, player);
      addCash(amt, player);
      break;
    case FundsType.Ctc:
      addCTC(amt, player);
      addCash(amt, player);
      break;
  }
}

export function validatePayment(
  cash: any,
  ctc: any,
  eitc: any,
  snap: any,
  totalDue: any,
  player: Player
): any {
  if (player.money < cash) {
    return 'Not enough cash.';
  } else if (player.ctcAcquired < ctc) {
    return 'Not enough CTC fund.';
  } else if (player.eitcAcquired < eitc) {
    return 'Not enough EITC fund.';
  } else if (player.snapFunds < snap) {
    return 'Not enough SNAP fund.';
  } else if (
    Number(cash + ctc + eitc + snap).toFixed(2) != Number(totalDue).toFixed(2)
  ) {
    return 'Total amount does not match';
  } else {
    return '';
  }
}
