import { parsePlayer } from '../../assets/functions/PlayerFunctions';
import { Item } from '../../interfaces/nutrition';
import './NutritionChart.css';

function NutritionChart({ className }: { className?: string }) {
  const player = parsePlayer(sessionStorage.getItem('player'));
  const items: Item[] = [
    {
      Title: 'Calories',
      CurrentScore: player.calories,
      ExpectedScore: player.playerInfo.requiredCalories,
    },
    {
      Title: 'Protein',
      CurrentScore: player.protein,
      ExpectedScore: player.playerInfo.requiredProtein,
    },
    {
      Title: 'Vegetable',
      CurrentScore: player.vegetable,
      ExpectedScore: player.playerInfo.requiredVegetable,
    },
    {
      Title: 'Fruit',
      CurrentScore: player.fruit,
      ExpectedScore: player.playerInfo.requiredFruit,
    },
    {
      Title: 'Grains',
      CurrentScore: player.grain,
      ExpectedScore: player.playerInfo.requiredGrain,
    },
    {
      Title: 'Fats',
      CurrentScore: player.fat,
      ExpectedScore: player.playerInfo.requiredFat,
    },
    {
      Title: 'Dairy',
      CurrentScore: player.dairy,
      ExpectedScore: player.playerInfo.requiredDairy,
    },
  ];

  const limits: Item[] = [
    {
      Title: 'Sugar',
      CurrentScore: player.sugar,
      ExpectedScore: player.playerInfo.requiredSugar,
    },
    {
      Title: 'Sodium',
      CurrentScore: player.sodium,
      ExpectedScore: player.playerInfo.requiredSodium,
    },
    {
      Title: 'Saturated Fats',
      CurrentScore: player.satFat,
      ExpectedScore: player.playerInfo.requiredSatFat,
    },
  ];

  return (
    <div className={className}>
      <div className={'nutrition-chart--table--container'}>
        <table className={'nutrition-chart--table'} style={{ width: '95%' }}>
          <tbody>
            {items.map((item: Item, index: number) => (
              <tr key={index}>
                <td>{item.Title}</td>
                <td className={'nutrition-chart--table--progress--container'}>
                  <div>
                    <progress
                      className={'nutrition-chart--progress'}
                      value={item.CurrentScore}
                      max={item.ExpectedScore}
                    ></progress>
                  </div>
                </td>
                <td style={{ textAlign: 'right' }}>
                  {item.CurrentScore}/{item.ExpectedScore}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className={'nutrition--instruction__text'}>
        To maintain good health, stay below these limits.
        <br />
        These limits are general; your health profile may require even lower
        limits.
      </p>
      <div className={'nutrition-chart--table--container'}>
        <table className={'nutrition-chart--table'}>
          <tbody>
            {limits.map((item: Item, index: number) => (
              <tr key={index}>
                <td>{item.Title}</td>
                <td>
                  <div>
                    <progress
                      className={`nutrition-chart--progress ${
                        item.CurrentScore >= item.ExpectedScore
                          ? 'nutrition-chart--progress-max'
                          : ''
                      }`}
                      value={item.CurrentScore}
                      max={item.ExpectedScore}
                    ></progress>
                  </div>
                </td>
                <td style={{ textAlign: 'right' }}>
                  Less than {item.ExpectedScore}{' '}
                  {item.Title === 'Sodium' ? 'mg' : 'g'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default NutritionChart;
