import end from './../../assets/icons/end.svg';
import restart from './../../assets/icons/restart.svg';
import help from './../../assets/icons/help.svg';
import ellipseOrange from '../../assets/icons/ellipse-orange.svg';
import './Menu.css';
import { MenuPage } from '../../types';

function Menu(props: any) {
  return (
    <div className={`menu-container ${props.class}`}>
      <span
        className={'menu--icon--container'}
        onClick={() => {
          props.setCurrentMenuPage(MenuPage.Help);
          props.blockMenuSlide(true);
        }}
      >
        <img
          className="menu--icon--backdrop"
          src={ellipseOrange}
          alt="Help Icon"
        />
        <img className="menu--icon" src={help} alt="Help Icon" />
      </span>
      <span
        className={'menu--icon--container'}
        onClick={() => {
          props.setCurrentMenuPage(MenuPage.End);
          props.blockMenuSlide(true);
        }}
      >
        <img
          className="menu--icon--backdrop"
          src={ellipseOrange}
          alt="Help Icon"
        />
        <img className="menu--icon" src={end} alt="End Icon" />
      </span>
      <span
        className={'menu--icon--container'}
        onClick={() => {
          props.setCurrentMenuPage(MenuPage.Restart);
          props.blockMenuSlide(true);
        }}
      >
        <img
          className="menu--icon--backdrop"
          src={ellipseOrange}
          alt="Help Icon"
        />
        <img className="menu--icon" src={restart} alt="Restart Icon" />
      </span>
    </div>
  );
}

export default Menu;
