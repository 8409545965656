import * as React from 'react';

import SessionManager from '../../assets/classes/SessionManager';
import {
  parsePlayer,
  restartGame,
} from '../../assets/functions/PlayerFunctions';

import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import './SlideMenu.css';
import { MenuPage } from '../../types';

interface Instruction {
  Title: string;
  Description1: string;
  Description2: string | null;
}

function SlideMenu(props: any) {
  const [currentInstructionIndex, setCurrentInstructionIndex] =
    React.useState<number>(0);
  const instructions: Instruction[] = [
    {
      Title: 'Purchasing food',
      Description1: `
            You can purchase food at either the Corner Store or Food Tiger.
            Select food items you wish to purchase, and add them to your cart.
            When you are ready to purchase food, click the purchase button.
            You will be given the option to pay with cash/SNAP benefits,
            or use a WIC benefit.
            `,
      Description2: `
            WIC purchases must be done separately
            from cash/SNAP purchases. Redeem your WIC benefit for one WIC food item in
            each listed category. All WIC purchases must be redeemed in one transaction.
            `,
    },
    {
      Title: 'Walking',
      Description1: `
            You can always choose to walk from one destination to the next.
            Some locations take several hours to walk between. To save time, it
            is better to use a car or bus if you’re able.
            `,
      Description2: null,
    },
    {
      Title: 'Taking a car',
      Description1: 'If you have a car, click on a location to travel there.',
      Description2: null,
    },
    {
      Title: 'Taking the Bus',
      Description1: `
            If you have bus tickets, click on the nearest bus stop to
            wait for the bus.
            Once it has arrived, you have the option to use a bus
            ticket, or purchase one bus ticket for $2.50.
            Once you have a ticket, click on the bus stop closest to
            your destination.
            `,
      Description2: `
            There are 4 Bus Stops: 1) Home 2) Food Tiger 3) SNAP
            Office/VITA Services and 4) Food Pantry/WIC Clinic. You
            can walk between the bus stop and the locations nearest
            that stop.
            `,
    },
  ];

  SessionManager.setActive(false);

  function renderMenuPage() {
    switch (props.currentMenuPage) {
      case MenuPage.Start:
        return (
          <div className={'slide-menu--wrapper'}>
            <h2 style={{ marginBottom: '4%' }}>Menu</h2>
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                props.setCurrentMenuPage(MenuPage.Help);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue="Help"
            />
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                props.setCurrentMenuPage(MenuPage.Restart);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Restart'}
            />
            <Button
              centerMode
              darkMode
              enable
              normalText
              normalWidth
              onClick={() => {
                props.setCurrentMenuPage(MenuPage.End);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'End your day'}
            />
          </div>
        );
      case MenuPage.Help:
        return (
          <div className={'slide-menu--wrapper'}>
            <h2 style={{ marginBottom: '4%' }}>Help</h2>
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                props.setCurrentMenuPage(MenuPage.Navigation);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Navigation'}
            />
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                setCurrentInstructionIndex(0);
                props.setCurrentMenuPage(MenuPage.Instruction);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Purchasing food'}
            />
            <Button
              darkMode
              centerMode
              enable
              isGoBack
              normalText
              normalWidth
              onClick={() => {
                handleClose();
              }}
              textValue="Back To Community"
            />
          </div>
        );
      case MenuPage.Restart:
        return (
          <div className={'slide-menu--wrapper'}>
            <h2 style={{ marginBottom: '4%' }}>Restart</h2>
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                restartGame(parsePlayer(sessionStorage.getItem('player')));
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Restart'}
            />
            <Button
              darkMode
              centerMode
              enable
              isGoBack
              normalText
              normalWidth
              onClick={() => {
                handleClose();
              }}
              textValue="Back To Community"
            />
          </div>
        );
      case MenuPage.End:
        const handleGameOver = () => {
          props.blockMenuSlide(false);
          props.setTimeIsUp(true);
          props.setDisplayGameOver(true);
        };
        return (
          <div className={'slide-menu--wrapper'}>
            <h2 style={{ marginBottom: '4%' }}>End Your Day</h2>
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={handleGameOver}
              style={{ marginBottom: '1.5%' }}
              textValue={'End your day'}
            />
            <Button
              darkMode
              centerMode
              enable
              isGoBack
              normalText
              normalWidth
              onClick={() => {
                handleClose();
              }}
              textValue="Back To Community"
            />
          </div>
        );
      case MenuPage.Navigation:
        return (
          <div className={'slide-menu--wrapper'}>
            <h2 style={{ marginBottom: '4%' }}>Navigation</h2>
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                setCurrentInstructionIndex(1);
                props.setCurrentMenuPage(MenuPage.Instruction);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Walking'}
            />
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                setCurrentInstructionIndex(2);
                props.setCurrentMenuPage(MenuPage.Instruction);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Taking a car'}
            />
            <Button
              centerMode
              enable
              normalText
              normalWidth
              onClick={() => {
                setCurrentInstructionIndex(3);
                props.setCurrentMenuPage(MenuPage.Instruction);
              }}
              style={{ marginBottom: '1.5%' }}
              textValue={'Taking the bus'}
            />
            <Button
              centerMode
              darkMode
              enable
              isGoBack
              normalText
              normalWidth
              onClick={() => {
                props.setCurrentMenuPage(MenuPage.Help);
              }}
              textValue="Go Back"
            />
          </div>
        );
      case MenuPage.Instruction:
        return (
          <div className={'slide-menu--wrapper'}>
            <h2 style={{ marginBottom: '2%' }}>
              {instructions[currentInstructionIndex].Title}
            </h2>
            <p
              style={{ marginBottom: '2%', textAlign: 'center', width: '70%' }}
            >
              {instructions[currentInstructionIndex].Description1}
            </p>
            {instructions[currentInstructionIndex].Description2 ===
            null ? null : (
              <p
                style={{
                  marginBottom: '2%',
                  textAlign: 'center',
                  width: '70%',
                }}
              >
                {instructions[currentInstructionIndex].Description2}
              </p>
            )}
            <Button
              centerMode
              darkMode
              enable
              isGoBack
              normalText
              normalWidth
              onClick={() => {
                if (
                  currentInstructionIndex === 1 ||
                  currentInstructionIndex === 2 ||
                  currentInstructionIndex === 3
                ) {
                  props.setCurrentMenuPage(MenuPage.Navigation);
                } else {
                  props.setCurrentMenuPage(MenuPage.Help);
                }
              }}
              textValue="Go Back"
            />
          </div>
        );
    }
  }

  const handleClose = () => {
    props.blockMenuSlide(false);
    SessionManager.setActive(true);
  };

  return (
    <div className={'board-overview--container'}>
      <div className={'side-menu--wrapper__close--icon--container'}>
        <CloseIcon name="main menu" onClick={handleClose} />
      </div>
      {renderMenuPage()}
      <span className={'slide-menu--background'} />
    </div>
  );
}

export default SlideMenu;
