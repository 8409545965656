import {
  formatter,
  monthlyCash,
  parsePlayer,
  startingCash,
  totalIncome,
} from '../../assets/functions/PlayerFunctions';

import CloseIcon from '../misc/CloseIcon';
import './BudgetPopUp.css';

interface expense {
  key: string;
  value: string;
}

function BudgetPopUp(props: any) {
  const player = parsePlayer(sessionStorage.getItem('player'));
  const expenses: expense[] = [
    {
      key: 'Rent',
      value: formatter.format(Number(player.playerInfo.rentExpense)),
    },
    {
      key: 'Utilities',
      value: formatter.format(Number(player.playerInfo.utilitiesExpense)),
    },
    {
      key: 'Medical',
      value: formatter.format(Number(player.playerInfo.medicalExpense)),
    },
    {
      key: 'Transportation',
      value: formatter.format(Number(player.playerInfo.transportationExpense)),
    },
    {
      key: 'Phone',
      value: formatter.format(Number(player.playerInfo.phoneExpense)),
    },
    {
      key: 'Childcare',
      value: formatter.format(Number(player.playerInfo.childcareExpense)),
    },
    {
      key: 'Taxes',
      value: formatter.format(Number(player.playerInfo.taxesExpense)),
    },
  ];

  const handleClose = () => {
    props.setDisplayBudgetPopUp(false);
  };

  return (
    <div className={'budget-pop-up--container'}>
      <span
        className={'board-overview--background'}
        onClick={function () {
          props.setDisplayBudgetPopUp(false);
        }}
      />
      <div className={'budget-pop-up--wrapper'}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon name="budget module" onClick={handleClose} />
        </div>
        <div className={'budget-pop-up--body'}>
          <h1 style={{ justifyContent: 'center' }}>Budget</h1>
          <table className={'budget-pop-up__table'}>
            <tbody>
              <tr style={{ color: '#085C71' }}>
                <td className={'budget-pop-up__table--cell--max-width'}>
                  Monthly Income
                </td>
                <td style={{ textAlign: 'right' }}>$</td>
                <td style={{ textAlign: 'right' }}>
                  {formatter.format(Number(totalIncome(player)))}
                </td>
              </tr>
              {expenses.map((expense: expense, index: number) => (
                <tr key={index}>
                  <td className={'budget-pop-up__table--cell--max-width'}>
                    {expense.key}
                  </td>
                  <td style={{ textAlign: 'right' }}>$</td>
                  <td style={{ textAlign: 'right' }}>{expense.value}</td>
                </tr>
              ))}
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={3}>
                  Sub total: $
                  {monthlyCash(player) > 0
                    ? formatter.format(Number(monthlyCash(player)))
                    : formatter.format(Number(0))}
                  /month
                </td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={3}>Sub total/family members/30 days</td>
              </tr>
              <tr
                style={{
                  color: '#085C71',
                  fontWeight: 600,
                  textAlign: 'center',
                }}
              >
                <td colSpan={3}>
                  ${formatter.format(Number(startingCash(player)))} individual
                  daily nutrition budget
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BudgetPopUp;
