import { LocationID, TravelType } from '../../constants';
import SessionManager from './SessionManager';

const walkScale = 6;
const carScale = 1;
export const locationMap: Map<string, number> = new Map<string, number>();
locationMap.set(LocationID.house + '-' + LocationID.communityKitchen, 2);
locationMap.set(LocationID.house + '-' + LocationID.cornerStore, 3);
locationMap.set(
  LocationID.house + '-' + LocationID.localNeighborhoodBusStop,
  3
);
locationMap.set(LocationID.house + '-' + LocationID.foodTiger, 25);
locationMap.set(LocationID.house + '-' + LocationID.foodTigerBusStop, 26);
locationMap.set(LocationID.house + '-' + LocationID.vitaServices, 20);
locationMap.set(LocationID.house + '-' + LocationID.snapOffice, 20);
locationMap.set(LocationID.house + '-' + LocationID.snapVitaBusStop, 20);
locationMap.set(LocationID.house + '-' + LocationID.foodPantry, 30);
locationMap.set(LocationID.house + '-' + LocationID.wicClinic, 31);
locationMap.set(LocationID.house + '-' + LocationID.pantryWicBusStop, 31);

locationMap.set(LocationID.communityKitchen + '-' + LocationID.cornerStore, 1);
locationMap.set(
  LocationID.communityKitchen + '-' + LocationID.localNeighborhoodBusStop,
  1
);
locationMap.set(LocationID.communityKitchen + '-' + LocationID.foodTiger, 23);
locationMap.set(
  LocationID.communityKitchen + '-' + LocationID.foodTigerBusStop,
  24
);
locationMap.set(
  LocationID.communityKitchen + '-' + LocationID.vitaServices,
  18
);
locationMap.set(LocationID.communityKitchen + '-' + LocationID.snapOffice, 18);
locationMap.set(
  LocationID.communityKitchen + '-' + LocationID.snapVitaBusStop,
  18
);
locationMap.set(LocationID.communityKitchen + '-' + LocationID.foodPantry, 28);
locationMap.set(LocationID.communityKitchen + '-' + LocationID.wicClinic, 29);
locationMap.set(
  LocationID.communityKitchen + '-' + LocationID.pantryWicBusStop,
  29
);

locationMap.set(
  LocationID.cornerStore + '-' + LocationID.localNeighborhoodBusStop,
  1
);
locationMap.set(LocationID.cornerStore + '-' + LocationID.foodTiger, 22);
locationMap.set(LocationID.cornerStore + '-' + LocationID.foodTigerBusStop, 23);
locationMap.set(LocationID.cornerStore + '-' + LocationID.vitaServices, 17);
locationMap.set(LocationID.cornerStore + '-' + LocationID.snapOffice, 17);
locationMap.set(LocationID.cornerStore + '-' + LocationID.snapVitaBusStop, 17);
locationMap.set(LocationID.cornerStore + '-' + LocationID.foodPantry, 27);
locationMap.set(LocationID.cornerStore + '-' + LocationID.wicClinic, 28);
locationMap.set(LocationID.cornerStore + '-' + LocationID.pantryWicBusStop, 28);

locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.foodTiger,
  22
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.foodTigerBusStop,
  23
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.vitaServices,
  17
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.snapOffice,
  17
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.snapVitaBusStop,
  17
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.foodPantry,
  27
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.wicClinic,
  28
);
locationMap.set(
  LocationID.localNeighborhoodBusStop + '-' + LocationID.pantryWicBusStop,
  28
);

locationMap.set(LocationID.foodTiger + '-' + LocationID.foodTigerBusStop, 1);
locationMap.set(LocationID.foodTiger + '-' + LocationID.vitaServices, 15);
locationMap.set(LocationID.foodTiger + '-' + LocationID.snapOffice, 15);
locationMap.set(LocationID.foodTiger + '-' + LocationID.snapVitaBusStop, 15);
locationMap.set(LocationID.foodTiger + '-' + LocationID.foodPantry, 16);
locationMap.set(LocationID.foodTiger + '-' + LocationID.wicClinic, 17);
locationMap.set(LocationID.foodTiger + '-' + LocationID.pantryWicBusStop, 17);

locationMap.set(
  LocationID.foodTigerBusStop + '-' + LocationID.vitaServices,
  16
);
locationMap.set(LocationID.foodTigerBusStop + '-' + LocationID.snapOffice, 16);
locationMap.set(
  LocationID.foodTigerBusStop + '-' + LocationID.snapVitaBusStop,
  16
);
locationMap.set(LocationID.foodTigerBusStop + '-' + LocationID.foodPantry, 17);
locationMap.set(LocationID.foodTigerBusStop + '-' + LocationID.wicClinic, 18);
locationMap.set(
  LocationID.foodTigerBusStop + '-' + LocationID.pantryWicBusStop,
  18
);

locationMap.set(LocationID.vitaServices + '-' + LocationID.snapOffice, 2);
locationMap.set(LocationID.vitaServices + '-' + LocationID.snapVitaBusStop, 1);
locationMap.set(LocationID.vitaServices + '-' + LocationID.foodPantry, 25);
locationMap.set(LocationID.vitaServices + '-' + LocationID.wicClinic, 26);
locationMap.set(
  LocationID.vitaServices + '-' + LocationID.pantryWicBusStop,
  26
);

locationMap.set(LocationID.snapOffice + '-' + LocationID.snapVitaBusStop, 2);
locationMap.set(LocationID.snapOffice + '-' + LocationID.foodPantry, 26);
locationMap.set(LocationID.snapOffice + '-' + LocationID.wicClinic, 27);
locationMap.set(LocationID.snapOffice + '-' + LocationID.pantryWicBusStop, 27);

locationMap.set(LocationID.snapVitaBusStop + '-' + LocationID.foodPantry, 25);
locationMap.set(LocationID.snapVitaBusStop + '-' + LocationID.wicClinic, 26);
locationMap.set(
  LocationID.snapVitaBusStop + '-' + LocationID.pantryWicBusStop,
  26
);

locationMap.set(LocationID.foodPantry + '-' + LocationID.wicClinic, 2);
locationMap.set(LocationID.foodPantry + '-' + LocationID.pantryWicBusStop, 2);

locationMap.set(LocationID.wicClinic + '-' + LocationID.pantryWicBusStop, 1);

export function calculateTravelTime(
  startLocation: LocationID,
  endLocation: LocationID,
  travelType: TravelType
): number {
  let travelTime = 0;
  if (locationMap.get(startLocation + '-' + endLocation)) {
    travelTime = Number(locationMap.get(startLocation + '-' + endLocation));
  } else if (locationMap.get(endLocation + '-' + startLocation)) {
    travelTime = Number(locationMap.get(endLocation + '-' + startLocation));
  }

  if (travelType == TravelType.Walk) {
    travelTime = travelTime * walkScale;
  } else if (travelType == TravelType.Car || travelType == TravelType.Bus) {
    travelTime = travelTime * carScale;
  }

  return travelTime;
}

export function addTime(time: number): void {
  SessionManager.setActive(false);

  const date = new Date();
  date.setHours(SessionManager.getHour(), SessionManager.getMinute());
  date.setTime(date.getTime() + time * 60 * 1000);
  const hour = date.getHours();

  SessionManager.setHour(hour);
  SessionManager.setMinute(date.getMinutes());
  SessionManager.setActive(true);
}

export function location(props: any): any {
  switch (props.module) {
    case LocationID.house:
      return LocationID.house;
    case LocationID.communityKitchen:
      return LocationID.communityKitchen;
    case LocationID.cornerStore:
      return LocationID.cornerStore;
    case LocationID.snapOffice:
      return LocationID.snapOffice;
    case LocationID.vitaServices:
      return LocationID.vitaServices;
    case LocationID.foodTiger:
      return LocationID.foodTiger;
    case LocationID.foodPantry:
      return LocationID.foodPantry;
    case LocationID.wicClinic:
      return LocationID.wicClinic;
    case LocationID.localNeighborhoodBusStop:
      return LocationID.localNeighborhoodBusStop;
    case LocationID.pantryWicBusStop:
      return LocationID.pantryWicBusStop;
    case LocationID.foodTigerBusStop:
      return LocationID.foodTigerBusStop;
    case LocationID.snapVitaBusStop:
      return LocationID.snapVitaBusStop;
  }
}
