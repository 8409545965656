import * as React from 'react';

import { addTime } from '../../assets/classes/TravelCalculator';
import { addFunds } from '../../assets/functions/CurrencyManager';
import getPlaceByModule from '../../assets/functions/Place';
import {
  addCTC,
  addEITC,
  addVoucher,
  childrenAges,
  dateOfBirth,
  formatter,
  fullName,
  parsePlayer,
  persistPlayer,
  totalIncome,
} from '../../assets/functions/PlayerFunctions';
import { Status } from '../../assets/functions/Status';
import deselected from '../../assets/icons/deselected.svg';
import errorX from '../../assets/icons/error-x.svg';
import meal from '../../assets/icons/meal.svg';
import selected from '../../assets/icons/selected.svg';
import success from '../../assets/icons/success.svg';
import { FundsType, GenderType, LocationID } from '../../constants';

import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import './LocationModule.css';

enum locationModulePage {
  Start = 1,
  Action,
  ActionComplete,
}

function LocationModule(props: any) {
  const [page, setPage] = React.useState<locationModulePage>(
    locationModulePage.Start
  );
  const [actionMessage, setActionMessage] = React.useState<
    string | undefined
  >();
  const [actionSuccessfullyCompleted, setActionSuccessfullyCompleted] =
    React.useState<boolean>(false);
  const player = parsePlayer(sessionStorage.getItem('player'));

  function renderEnterButton() {
    const handleEnterClick = () => {
      const placeIsAStore = (function (): boolean {
        return (
          props.module === LocationID.cornerStore ||
          props.module === LocationID.foodTiger
        );
      })();

      if (placeIsAStore) {
        props.setDisplayStoreBuilder(true);
        props.setDisplayLocationModule(false);
      } else {
        setPage(locationModulePage.Action);
      }
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={true}
        darkMode={false}
        enable={true}
        normalText={true}
        normalWidth={true}
        onClick={handleEnterClick}
        textValue={'Enter'}
      />
    );
  }

  const persistAndComplete = () => {
    persistPlayer(player);
    setPage(locationModulePage.ActionComplete);
  };

  function renderFreeHotMealButton() {
    const handleFreeMealClick = () => {
      checkUsedCommunityKitcken();
      persistAndComplete();
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={false}
        enable={true}
        normalText={true}
        normalWidth={false}
        onClick={handleFreeMealClick}
        textValue={'Free hot meal'}
      />
    );
  }

  function renderBusTicketButton() {
    const handleBusTicketClick = () => {
      checkCommunityKitchenBusTicket();
      persistAndComplete();
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={true}
        enable={true}
        normalText={true}
        normalWidth={false}
        onClick={handleBusTicketClick}
        textValue={'Bus ticket'}
      />
    );
  }

  function renderProcessButton() {
    const locationHasForm: boolean =
      player.location === LocationID.foodPantry ||
      player.location === LocationID.snapOffice ||
      player.location === LocationID.vitaServices ||
      player.location === LocationID.wicClinic;
    let time = '';

    if (locationHasForm) {
      switch (player.location) {
        case LocationID.foodPantry:
          time = '2 hours';
          break;
        case LocationID.snapOffice:
          time = '3 hours';
          break;
        case LocationID.vitaServices:
          time = '1 hour';
          break;
        case LocationID.wicClinic:
          time = '2 hours';
          break;
      }
    }

    const handleProcessTimeClick = () => {
      const household: number = (function (): number {
        return player.playerInfo.numInHouse;
      })();

      const childrenCounter: number = (function (): number {
        return Object.keys(player.playerInfo.children).length;
      })();

      const monthlyIncome: number = (function (): number {
        return totalIncome(player);
      })();

      let message: string;
      let supportReceived = false;
      let supportReceivedBefore = false;

      if (locationHasForm) {
        switch (player.location) {
          case LocationID.foodPantry:
            time = '2 hours';
            addTime(120);
            supportReceivedBefore = player.usedFoodPantry;

            supportReceived = (function (): boolean {
              if (supportReceivedBefore) {
                return false;
              }

              if (
                player.playerInfo.zip !== '30317' &&
                player.playerInfo.zip !== '30307'
              ) {
                return false;
              }

              player.usedFoodPantry = true;
              persistPlayer(player);

              return true;
            })();

            if (supportReceivedBefore) {
              message = `
                                You have already received food from the food pantry today. Due to the high number of
                                people seeking food and limited food, we can only help you one time today.
                                `;
            } else if (supportReceived) {
              message = `
                                You live in our service area, so your application has been approved. You may select
                                two items from here, free of cost.
                                `;
            } else {
              message = `
                                Due to high demand and limited food, we are currently only serving individuals
                                living in the 30317 and 30307 zip codes. We cannot provide you with food from our
                                pantry today.
                                `;
            }

            setActionMessage(message);
            setActionSuccessfullyCompleted(supportReceived);

            break;
          case LocationID.snapOffice:
            time = '3 hours';
            addTime(180);
            supportReceivedBefore = player.usedSnap;

            const snapReceived: number = (function (): number {
              if (supportReceivedBefore) {
                return 0;
              }

              let snap: number;

              if (household == 3 && monthlyIncome <= 280) {
                snap = 5.69;
              } else if (household == 1 && monthlyIncome <= 950) {
                snap = 3.69;
              } else if (household == 1 && monthlyIncome == 3300) {
                snap = 0;
              } else if (household == 4 && monthlyIncome == 2960) {
                snap = 0;
              } else if (household == 5 && monthlyIncome == 3680) {
                snap = 0;
              } else {
                snap = 2.19;
              }

              addFunds(player, FundsType.Snap, snap);

              player.usedSnap = true;
              persistPlayer(player);

              return snap;
            })();

            supportReceived = snapReceived > 0;

            if (supportReceivedBefore) {
              message = `You have already visited the SNAP Office today.`;
            } else if (supportReceived) {
              message = `
                                Your application has been approved. For a household of ${household} and monthly
                                income of $${formatter.format(
                                  monthlyIncome
                                )}, you have received $${formatter.format(
                snapReceived
              )} in SNAP benefits
                                that can be used to purchase any food that is not prepared and ready to eat.
                                `;
            } else {
              message = `
                                Your application has been denied. For a household of ${household} and monthly
                                income of $${formatter.format(
                                  monthlyIncome
                                )}, you make too much money to receive SNAP benefits.
                                `;
            }

            setActionMessage(message);
            setActionSuccessfullyCompleted(supportReceived);

            break;
          case LocationID.vitaServices:
            time = '1 hour';
            addTime(60);

            supportReceivedBefore = player.usedVita;

            const supportReceivedEITC: boolean = (function (): boolean {
              if (supportReceivedBefore) {
                return false;
              }

              if (!player.playerInfo.paysTaxes) {
                return false;
              } else if (
                player.playerInfo.socialSecurityIncome > 0 ||
                player.playerInfo.temporaryAssistance > 0
              ) {
                return false;
              }

              let result = true;
              switch (childrenCounter) {
                case 0:
                  if (
                    (player.playerInfo.single && monthlyIncome > 1235) ||
                    (player.playerInfo.married && monthlyIncome > 1694)
                  ) {
                    result = false;
                  }

                  break;
                case 1:
                  if (
                    (player.playerInfo.single && monthlyIncome > 3261) ||
                    (player.playerInfo.married && monthlyIncome > 3721)
                  ) {
                    result = false;
                  }

                  break;
                case 2:
                  if (
                    (player.playerInfo.single && monthlyIncome > 3705) ||
                    (player.playerInfo.married && monthlyIncome > 4165)
                  ) {
                    result = false;
                  }

                  break;
                default:
                  if (
                    (player.playerInfo.single && monthlyIncome > 3979) ||
                    (player.playerInfo.married && monthlyIncome > 4439)
                  ) {
                    result = false;
                  }

                  break;
              }
              if (player.playerInfo.temporaryAssistance > 0) {
                result = false;
              }

              return result;
            })();

            const supportReceivedCTC: boolean = (function (): boolean {
              if (player.playerInfo.temporaryAssistance > 0) {
                return false;
              }

              if (supportReceivedBefore) {
                return false;
              }

              if (!player.playerInfo.paysTaxes || childrenCounter === 0) {
                return false;
              }

              if (
                player.playerInfo.married &&
                player.playerInfo.jointTax &&
                monthlyIncome > 9167
              ) {
                return false;
              }

              if (
                player.playerInfo.married &&
                !player.playerInfo.jointTax &&
                monthlyIncome > 4583
              ) {
                return false;
              }

              if (player.playerInfo.single && monthlyIncome > 6250) {
                return false;
              }

              return true;
            })();

            const eitcReceived: number = (function (): number {
              if (!supportReceivedEITC) {
                return 0;
              }

              switch (childrenCounter) {
                case 0:
                  return 1.38;
                case 1:
                  return 4.6;
                case 2:
                  return 5;
                default:
                  return 4.25;
              }
            })();

            const ctcReceived: number = (function (): number {
              return !supportReceivedCTC ? 0 : 2.74;
            })();

            if (supportReceivedEITC) {
              addEITC(eitcReceived, player);
            }

            if (ctcReceived) {
              addCTC(ctcReceived, player);
            }

            if (supportReceivedEITC || ctcReceived) {
              player.usedVita = true;
              persistPlayer(player);
            }

            if (supportReceivedBefore) {
              message = `You have already visited VITA Services today.`;
            } else if (supportReceivedEITC && supportReceivedCTC) {
              message = `
                                Your application has been approved. For ${household} person household and monthly
                                income of $${formatter.format(
                                  monthlyIncome
                                )}, you have received $${formatter.format(
                eitcReceived
              )} in EITC benefits
                                that can be used to purchased food or bus ticket. You have also received
                                $${ctcReceived} in CTC benefits and can be used to purchased food.
                                `;
            } else if (supportReceivedEITC && !supportReceivedCTC) {
              message = `
                                Your application has been approved for EITC. For ${household} person household and
                                monthly income of $${formatter.format(
                                  monthlyIncome
                                )}, you have received $${formatter.format(
                eitcReceived
              )} in EITC
                                benefits that can be used to purchase food or bus tokens. However, you do not have
                                any children or income is too high, so do not qualify for CTC benefits.
                                `;
            } else if (!supportReceivedEITC && supportReceivedCTC) {
              message = `
                                Your application has been approved for CTC. For ${household} person household and
                                monthly income of $${formatter.format(
                                  monthlyIncome
                                )}, you have received $${formatter.format(
                ctcReceived
              )} in CTC
                                benefits and can be used to purchase food. However, you do not qualify for EITC.
                                Income must be wages from a job, so income from social security and unemployment is
                                ineligible.
                                `;
            } else if (
              !supportReceivedEITC &&
              !supportReceivedCTC &&
              player.playerInfo.temporaryAssistance > 0
            ) {
              message = `
                                Your application has been denied. You do not qualify for
                                the EITC or the CTC. You do not qualify for EITC
                                because income wages must be from a job and not
                                social security or unemployment.
                                `;
            } else if (
              !supportReceivedEITC &&
              !supportReceivedCTC &&
              player.playerInfo.lastName == 'Member 15'
            ) {
              message = `
                                Your application has been denied. 

You do not qualify for EITC because your income is too high or must be wages from a job. Income from social security and unemployment is ineligible. You do not qualify for the CTC because either you don’t have any children or your income is too high. 
                                `;
            } else if (!supportReceivedEITC && !supportReceivedCTC) {
              message = `
                                You do not qualify for
                                the CTC because either you don’t have any children or
                                your income is too high.
                                `;
            } else {
              message = `
                                Unfortunately, the Volunteer Tax Preparer for VITA Services is not available today.
                                `;
            }

            supportReceived = supportReceivedEITC || supportReceivedCTC;

            setActionMessage(message);
            setActionSuccessfullyCompleted(supportReceived);

            break;
          case LocationID.wicClinic:
            time = '2 hours';
            addTime(120);
            supportReceivedBefore = player.usedWIC;

            supportReceived = (function (): boolean {
              if (supportReceivedBefore) {
                return false;
              }

              const eligibleNumber: number = (function (): number {
                if (supportReceivedBefore) {
                  return 0;
                }

                let counter = 0;

                if (player.playerInfo.pregnant) {
                  counter++;
                }

                for (const child of player.playerInfo.children) {
                  if (child.age < 5) {
                    counter++;
                  }
                }

                return counter;
              })();

              let result = true;
              if (eligibleNumber > 0) {
                switch (household) {
                  case 2:
                    if (monthlyIncome > 2538) {
                      result = false;
                    }

                    break;
                  case 3:
                    if (monthlyIncome > 3204) {
                      result = false;
                    }

                    break;
                  case 4:
                    if (monthlyIncome > 3870) {
                      result = false;
                    }

                    break;
                  case 5:
                    if (monthlyIncome > 4536) {
                      result = false;
                    }

                    break;
                  default:
                }
              } else {
                result = false;
              }

              return result;
            })();

            if (player.playerInfo.children.length === 0) {
              supportReceived = false;
            }

            if (supportReceived) {
              player.usedWIC = true;
              persistPlayer(player);
            }

            if (supportReceived) {
              addVoucher(player);
              message = `
                                Your application has been approved. For a household of
                                ${household} that has either a pregnant woman or children under
                                the age of 5 and a monthly income of $${formatter.format(
                                  monthlyIncome
                                )}, you
                                have received a WIC benefit. The benefit can be used
                                during one transaction at participating grocery stores.
                                You can find your benefit in your wallet.
                                `;
            } else {
              message = `
                                Your application has been denied. You are either not a
                                pregnant woman, and/or you do not have at least one
                                child under the age of 5 in your household.
                                `;
            }

            setActionMessage(message);
            setActionSuccessfullyCompleted(supportReceived);

            break;
        }
      }

      setPage(locationModulePage.ActionComplete);
    };

    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={false}
        enable={true}
        normalText={false}
        normalWidth={false}
        onClick={handleProcessTimeClick}
        textValue={`Process (${time})`}
      />
    );
  }

  const closeLocationModule = () => {
    props.setDisplayLocationModule(false);
  };

  function renderCloseButton() {
    const handleClose = () => {
      if (
        player.location === LocationID.foodPantry &&
        actionSuccessfullyCompleted
      ) {
        props.setDisplayStoreBuilder(true);
      }

      closeLocationModule();
    };

    return (
      <Button
        arrowLeftShortIcon={false}
        centerMode={true}
        darkMode={false}
        enable={true}
        normalText={true}
        normalWidth={true}
        onClick={handleClose}
        textValue={'Close'}
      />
    );
  }

  function renderReturnToCommunityButton() {
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={true}
        enable={true}
        normalText={false}
        normalWidth={false}
        onClick={closeLocationModule}
        textValue={'Return to Community'}
      />
    );
  }

  function renderReturnToCommunityButtonXL() {
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={true}
        darkMode={true}
        enable={true}
        normalText={true}
        normalWidth={true}
        onClick={closeLocationModule}
        textValue={'Return to Community'}
      />
    );
  }

  function renderYesOrNoElement(active: boolean) {
    return (
      <span className={'location-module__form__yes-or-no-element--container'}>
        <p>Yes</p>
        <img
          className={'location-module__form__yes-or-no-element__icon'}
          src={active ? selected : deselected}
          alt={'Check Icon'}
        />
        <p>No</p>
        <img
          className={'location-module__form__yes-or-no-element__icon'}
          src={!active ? selected : deselected}
          alt={'Check Icon'}
        />
      </span>
    );
  }

  function renderWICTypeElement(active: boolean, child: any) {
    return (
      <span className={'location-module__form__yes-or-no-element--container'}>
        <p>Yes</p>
        <img
          className={'location-module__form__yes-or-no-element__icon'}
          src={child.age < 5 ? selected : deselected}
          alt={'Check Icon'}
        />
        <p>No</p>
        <img
          className={'location-module__form__yes-or-no-element__icon'}
          src={child.age > 4 ? selected : deselected}
          alt={'Check Icon'}
        />
        <span className="typewriter">
          <p>Age: {child.age}</p>
        </span>
      </span>
    );
  }

  function checkUsedCommunityKitcken() {
    if (Number(sessionStorage.getItem('meals')) < 1) {
      setActionSuccessfullyCompleted(false);
      setActionMessage(Status.ranOutOfMeals);
    } else if (player.usedCommunityKitchen) {
      setActionSuccessfullyCompleted(false);
      setActionMessage(Status.alreadyReceivedSoup);
    } else {
      player.usedCommunityKitchen = true;
      player.grain += 2;
      player.vegetable += 3;
      player.calories += 350;
      setActionSuccessfullyCompleted(true);
      setActionMessage(Status.receiveSoup);
      sessionStorage.setItem(
        'meals',
        Number(sessionStorage.getItem('meals')) - 1 + ''
      );
    }
  }

  function checkCommunityKitchenBusTicket() {
    if (player.communityKitchenBusTicket) {
      setActionSuccessfullyCompleted(false);
      setActionMessage(Status.alreadyHaveTransportation);
    } else {
      player.communityKitchenBusTicket = true;
      player.busTickets += 1;

      setActionSuccessfullyCompleted(true);
      setActionMessage(Status.receiveTicketCommunityKitchen);
    }
  }

  function checkSupportReceivedBefore(): boolean {
    const player = parsePlayer(sessionStorage.getItem('player'));

    if (player.usedFoodPantry && player.location === LocationID.foodPantry) {
      return true;
    } else if (player.usedSnap && player.location === LocationID.snapOffice) {
      return true;
    } else if (player.usedVita && player.location === LocationID.vitaServices) {
      return true;
    } else if (player.usedWIC && player.location === LocationID.wicClinic) {
      return true;
    } else if (
      player.VITAServiceIsClosed &&
      player.location === LocationID.vitaServices
    ) {
      return true;
    }

    return false;
  }

  function renderLocationModulePage() {
    const place = getPlaceByModule(props.module);
    const name = sessionStorage.getItem('name')
      ? sessionStorage.getItem('name')
      : player.playerInfo.firstName;

    const isAvatarAtBusStop: boolean =
      player.location === LocationID.localNeighborhoodBusStop ||
      player.location === LocationID.snapVitaBusStop ||
      player.location === LocationID.foodTigerBusStop ||
      player.location === LocationID.pantryWicBusStop;

    if (props.module === LocationID.house || isAvatarAtBusStop) {
      props.setDisplayLocationModule(false);
      return;
    }

    switch (page) {
      case locationModulePage.Start:
        if (checkSupportReceivedBefore()) {
          return (
            <div className={'location-module--wrapper__body'}>
              <img
                className={'board-overview--icon'}
                src={actionSuccessfullyCompleted ? success : errorX}
                alt="Action Complete Icon"
              />

              <h3
                className={'location-module__action-complete__action-message'}
              >
                {player.location === LocationID.wicClinic
                  ? `You have already visited WIC Clinic today.`
                  : player.location === LocationID.foodPantry
                  ? `You have already received food from the food pantry today. Due to the high
                                            number of people seeking food and limited food, we can only help you one time
                                            today.`
                  : player.location === LocationID.snapOffice
                  ? `You have already visited the SNAP Office today.`
                  : player.VITAServiceIsClosed
                  ? `Unfortunately, the Volunteer Tax Preparer for VITA Services is not available today.`
                  : player.location === LocationID.vitaServices
                  ? `You have already visited VITA Services today.`
                  : null}
              </h3>
              <div>{renderCloseButton()}</div>
            </div>
          );
        }
        return (
          <div className={'location-module--wrapper__body'}>
            <h3>{place?.Name}</h3>
            <span>
              <p className={'location-module__in-deep-description'}>
                {place?.InDeepDescription}
              </p>
              <br />
              <p className={'location-module__in-deep-description'}>
                {place?.DidYouKnow}
              </p>
              <p className={'location-module__in-deep-description'}>
                {place?.DidYouKnow2}
              </p>
            </span>
            {renderEnterButton()}
          </div>
        );
      case locationModulePage.ActionComplete:
        return (
          <div className={'location-module--wrapper__body'}>
            <img
              className={'board-overview--icon'}
              src={actionSuccessfullyCompleted ? success : errorX}
              alt="Action Complete Icon"
            />

            <h3 className={'location-module__action-complete__action-message'}>
              {actionMessage}
            </h3>
            <div
              className={
                'button--container--row board-overview--button--container--normal-height'
              }
            >
              {player.location === LocationID.communityKitchen ? (
                <Button
                  darkMode
                  enable
                  isGoBack
                  centerMode
                  normalText
                  normalWidth
                  onClick={() => {
                    setPage(locationModulePage.Action);
                  }}
                  textValue="Return To Community Kitchen"
                />
              ) : null}
              {player.location === LocationID.communityKitchen
                ? renderReturnToCommunityButtonXL()
                : renderCloseButton()}
            </div>
          </div>
        );
      case locationModulePage.Action:
        switch (props.module) {
          case LocationID.communityKitchen:
            const meals = sessionStorage.getItem('meals');
            return (
              <div className={'location-module--wrapper__body'}>
                <h3>Meals remaining: {meals}</h3>
                <img
                  className={'location-module__in-deep-description__icon'}
                  src={meal}
                  alt="Place Icon"
                />
                <div className={'button--container--column'}>
                  <div className={'button--container--row'}>
                    {renderFreeHotMealButton()}
                    {renderBusTicketButton()}
                  </div>
                </div>
              </div>
            );
          case LocationID.snapOffice:
            return (
              <div className={'location-module--form--wrapper__body'}>
                <div className={'location-module__form--container'}>
                  <h1>SNAP Application</h1>
                  <div className={'location-module__form__group typewriter'}>
                    <div className={'location-module__form__field'}>
                      <h1>Last name</h1>
                      <p>{player.playerInfo.lastName}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Fist name</h1>
                      <p>{name}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Middle initial</h1>
                      <p>{player.playerInfo.middleName}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Phone number</h1>
                      <p>{player.playerInfo.phone}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Address</h1>
                      <p>{player.playerInfo.address}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group typewriter'}>
                    <div className={'location-module__form__field'}>
                      <h1>City</h1>
                      <p>{player.playerInfo.city}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>State</h1>
                      <p>{player.playerInfo.state}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Zip code</h1>
                      <p>{player.playerInfo.zip}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Gender</h1>
                      <p>{GenderType[player.playerInfo.gender]}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Date of birth</h1>
                      <p>{dateOfBirth(player)}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Primary language</h1>
                      <p>{player.playerInfo.primaryLanguage}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Social security number</h1>
                      <p>{player.playerInfo.ssn}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Are you married?</h1>
                      {renderYesOrNoElement(player.playerInfo.married)}
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Are you pregnant?</h1>
                      {renderYesOrNoElement(player.playerInfo.pregnant)}
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Are you employed?</h1>
                      {renderYesOrNoElement(player.playerInfo.jobIncome > 0)}
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Are you in the US legally?</h1>
                      {renderYesOrNoElement(
                        player.playerInfo.ssn ? true : false
                      )}
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Number in house hold</h1>
                      <p>{player.playerInfo.numInHouse}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Number of children</h1>
                      <p>{player.playerInfo.children.length}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Ages of children</h1>
                      <p>{childrenAges(player)}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Monthly income</h1>
                      <p>{formatter.format(Number(totalIncome(player)))}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Hourly wage</h1>
                      <p>{player.playerInfo.hourlyIncome}</p>
                    </div>
                  </div>
                </div>
                <div className={'button--container--column'}>
                  <div className={'button--container--row'}>
                    {renderProcessButton()}
                    {renderReturnToCommunityButton()}
                  </div>
                </div>
              </div>
            );
          case LocationID.vitaServices:
            return (
              <div className={'location-module--form--wrapper__body'}>
                <div className={'location-module__form--container'}>
                  <h1>VITA Services Form</h1>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Name(s) as shown on income tax return</h1>
                      <p>{fullName(player)}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Monthly income from job wages/earnings</h1>
                      <p>{formatter.format(Number(totalIncome(player)))}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Are you single?</h1>
                      {renderYesOrNoElement(player.playerInfo.single)}
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Are you married?</h1>
                      {renderYesOrNoElement(player.playerInfo.married)}
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Number of children</h1>
                      <p>{player.playerInfo.children.length}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>If married, did you file a joint tax return?</h1>
                      {renderYesOrNoElement(player.playerInfo.jointTax)}
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>
                        Did you live in the US for more than 6 months last year?
                      </h1>
                      {renderYesOrNoElement(player.playerInfo.inUSSixMonth)}
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Was all your income earned in the US?</h1>
                      {renderYesOrNoElement(player.playerInfo.allIncomeInUS)}
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>
                        Do you have an income from investments (stocks, mutual
                        funds) of less than $3,150?
                      </h1>
                      {renderYesOrNoElement(false)}
                    </div>
                  </div>
                </div>
                <div className={'button--container--column'}>
                  <div className={'button--container--row'}>
                    {renderProcessButton()}
                    {renderReturnToCommunityButton()}
                  </div>
                </div>
              </div>
            );
          case LocationID.foodTiger:
            props.setDisplayLocationModule(false);
            props.setDisplayLocationModule(true);
            return;
          case LocationID.foodPantry:
            return (
              <div className={'location-module--form--wrapper__body'}>
                <div className={'location-module__form--container typewriter'}>
                  <h1>Food Pantry Form</h1>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Name</h1>
                      <p>{name}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Phone Number</h1>
                      <p>{player.playerInfo.phone}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>City</h1>
                      <p>{player.playerInfo.city}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>State</h1>
                      <p>{player.playerInfo.state}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Zip Code</h1>
                      <p>{player.playerInfo.zip}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Monthly Income</h1>
                      <p>{formatter.format(Number(totalIncome(player)))}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Number of Children</h1>
                      <p>{player.playerInfo.children.length}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Age of Children</h1>
                      <p>{childrenAges(player)}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>
                        Do you qualify for any federal assistance such as SNAP
                        or WIC?
                      </h1>
                      <p>{player.playerInfo.qualifyFor}</p>
                    </div>
                  </div>
                </div>
                <div className={'button--container--column'}>
                  <div className={'button--container--row'}>
                    {renderProcessButton()}
                    {renderReturnToCommunityButton()}
                  </div>
                </div>
              </div>
            );
          case LocationID.wicClinic:
            return (
              <div className={'location-module--form--wrapper__body'}>
                <div className={'location-module__form--container'}>
                  <h1>WIC Application</h1>
                  <div className={'location-module__form__group typewriter'}>
                    <div className={'location-module__form__field'}>
                      <h1>Last name</h1>
                      <p>{player.playerInfo.lastName}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Fist name</h1>
                      <p>{name}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Middle initial</h1>
                      <p>{player.playerInfo.middleName}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Phone number</h1>
                      <p>{player.playerInfo.phone}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Address</h1>
                      <p>{player.playerInfo.address}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>City</h1>
                      <p>{player.playerInfo.city}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group typewriter'}>
                    <div className={'location-module__form__field'}>
                      <h1>State</h1>
                      <p>{player.playerInfo.state}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Zip code</h1>
                      <p>{player.playerInfo.zip}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Gender</h1>
                      <p>{GenderType[player.playerInfo.gender]}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Date of birth</h1>
                      <p>{dateOfBirth(player)}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Primary language</h1>
                      <p>{player.playerInfo.primaryLanguage}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Are you employed?</h1>
                      {renderYesOrNoElement(player.playerInfo.jobIncome > 0)}
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Are you pregnant?</h1>
                      {renderYesOrNoElement(player.playerInfo.pregnant)}
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Number in house hold</h1>
                      <p>{player.playerInfo.numInHouse}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Monthly income</h1>
                      <p>{formatter.format(Number(totalIncome(player)))}</p>
                    </div>
                    <div className={'location-module__form__field typewriter'}>
                      <h1>Hourly wage</h1>
                      <p>{player.playerInfo.hourlyIncome}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    {player.playerInfo.children.map(
                      (child: any, index: any) => (
                        <div
                          key={index}
                          className={'location-module__form__field'}
                        >
                          <h1>WIC Type</h1>
                          {renderWICTypeElement(true, child)}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className={'button--container--column'}>
                  <div className={'button--container--row'}>
                    {renderProcessButton()}
                    {renderReturnToCommunityButton()}
                  </div>
                </div>
              </div>
            );
          default:
            props.setDisplayLocationModule(false);
        }
    }
  }

  const handleClose = () => {
    props.setDisplayLocationModule(false);
  };

  return (
    <div className={'store-builder--container'}>
      <div className={'store-builder--wrapper'}>
        <div className={'location-module--wrapper__close--icon--container'}>
          <CloseIcon name="location module" onClick={handleClose} />
        </div>
        {renderLocationModulePage()}
      </div>
      <span className={'store-builder--background'} />
    </div>
  );
}

export default LocationModule;
