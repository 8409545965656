import SessionManager from '../../assets/classes/SessionManager';
import getPlaceByModule from '../../assets/functions/Place';

import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import './TransportationModule.css';

function HomeModule(props: any) {
  function renderEndButton() {
    const handleClick = () => {
      props.setDisplayHomeModule(false);
      props.setTimeIsUp(true);
      props.setDisplayGameOver(true);
    };
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={true}
        enable={true}
        normalText={false}
        normalWidth={false}
        onClick={handleClick}
        textValue={'No, I would like to end'}
      />
    );
  }

  function renderReturnToGameBoardButton() {
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={false}
        darkMode={false}
        enable={true}
        normalText={false}
        normalWidth={false}
        onClick={() => {
          props.setDisplayHomeModule(false);
        }}
        textValue={'Yes, I will head back out'}
      />
    );
  }

  function renderHomeModulePage() {
    const place = getPlaceByModule(props.module);

    const handleClose = () => {
      props.setDisplayHomeModule(false);
      SessionManager.setActive(true);
    };

    return (
      <div className={'board-overview--wrapper'}>
        <span className={'transportation-module__close--icon--container'}>
          <CloseIcon name="home module" onClick={handleClose} />
        </span>
        <div className={'board-overview--body'}>
          <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
            {place?.Name}
          </h1>
          <div className={'board-overview--body__text-content'}>
            <p style={{ justifyContent: 'center', textAlign: 'center' }}>
              {props.nutrientsSuccessfullyObtained
                ? `Welcome home! Looks like you have obtained all the nutritious food you need today.
                                            There is still time in the day. Would you like to head back out into the community?`
                : `Welcome home! There is still time in the day and you have not obtained all
                                            the nutritious food you need. Would you like to head back out into the
                                            community?`}
            </p>
          </div>
          <div className={'board-overview--body__main-content'}>
            <img
              className={'board-overview--icon'}
              src={place?.Icon}
              alt="Time Check Icon"
            />
          </div>
          <div
            className={
              'button--container--column board-overview--button--container--normal-height'
            }
          >
            {
              // If you are at Home, it has special buttons.
              <div className={'button--container--row'}>
                {renderReturnToGameBoardButton()}
                {renderEndButton()}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'board-overview--container'}>
      {renderHomeModulePage()}
      <span className={'board-overview--background'} />
    </div>
  );
}

export default HomeModule;
