import BusIcon from '../../assets/images/BusIcon';
import CarIcon from '../../assets/images/CarIcon';
import CheckmarkIcon from './CheckmarkIcon';
import CloseIcon from './CloseIcon';
import shoeIcon from '../../assets/images/shoe.svg';
import './TransportationIcon.css';

const TransportationIcon = ({
  disabled,
  showIndicators,
  type,
}: {
  disabled?: boolean;
  showIndicators?: boolean;
  type?: 'bus' | 'car' | 'walk';
}) => {
  const shoe = (
    <img className={'transportation-icon'} src={shoeIcon} alt={'Shoe Icon'} />
  );
  let Component = BusIcon;
  if (type === 'car') {
    Component = CarIcon;
  }
  return (
    <div className="transportation-icon-with-indicator">
      {type === 'walk' ? (
        shoe
      ) : (
        <Component
          className={'transportation-icon'}
          color={disabled ? '#c0c0c0' : '#085C71'}
          title={type === 'bus' ? 'Bus Icon' : 'Car Icon'}
        />
      )}
      {disabled && showIndicators ? (
        <CloseIcon
          color="#fff"
          fill="#d90000"
          name="Close icon"
          onClick={() => {}}
        />
      ) : undefined}
      {!disabled && showIndicators ? <CheckmarkIcon /> : undefined}
    </div>
  );
};

export default TransportationIcon;
