import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  parsePlayer,
  startingCash,
} from '../../assets/functions/PlayerFunctions';
import handIcon from '../../assets/images/hand_icon.svg';
import { createSessionMember } from '../../hooks/createSessionMember';
import Button from '../misc/Button';
import Point from '../misc/Point';
import TrasportationOptions from '../misc/TransportationOptions';
import BudgetPopUp from './BudgetPopUp';
import './GameInstructions.css';

function GameInstructions() {
  const [index, setIndex] = useState<number>(0);
  const [displayBudgetPopUp, setDisplayBudgetPopUp] = useState<boolean>(false);
  const history = useHistory();

  let player;
  let title: string | undefined;

  function renderViewBudgetButton(centerMode = true, normalWidth = true) {
    return (
      <Button
        arrowLeftShortIcon
        centerMode={centerMode}
        enable
        normalText
        normalWidth={normalWidth}
        onClick={() => {
          setDisplayBudgetPopUp(true);
        }}
        textValue={'View budget'}
      />
    );
  }

  function renderNextButton(centerMode = true) {
    if (index === 5) {
      return (
        <Button
          arrowLeftShortIcon
          centerMode={centerMode}
          enable
          normalText
          onClick={() => {
            history.push('/game-board');
          }}
          textValue={'Next'}
        />
      );
    } else {
      return (
        <Button
          arrowLeftShortIcon
          centerMode={centerMode}
          enable
          normalText
          onClick={() => {
            setIndex(index + 1);
          }}
          textValue={'Next'}
        />
      );
    }
  }

  function renderGoBackButton() {
    if (index === 0) {
      return (
        <Button
          darkMode
          enable
          isGoBack
          normalText
          onClick={() => {
            history.push('/');
          }}
          textValue="Go Back"
        />
      );
    } else {
      return (
        <Button
          darkMode
          enable
          isGoBack
          normalText
          onClick={() => {
            setIndex(index - 1);
          }}
          textValue="Go Back"
        />
      );
    }
  }

  const playerAux = sessionStorage.getItem('player');

  function renderGameInstructionsPage() {
    switch (index) {
      case 0:
        return (
          <div className={'game-instructions--wrapper'}>
            <h1 style={{ marginBottom: '2%', textAlign: 'center' }}>
              Welcome.
              <br />
              Your objective is to acquire enough
              <br />
              food and get home by 6pm.
            </h1>
            <p style={{ marginBottom: '9%', textAlign: 'center' }}>
              People who are experiencing food insecurity face many
              <br />
              challenges in their day to day life. You will face some of those
              <br />
              challenges throughout this experience.
            </p>
            <div
              className={
                'button--container--column game-instructions--button--container'
              }
            >
              <div className={'button--container--row'}>
                {renderGoBackButton()}
                {renderNextButton()}
              </div>
              <Point numberOfPoints={6} currentPoint={1} />
            </div>
          </div>
        );
      case 1:
        return (
          <div className={'game-instructions--wrapper'}>
            <h1 style={{ marginBottom: '2%', textAlign: 'center' }}>
              {`Using your allotted income, you'll need`}
              <br />
              to acquire enough nutritious food from
              <br />
              each of the 6 main food groups.
            </h1>
            <p style={{ marginBottom: '9%', textAlign: 'center' }}>
              There are resources throughout the community to
              <br />
              help you get the food you need.
            </p>
            <div
              className={
                'button--container--column game-instructions--button--container'
              }
            >
              <div className={'button--container--row'}>
                {renderGoBackButton()}
                {renderNextButton()}
              </div>
              <Point numberOfPoints={6} currentPoint={2} />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={'game-instructions--wrapper'}>
            <h1 style={{ marginBottom: '6%', textAlign: 'center' }}>
              Hi there. What’s your name?
            </h1>
            <div style={{ marginBottom: '9%', textAlign: 'center' }}>
              <div style={{ margin: '3%' }}>
                <p
                  style={{
                    color: '#085C71',
                    marginBottom: '1%',
                    textAlign: 'start',
                  }}
                >
                  First name
                </p>
                <input
                  className={'game-instructions--input--name'}
                  onChange={(event) => {
                    sessionStorage.setItem('name', event.target.value);
                  }}
                />
              </div>
              <p>We will use this to personalize your simulation experience.</p>
            </div>
            <div
              className={
                'button--container--column game-instructions--button--container'
              }
            >
              <div className={'button--container--row'}>
                {renderGoBackButton()}
                {renderNextButton()}
              </div>
              <Point numberOfPoints={6} currentPoint={3} />
            </div>
          </div>
        );
      case 3:
        player = sessionStorage.getItem('InSession')
          ? parsePlayer(sessionStorage.getItem('player'))
          : createSessionMember();
        sessionStorage.setItem('playerInitState', JSON.stringify(player));
        title = player.playerInfo.fullDescription;
        sessionStorage.setItem('player', JSON.stringify(player));
        sessionStorage.setItem('meals', '40');

        return (
          <div className={'game-instructions--wrapper'}>
            <img
              style={{ marginBottom: '4%' }}
              className={'game-instructions--icon'}
              src={handIcon}
              alt="Hand Icon"
            />
            <h2
              style={{ marginBottom: '9%', textAlign: 'center' }}
              className={'game-instructions--max-width'}
            >
              Hi there
              {sessionStorage.getItem('name')
                ? ', ' + sessionStorage.getItem('name')
                : ''}
              .
              <br />
              {title}
            </h2>
            <div
              className={
                'button--container--column game-instructions--button--container'
              }
            >
              <div className={'button--container--row'}>
                {renderGoBackButton()}
                {renderNextButton()}
              </div>
              <Point numberOfPoints={6} currentPoint={4} />
            </div>
          </div>
        );
      case 4:
        if (playerAux) {
          title =
            'You’ve got $' +
            startingCash(JSON.parse(playerAux)) +
            ' to spend today.';
        }

        return (
          <div className={'game-instructions--wrapper'}>
            <img
              style={{ marginBottom: '4%' }}
              className={'game-instructions--icon'}
              src={handIcon}
              alt="Hand Icon"
            />
            <h2 style={{ marginBottom: '4%', textAlign: 'center' }}>{title}</h2>
            <p style={{ marginBottom: '9%', textAlign: 'center' }}>
              Don’t forget, community services are there to help. Your budget is
              <br />
              determined by income minus expenses divided by number of
              <br />
              family members divided by 30 days. See your breakdown.
              <br />
              <br />
              Everything in this simulation is taking place in one day,
              <br />
              and you are acquiring food for one person.
            </p>
            <div
              className={
                'button--container--column game-instructions--button--container'
              }
            >
              <div className={'button--container--row'}>
                {renderGoBackButton()}
                {renderViewBudgetButton(false, false)}
                {renderNextButton(false)}
              </div>
              <Point numberOfPoints={6} currentPoint={5} />
            </div>
          </div>
        );
      case 5:
        if (playerAux) {
          const player = JSON.parse(sessionStorage.getItem('player') as any);
          if (player.playerInfo.hasCar) {
            title = 'You have a car.';
          } else if (player.busTickets > 0) {
            title =
              'You have a bus pass with ' +
              JSON.parse(playerAux).busTickets +
              ' rides left.';
          } else {
            title = 'You do not have any transportation.';
          }

          return (
            <div className={'game-instructions--wrapper'}>
              <TrasportationOptions
                busDisabled={player.busTickets === 0}
                carDisabled={!player.playerInfo.hasCar}
                showIndicators
              />
              <h1 style={{ marginBottom: '1%', textAlign: 'center' }}>
                {title}
              </h1>
              <p style={{ marginBottom: '9%', textAlign: 'center' }}>
                {`You're always welcome to walk the community.`}
              </p>
              <div
                className={
                  'button--container--column game-instructions--button--container'
                }
              >
                <div className={'button--container--row'}>
                  {renderGoBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={6} currentPoint={6} />
              </div>
            </div>
          );
        } else {
          return (
            <div className={'game-instructions--wrapper'}>
              <h1>Error: No player found</h1>
            </div>
          );
        }
    }
  }

  return (
    <div className={'sub-container'}>
      {displayBudgetPopUp ? (
        <span>
          <BudgetPopUp setDisplayBudgetPopUp={setDisplayBudgetPopUp} />
        </span>
      ) : null}
      <div className={'game-instructions--container'}>
        {renderGameInstructionsPage()}
      </div>
    </div>
  );
}

export default GameInstructions;
