export enum Status {
  //COMMUNITY KITCHEN
  receiveSoup = 'Enjoy this delicious vegetable soup. It provides 2 grain, 3 vegetable and 350 calories.',
  alreadyReceivedSoup = 'You have already received a hot meal today. Due to the ' +
    'high number of people seeking food, we can only ' +
    'provide one meal per person.',
  alreadyHaveTransportation = 'It looks like you already have some mode of transportation. We have a limited amount of bus tickets, ' +
    'so we must reserve them for someone without any transportation.',
  receiveTicketCommunityKitchen = 'We received a small donation of bus tickets for those who have no transportation. Here is one bus ticket.',
  ranOutOfMeals = 'Sorry, we ran out of meals to give out today.',

  //STORE
  repeatedWIC = 'There are multiple {0} items. ' +
    'WIC is redeemable for one WIC food item in each category of fruits, vegetables, grains, protein, and dairy. ',
  tooManyWIC = 'WIC benefit can be used on a maximum of 5 food items.',
  wicRedeemed = 'WIC benefit has been redeemed.',
  purchaseCompleted = 'Purchase completed.',
  snapOnPremade = 'Cannot use SNAP benefits on premade food.',
  insufficientCash = 'Not enough cash.',
  insufficientSnap = 'Not enough SNAP fund.',
  totalMismatch = 'Total amount does not match.',

  //SNAP
  snapApproved = 'Your application has been approved. For a household of {0} and monthly income of {1, ' +
    'you have received {2} in SNAP benefits that can be used to purchase any food that is not prepared and ready to eat.',
  snapDenied = 'Your application has been denied. For a household of {0} and monthly income of {1, you make too much money to receive SNAP benefits.',

  //FORM
  enterAgain = 'You have already visited the {0} today.',

  //VITA
  bothEitcCTC = 'Your application has been approved. ' +
    'For {0} child household and monthly income of {1, you have received {2} in EITC benefits that can be used to purchase food or bus tickets. ' +
    'You have also received $2.74 in CTC benefits and can be used to purchase food.',
  justCtc = 'Your application has been approved for CTC. ' +
    'For {0} child household and monthly income of {1, you have received $2.74 in CTC benefits and can be used to purchase food. ' +
    'However, you do not qualify for EITC. Income must be wages from a job, so income from social security and unemployment is ineligible.',
  justEitc = 'Your application has been approved for EITC. ' +
    'For {0} child household and monthly income of {1, you have received {2} in EITC benefits that can be used to purchase food or bus tokens. ' +
    'However, you do not have any children or income is too high, so do not qualify for CTC benefits.',
  neitherEitcCTC = 'Your application has been denied. You do not qualify for the EITC or the CTC. You do not qualify for EITC ' +
    'because income wages must be from a job and not social security or unemployment. You do not qualify for ' +
    'the CTC because either you don’t have any children or your income is too high.',
  vitaNoVolunteer = 'Unfortunately, the Volunteer Tax Preparer for VITA Services is not available today.',

  //Food Pantry
  enterFoodPantry = 'You live in our service area, so your application has been approved. You may select two items from here, ' +
    'free of cost.',
  deniedFoodPantry = 'Due to high demand and limited food, we are currently only serving individuals living in the 30317 and 30307 ' +
    'zip codes. We cannot provide you with food from our pantry today.',
  leaveFoodPantry = 'Thank you for visiting the Food Pantry.',
  reenterFoodPantryAfterReceived = 'You have already received food from the food pantry today. Due to the high number of' +
    ' people seeking food and limited food, we can only help you one time today.',

  //WIC Clinic
  wicApproved = 'For a household of {0} that has either a pregnant woman or children under the age of 5 and monthly income of {1, ' +
    'you have received a WIC benefit. The benefit can be used during one transaction at participating grocery stores for WIC approved foods.' +
    ' You may find your benefit in your wallet.',
  wicDenied = 'Your application has been denied because you are not a pregnant woman and/or you don’t have at least one child under the age of 5 in your household.',

  //Random Event
  freeRideReceived = 'A friend has offered to give you a ride to your next location. ' +
    'Choose wisely as this will only get you to one place. Redeem by clicking the next location you want to go.',
  carBrokeDown = 'Your car has broken down and you must get it fixed (will be repaired in 3 hours).',
  carRepaired = 'Your car is fixed. You can take the car again.',

  //End Game
  gettingLate = 'You better get home soon.  It is getting late.',
  notHomeInsufficientFoodEnd = 'Unfortunately, the day has ended and you did not make it back home. ' +
    'Everything is closed and you did not get enough food for yourself today.',
  notHomeSufficientFoodEnd = 'Unfortunately, the day has ended and you did not make it back home. ' +
    'Everything is closed, but you were able to get enough food for yourself today.',
  homeInsufficientFoodEnd = 'Unfortunately, everything is closed and you did not get enough food for yourself today. ' +
    'However, you made it back home.',
  homeSufficientFoodEnd = 'Great work! ' +
    'You successfully made it through the day and came back home with all the nutritious food you need for today.',

  //General Status
  achievedNutrition = 'Great work! You have successfully obtained all the nutritious food you need for today. ' +
    'Feel free to head home whenever you want.',
  homeInsufficientFood = 'Welcome home! There is still time in the day and you have not obtained all the nutritious food you need. ' +
    'Would you like to head back out into the community?',
  homeSufficientFood = 'Welcome home! Looks like you have obtained all the nutritious food you need today. ' +
    'There is still time in the day. Would you like to head back out into the community?',

  //Bus
  purchaseSingleTicket = 'Payment of {0} bus ticket successful.',
  purchaseMultipleTickets = 'Payment of {0} bus tickets successful.',

  //Hints
  clickStopInstruction = 'Click on a bus stop to have the bus drop you off where you want to go.',
}
