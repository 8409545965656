import { LocationID, WICNutrients } from '../../constants';
import { Nutrient, Player, RequiredNutrient, WICNutrient } from '../../types';

export function parsePlayer(playerStr: any): Player {
  return JSON.parse(playerStr);
}

// todo(@victoria): figure out why session storage fails ts when player: Player here
export function restartGame(player: any): void {
  sessionStorage.setItem(
    'player',
    sessionStorage.getItem('playerInitState')
      ? sessionStorage.getItem('playerInitState')
      : player
  );
  sessionStorage.setItem('meals', '40');
  window.location.href = window.location.origin;
}

export function setPlayerName(player: Player, name: string): void {
  player.playerInfo.firstName = name;
}

export function playerName(player: Player): string {
  return player.playerInfo.firstName;
}

export function totalIncome(player: Player): any {
  return (
    player.playerInfo.jobIncome +
    player.playerInfo.temporaryAssistance +
    player.playerInfo.socialSecurityIncome +
    player.playerInfo.additionalIncome
  );
}

export function monthlyCash(player: Player) {
  const expenses =
    player.playerInfo.rentExpense +
    player.playerInfo.utilitiesExpense +
    player.playerInfo.medicalExpense +
    player.playerInfo.transportationExpense +
    player.playerInfo.taxesExpense +
    player.playerInfo.phoneExpense +
    player.playerInfo.childcareExpense +
    player.playerInfo.otherExpense;
  return totalIncome(player) + expenses;
}

export function startingCash(player: Player): any {
  const moCash = monthlyCash(player);

  const dailyCash = moCash / (30 * player.playerInfo.numInHouse);

  const cashForDay = dailyCash.toFixed(2);

  return moCash > 0 ? cashForDay : 0;
}

export function formIncome(player: Player): any {
  return (
    player.playerInfo.temporaryAssistance +
    player.playerInfo.socialSecurityIncome
  );
}

export function totalAvailable(player: Player): any {
  return Number(player.money) + Number(player.snapFunds);
}

export function totalBudget(player: Player): any {
  return Number(formIncome(player)) + Number(startingCash(player));
}

export function setMoney(value: number, player: Player): void {
  player.money = value;
}

export function setTicketFromCommunityKitchen(player: Player): void {
  if (player.busTickets > 0 || player.playerInfo.hasCar) {
    player.communityKitchenBusTicket = true;
  }
}

export function setBusTokens(value: number, player: Player): void {
  player.busTickets = value;
}

export function checkVoucher(player: Player): number {
  return player.hadWic ? 1 : 0;
}

export function resetToStarting(player: Player): void {
  player.money = startingCash(player);
}

// update player nutrition
export function addNutrition(food: any, player: Player, quantity: any): void {
  player.calories += Number(food.calories) * Number(quantity);
  player.grain += food.nutrition.grain
    ? Number(food.nutrition.grain) * Number(food.quantity)
    : 0;
  player.fat += food.nutrition.fat
    ? Number(food.nutrition.fat) * Number(quantity)
    : 0;
  player.protein += food.nutrition.protein
    ? +(Number(food.nutrition.protein) * Number(quantity))
    : 0;
  player.dairy += food.nutrition.dairy
    ? Number(food.nutrition.dairy) * Number(quantity)
    : 0;
  player.fruit += food.nutrition.fruit
    ? Number(food.nutrition.fruit) * Number(quantity)
    : 0;
  player.vegetable += food.nutrition.vegetable
    ? Number(food.nutrition.vegetable) * Number(quantity)
    : 0;
  player.extra += food.nutrition.extra
    ? Number(food.nutrition.extra) * Number(quantity)
    : 0;
  player.sugar += food.sugar ? Number(food.sugar) * Number(quantity) : 0;
  player.sodium += food.sodium ? Number(food.sodium) * Number(quantity) : 0;
  player.satFat += food.saturatedFat
    ? Number(food.saturatedFat) * Number(quantity)
    : 0;
  persistPlayer(player);
}

export function addNutritionV2(food: any, player: Player): any {
  for (let i = 0; i < food.length; i++) {
    if (food[i].quantity > 0 && !food[i].used) {
      const calories = food[i].calories
        ? Number(food[i].calories) * Number(food[i].quantity)
        : 0;
      const grain = food[i].nutrition.grain
        ? Number(food[i].nutrition.grain) * Number(food[i].quantity)
        : 0;
      const protein = food[i].nutrition.protein
        ? Number(food[i].nutrition.protein) * Number(food[i].quantity)
        : 0;
      const fat = food[i].nutrition.fat
        ? Number(food[i].nutrition.fat) * Number(food[i].quantity)
        : 0;
      const dairy = food[i].nutrition.dairy
        ? Number(food[i].nutrition.dairy) * Number(food[i].quantity)
        : 0;
      const fruit = food[i].nutrition.fruit
        ? Number(food[i].nutrition.fruit) * Number(food[i].quantity)
        : 0;
      const vegetable = food[i].nutrition.vegetable
        ? Number(food[i].nutrition.vegetable) * Number(food[i].quantity)
        : 0;
      const extra = food[i].nutrition.extra
        ? Number(food[i].nutrition.extra) * Number(food[i].quantity)
        : 0;
      const sugar = food[i].sugar
        ? Number(food[i].sugar) * Number(food[i].quantity)
        : 0;
      const sodium = food[i].sodium
        ? Number(food[i].sodium) * Number(food[i].quantity)
        : 0;
      const satFat = food[i].saturatedFat
        ? Number(food[i].saturatedFat) * Number(food[i].quantity)
        : 0;

      player.calories = player.calories + calories;
      player.grain = player.grain + grain;
      player.protein = player.protein + protein;
      player.fat = player.fat + fat;
      player.dairy = player.dairy + dairy;
      player.fruit = player.fruit + fruit;
      player.vegetable = player.vegetable + vegetable;
      player.extra = player.extra + extra;
      player.sugar = player.sugar + sugar;
      player.sodium = player.sodium + sodium;
      player.satFat = player.satFat + satFat;

      food[i].used = true;
    }
  }
  return player;
}

export function addCash(cash: number, player: Player): void {
  player.money += cash;
  player.money = Number(player.money.toFixed(2));
}

export function subtractCash(cash: number, player: Player): void {
  player.money -= cash;
  player.money = player.money > 0 ? Number(player.money.toFixed(2)) : 0;
}

export function addSnap(funds: number, player: Player): void {
  player.snapFunds += funds;
  player.snapAcquired += funds;
  persistPlayer(player);
}

export function subtractSnap(funds: number, player: Player): void {
  player.snapFunds -= funds;
  player.snapFunds = Number(player.snapFunds.toFixed(2));
}

export function addEITC(funds: number, player: Player): void {
  player.eitcAcquired += funds;
  player.money = funds + Number(player.money);
  persistPlayer(player);
}

export function addCTC(funds: number, player: Player): void {
  player.ctcAcquired += funds;
  player.money = funds + Number(player.money);
  persistPlayer(player);
}

export function addVoucher(player: Player): void {
  player.hadWic = true;

  player.voucher = {
    fruitUsed: false,
    vegetableUsed: false,
    grainUsed: false,
    proteinUsed: false,
    dairyUsed: false,
  };
  player.playerInfo.federalAssistance = true;
  persistPlayer(player);
}

export function updateVoucher(nutrient: WICNutrient, player: Player): void {
  if (player.voucher !== null && player.hadWic) {
    if (nutrient == WICNutrients.grain) {
      player.voucher.grainUsed = true;
    }
    if (nutrient == WICNutrients.protein) {
      player.voucher.proteinUsed = true;
    }
    if (nutrient == WICNutrients.dairy) {
      player.voucher.dairyUsed = true;
    }
    if (nutrient == WICNutrients.fruit) {
      player.voucher.fruitUsed = true;
    }
    if (nutrient == WICNutrients.vegetable) {
      player.voucher.vegetableUsed = true;
    }
    if (Object.values(player.voucher).every((usedNutrient) => usedNutrient)) {
      player.hadWic = false;
    }
    persistPlayer(player);
  }
}

export function hasNoModeOfTransportation(player: Player): boolean {
  if (
    player.busTickets == 0 &&
    player.money < 2.5 &&
    (!player.playerInfo.hasCar ||
      (player.playerInfo.hasCar && player.carBrokenDown)) &&
    !player.playerInfo.busPass &&
    !player.onBus &&
    !player.hasTemporaryRide
  ) {
    return true;
  } else {
    return false;
  }
}

function movingPlayer() {
  sessionStorage.setItem(
    'InSession',
    Number(sessionStorage.getItem('InSession')) + 1 + ''
  );
}

export function setLocation(module: LocationID): void {
  const player = parsePlayer(sessionStorage.getItem('player'));
  switch (module) {
    case LocationID.house:
      player.isHome = true;
      player.location = LocationID.house;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.communityKitchen:
      player.location = LocationID.communityKitchen;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.cornerStore:
      player.location = LocationID.cornerStore;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.snapOffice:
      player.location = LocationID.snapOffice;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.vitaServices:
      player.location = LocationID.vitaServices;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.foodTiger:
      player.location = LocationID.foodTiger;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.foodPantry:
      player.location = LocationID.foodPantry;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.wicClinic:
      player.location = LocationID.wicClinic;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.localNeighborhoodBusStop:
      player.location = LocationID.localNeighborhoodBusStop;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.pantryWicBusStop:
      player.location = LocationID.pantryWicBusStop;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.foodTigerBusStop:
      player.location = LocationID.foodTigerBusStop;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
    case LocationID.snapVitaBusStop:
      player.location = LocationID.snapVitaBusStop;
      player.isHome = false;
      movingPlayer();
      persistPlayer(player);
      return;
  }
  return;
}

export function persistPlayer(player: Player) {
  sessionStorage.setItem('player', JSON.stringify(player));
}

export function dateOfBirth(player: Player): string {
  const year = new Date();
  year.setFullYear(year.getFullYear() - player.playerInfo.age);
  return (
    player.playerInfo.birthDay +
    '/' +
    player.playerInfo.birthMonth +
    '/' +
    year.getFullYear()
  );
}

export function fullName(player: Player): string {
  return (
    (sessionStorage.getItem('name')
      ? sessionStorage.getItem('name')
      : player.playerInfo.firstName) +
    ' ' +
    player.playerInfo.lastName
  );
}

export function childrenAges(player: Player): string {
  let result = '';
  player.playerInfo.children.forEach((c: any) => {
    result += c['age'] + ', ';
  });
  return result.substr(0, result.length - 2);
}

export function getNutritionConsumed(player: Player): number {
  if (player === null) {
    return 0;
  }

  const getNutrientCompleteRatio = (
    nutrient: Nutrient | 'calories'
  ): number => {
    const ratio: number =
      player[nutrient] /
      player.playerInfo[
        `required${
          nutrient[0].toUpperCase() + nutrient.substring(1)
        }` as RequiredNutrient
      ];

    if (ratio > 1) {
      return 1;
    }

    return ratio;
  };

  const totalNutritionRatios =
    getNutrientCompleteRatio('calories') +
    getNutrientCompleteRatio('grain') +
    getNutrientCompleteRatio('fat') +
    getNutrientCompleteRatio('protein') +
    getNutrientCompleteRatio('dairy') +
    getNutrientCompleteRatio('fruit') +
    getNutrientCompleteRatio('vegetable');

  // todo (@victoria): fix usage of `extra`; should be found from food.nutrition.extra (cornerStore.ts, foodTiger.ts); how to add it to nutrition status since extra always = 0; if unimportant, remove entirely
  // if (player.playerInfo.requiredExtra) {
  //   totalNutritionRatios += getNutrientCompleteRatio('extra');
  // }

  return Number(((totalNutritionRatios / 7) * 100).toFixed(0));
}

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export function setFreeRide(value: boolean): void {
  const player = parsePlayer(sessionStorage.getItem('player'));
  player.hasTemporaryRide = value;
  persistPlayer(player);
}

export function setCarBrokenDown(value: boolean): void {
  const player = parsePlayer(sessionStorage.getItem('player'));
  player.carBrokenDown = value;
  persistPlayer(player);
}
