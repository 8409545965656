// todo (@victoria): refactor these to be derived from `Nutrients` below
export enum RequiredNutrients {
  requiredCalories = 'requiredCalories',
  requiredDairy = 'requiredDairy',
  requiredExtra = 'requiredExtra',
  requiredFat = 'requiredFat',
  requiredFruit = 'requiredFruit',
  requiredGrain = 'requiredGrain',
  requiredProtein = 'requiredProtein',
  requiredVegetable = 'requiredVegetable',
}

// todo(@victoria): Replace `FoodType` in `Constants.tsx` with this
export enum WICNutrients {
  dairy = 'dairy',
  fruit = 'fruit',
  grain = 'grain',
  protein = 'protein',
  vegetable = 'vegetable',
}

export enum OtherNutrients {
  extra = 'extra',
  fat = 'fat',
  sodium = 'sodium',
  satFat = 'satFat',
  sugar = 'sugar',
}

export enum FoodType {
  None,
  Grain,
  Fat,
  Protein,
  Dairy,
  Fruit,
  Vegetable,
  Extra,
}

export enum FundsType {
  Cash,
  Snap,
  Eitc,
  Ctc,
}

export enum GenderType {
  Male,
  Female,
}

export enum LocationID {
  house,
  communityKitchen,
  cornerStore,
  localNeighborhoodBusStop,
  foodTiger,
  foodTigerBusStop,
  snapOffice,
  vitaServices,
  snapVitaBusStop,
  foodPantry,
  wicClinic,
  pantryWicBusStop,
}

export enum Neighborhood {
  onRoad = 'On Road',
  localNeighborhood = 'Local Neighborhood',
  foodTigerArea = 'Food Tiger Area',
  wicFoodPantryArea = 'WIC Food Pantry Area',
  vitaSnapArea = 'VITA Snap Area',
}

export enum TravelType {
  Walk = 0,
  Car = 1,
  Bus = 2,
}
