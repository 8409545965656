import home from '../../assets/images/places/home.png';
import communityKitchen from '../../assets/images/places/community_kitchen.png';
import cornerStore from '../../assets/images/places/corner_store.png';
import snapOffice from '../../assets/images/places/snap_office.png';
import vitaServices from '../../assets/images/places/vita_services.png';
import foodTiger from '../../assets/images/places/food_tiger.png';
import foodPantry from '../../assets/images/places/food_pantry.png';
import wicClinic from '../../assets/images/places/wic_clinic.png';
import busStop from '../../assets/images/places/bus_stop.png';
import snapVitaBusSop from '../../assets/images/places/snap_vita_bus_stop.png';
import foodTigerBusStop from '../../assets/images/places/food_tiger_bus_stop.png';
import foodPantryWicClincBusStop from '../../assets/images/places/food_pantry_wic_clinc_bus_stop.png';
import { LocationID } from '../../constants';
import Place from '../classes/Place';

function getPlaceByModule(module: LocationID): Place | undefined {
  switch (module) {
    case LocationID.house:
      return {
        Description: `Welcome home! There is still time in the day and you have not obtained all the nutritious
                food you need. Would you like to head back out into the community?`,
        Name: 'Home',
        Icon: home,
        InDeepDescription: '',
        DidYouKnow: '',
        DidYouKnow2: '',
      };
    case LocationID.communityKitchen:
      return {
        Description: 'Provides free, prepared meals for people in need.',
        Name: 'Community Kitchen',
        Icon: communityKitchen,
        InDeepDescription: `The Community Kitchen is a local nonprofit organization
                    that provides free, prepared meals to people in need. Food
                    is donated from grocery stores and from your local food
                    bank. The Community Kitchen relies on volunteers to help
                    keep the kitchen open.`,
        DidYouKnow: `
                    You may help yourself to one free hot meal as long as
                    supplies last. The Community Kitchen also has a small
                    number of donated bus tokens for those in need of
                    transportation.`,
        DidYouKnow2: '',
      };
    case LocationID.cornerStore:
      return {
        Description: 'A small neighborhood convenience store.',
        Name: 'Corner Store',
        Icon: cornerStore,
        InDeepDescription: `The Corner Store is a small neighborhood
                convenience store with limited inventory. You can use
                cash, SNAP, EITC, CTC, and WIC to purchase food.
                `,
        DidYouKnow: `Did you know?`,
        DidYouKnow2: `Everything in this simulation is taking
                place in one day, and you are aquiring food for one
                person. Each food item you acquire is one serving,
                and it is priced accordingly.`,
      };
    case LocationID.snapOffice:
      return {
        Description: `You can apply here for SNAP; a federal nutrition program. If you are eligible, you will receive benefits.`,
        Name: 'SNAP Office',
        Icon: snapOffice,
        InDeepDescription: `SNAP stands for Supplemental Nutrition Assistance
                Program. It is a federally funded nutrition program
                where individuals can apply for and, if eligible, receive
                SNAP benefits that they can use to purchase any food
                that is not prepared and ready to eat.
                `,
        DidYouKnow: `Did you know?`,
        DidYouKnow2: `In reality, each of the benefits in the
                experience typically requires multiple visits and more
                processing time.`,
      };
    case LocationID.vitaServices:
      return {
        Description: `Provides Volunteer Income Tax Assistance (VITA) to help low and middle income workers with
                free tax preparation - including filing for the Earned Income Tax Credit (EITC) and/or the Child Tax
                Credit (CTC).`,
        Name: 'VITA Services',
        Icon: vitaServices,
        InDeepDescription: `
                VITA stands for Volunteer Income Tax Assistance. The
                VITA Services is where volunteer tax preparers help low to
                middle income workers with tax assistance. If you have
                earned income, you can file for and receive Earned Income Tax Credit (EITC) and/or Child Tax
                Credit (CTC).
                `,
        DidYouKnow: `
                Did you know? 
                `,
        DidYouKnow2: `
                In reality, each of the benefits in the
                experience typically requires multiple visits and more
                processing time. Also, EITC and CTC are only filed
                during tax season.
                `,
      };
    case LocationID.foodTiger:
      return {
        Description: 'Large chain grocery store.',
        Name: 'Food Tiger',
        Icon: foodTiger,
        InDeepDescription: `Food Tiger is a large grocery chain in your
                    community. You can use cash, SNAP, EITC,
                    CTC, and WIC to purchase food.`,
        DidYouKnow: `Did you know? `,
        DidYouKnow2: `Everything in this simulation
                is taking place in one day, and you are
                acquiring food for one person. Each food item
                you acquire is one serving, and it is priced
                accordingly.`,
      };
    case LocationID.foodPantry:
      return {
        Description: 'Provides food for those in need.',
        Name: 'Food Pantry',
        Icon: foodPantry,
        InDeepDescription: `
                Food Pantries are organizations that provide free groceries to people in need. This Food Pantry has 
                limited supplies and therefore can only serve the members of the surrounding community. Please fill 
                out an application and if eligible, you may select two items from the pantry while supplies last.`,
        DidYouKnow: '',
        DidYouKnow2: '',
      };
    case LocationID.wicClinic:
      return {
        Description: `Provides WIC benefits for families with pregnant women, infants and children up to age 5.`,
        Name: 'WIC Clinic',
        Icon: wicClinic,
        InDeepDescription: `WIC stands for Supplemental Nutrition Program for
                Women, Infants, and Children. The WIC Clinic is a
                federally funded nutrition program for pregnant women
                and families with infants and children under the age of
                5. Individuals can apply, and if eligible, will receive a
                WIC benefit that can be used for WIC approved foods.`,
        DidYouKnow: `
                Did you know? `,
        DidYouKnow2: `
                 In reality, each of the benefits in this
                experience typically require multiple visits and more
                processing time.`,
      };
    case LocationID.localNeighborhoodBusStop:
      return {
        Description: 'Take the bus around the community.',
        Name: 'Bus Stop',
        Icon: busStop,
        InDeepDescription: '',
        DidYouKnow: '',
        DidYouKnow2: '',
      };
    case LocationID.pantryWicBusStop:
      return {
        Description: 'To take bus to and around the Community.',
        Name: 'Food Pantry/WIC Clinc Bus Stop',
        Icon: foodPantryWicClincBusStop,
        InDeepDescription: '',
        DidYouKnow: '',
        DidYouKnow2: '',
      };
    case LocationID.foodTigerBusStop:
      return {
        Description: 'Take the bus to and around the Community.',
        Name: 'Food Tiger Bus Stop',
        Icon: foodTigerBusStop,
        InDeepDescription: '',
        DidYouKnow: '',
        DidYouKnow2: '',
      };
    case LocationID.snapVitaBusStop:
      return {
        Description: 'Take the bus to and around the Community',
        Name: 'SNAP/VITA Bus Stop',
        Icon: snapVitaBusSop,
        InDeepDescription: '',
        DidYouKnow: '',
        DidYouKnow2: '',
      };
  }
}

export default getPlaceByModule;
