import './HealthStatus.css';

interface Props {
  status: any;
}

const HealthStatus = ({ status }: Props): JSX.Element => {
  return (
    <div className="health-status">
      <p className="health-status--title">{`Health status: ${
        status ? status : 'Healthy'
      }`}</p>
    </div>
  );
};

export default HealthStatus;
