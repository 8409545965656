import { Link } from 'react-router-dom';

import {
  getNutritionConsumed,
  parsePlayer,
} from '../../assets/functions/PlayerFunctions';
import success from '../../assets/icons/success.svg';
import errorX from '../../assets/icons/error-x.svg';
import { LocationID } from '../../constants';
import Button from '../misc/Button';

function GameOver(props: any) {
  const player = parsePlayer(sessionStorage.getItem('player'));
  const isPlayerAtHome = player.location === LocationID.house;

  function renderSlide() {
    if (props.warningTime && !props.timeIsUp) {
      return (
        <div className={'board-overview--body'}>
          <div
            className={
              'board-overview--body__main-content board-overview--body__text-content'
            }
          >
            <h3
              style={{
                justifyContent: 'center',
                paddingTop: '25%',
                textAlign: 'center',
              }}
            >
              You better get home soon. It is getting late.
            </h3>
          </div>
          <div
            className={
              'button--container--column board-overview--button--container--normal-height'
            }
          >
            <Button
              arrowLeftShortIcon={true}
              centerMode={true}
              darkMode={false}
              enable={true}
              normalText={true}
              normalWidth={true}
              onClick={() => {
                props.setDisplayGameOver(false);
              }}
              textValue={'Close'}
            />
          </div>
        </div>
      );
    } else if (
      props.nutrientsSuccessfullyObtained &&
      isPlayerAtHome &&
      props.timeIsUp
    ) {
      return (
        <div className={'board-overview--body'}>
          <img
            className={'board-overview--icon'}
            src={success}
            alt="Action Complete Icon"
          />
          <div
            className={
              'board-overview--body__main-content board-overview--body__text-content'
            }
          >
            <h3
              style={{
                justifyContent: 'center',
                paddingBottom: '15%',
                textAlign: 'center',
              }}
            >
              Great work! You have successfully made it through the day and came
              back home with all the nutritious food you need for today.
            </h3>
          </div>
          <div
            className={
              'button--container--column board-overview--button--container--normal-height'
            }
          >
            {/* todo: Convert Link to useHistory in button onClick */}
            <Link style={{ textDecoration: 'none' }} to="/post-simulation">
              <Button
                arrowLeftShortIcon={true}
                centerMode={true}
                darkMode={false}
                enable={true}
                normalText={true}
                normalWidth={true}
                onClick={() => {}}
                textValue={'Next'}
              />
            </Link>
          </div>
        </div>
      );
    } else if (
      props.nutrientsSuccessfullyObtained &&
      !isPlayerAtHome &&
      !props.timeIsUp
    ) {
      return (
        <div className={'board-overview--body'}>
          <img
            className={'board-overview--icon'}
            src={success}
            alt="Action Complete Icon"
          />
          <div
            className={
              'board-overview--body__main-content board-overview--body__text-content'
            }
          >
            <h3
              style={{
                justifyContent: 'center',
                paddingBottom: '15%',
                textAlign: 'center',
              }}
            >
              {`Great work! You've successfully obtained all the nutritious food
              you need for today. Feel free to head home whenever you want.`}
            </h3>
          </div>
          <div
            className={
              'button--container--column board-overview--button--container--normal-height'
            }
          >
            <Button
              arrowLeftShortIcon={true}
              centerMode={true}
              darkMode={false}
              enable={true}
              normalText={true}
              normalWidth={true}
              onClick={() => {
                props.setDisplayGameOver(false);
              }}
              textValue={'Close'}
            />
          </div>
        </div>
      );
    } else if (
      props.nutrientsSuccessfullyObtained &&
      !isPlayerAtHome &&
      props.timeIsUp
    ) {
      return (
        <div className={'board-overview--body'}>
          {Number(getNutritionConsumed(player)) < 90 ? (
            <img
              className={'board-overview--icon'}
              src={errorX}
              alt="Action Complete Icon"
            />
          ) : (
            <div />
          )}
          <div
            className={
              'board-overview--body__main-content board-overview--body__text-content'
            }
          >
            <h3
              style={{
                justifyContent: 'center',
                paddingBottom: '15%',
                textAlign: 'center',
              }}
            >
              Good work. You have successfully obtained all the nutritious food
              you need for today, but you did not make it back home in time.
            </h3>
          </div>
          <div
            className={
              'button--container--column board-overview--button--container--normal-height'
            }
          >
            {
              // todo: Convert Link to useHistory in button onClick
              <Link style={{ textDecoration: 'none' }} to="/post-simulation">
                <Button
                  arrowLeftShortIcon={true}
                  centerMode={true}
                  darkMode={false}
                  enable={true}
                  normalText={true}
                  normalWidth={true}
                  onClick={() => {}}
                  textValue={'Exit to Debrief'}
                />
              </Link>
            }
          </div>
        </div>
      );
    } else if (props.timeIsUp) {
      return (
        <div className={'board-overview--body'}>
          {Number(getNutritionConsumed(player)) < 90 ? (
            <img
              className={'board-overview--icon'}
              src={errorX}
              alt="Action Complete Icon"
            />
          ) : (
            <div />
          )}
          <div
            className={
              'board-overview--body__main-content board-overview--body__text-content'
            }
          >
            <h3
              style={{
                justifyContent: 'center',
                paddingBottom: '15%',
                textAlign: 'center',
              }}
            >
              The day has ended and you did not obtain all the nutritious food
              you need for today. The Community Food Experience is designed to
              explore the impact of food insecurity. Just like in real life, it
              is extremely difficult.
            </h3>
          </div>
          <div
            className={
              'button--container--column board-overview--button--container--normal-height'
            }
          >
            {
              // todo: Convert Link to useHistory in button onClick
              <Link style={{ textDecoration: 'none' }} to="/post-simulation">
                <Button
                  arrowLeftShortIcon={true}
                  centerMode={true}
                  darkMode={false}
                  enable={true}
                  normalText={true}
                  normalWidth={true}
                  onClick={() => {}}
                  textValue={'Exit to Debrief'}
                />
              </Link>
            }
          </div>
        </div>
      );
    }
  }

  return (
    <div className={'board-overview--container'}>
      <div className={'board-overview--wrapper'}>{renderSlide()}</div>
      <span className={'board-overview--background'} />
    </div>
  );
}

export default GameOver;
