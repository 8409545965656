import * as React from 'react';

import errorX from '../../assets/icons/error-x.svg';
import { Store } from '../../interfaces/store';
import { cornerStore } from '../../assets/objects/cornerStore';
import { foodTiger } from '../../assets/objects/foodTiger';
import { foodPantry } from '../../assets/objects/foodPantry';
import { LocationID } from '../../constants';
import CloseIcon from '../misc/CloseIcon';
import { getDefaultWicCart } from '../game-board/GameBoard';
import ShoppingCart from './ShoppingCart';
import Products from './Products';
import './StoreBuilder.css';

function StoreBuilder(props: any) {
  const [displayInfoPopUp, setDisplayInfoPopUp] =
    React.useState<boolean>(false);
  const [option, setOption] = React.useState<number>(0);
  const [items, setItems] = React.useState<string>('');
  const store = (function (): Store {
    switch (props.module) {
      case LocationID.cornerStore:
        return {
          name: 'Corner Store',
          products: cornerStore.products,
        };
      case LocationID.foodTiger:
        return {
          name: 'Food Tiger',
          products: foodTiger.products,
        };
      default:
        return {
          name: 'Food Pantry',
          products: foodPantry.products,
        };
    }
  })();

  const [products, setProducts] = React.useState(store.products);

  const handleCloseWICInstructions = () => {
    setDisplayInfoPopUp(false);
  };
  const handleCloseStoreBuilder = () => {
    // Reset product quantity.
    for (let i = 0; i < products.length; i++) {
      products[i].quantity = 0;
    }
    props.setWicCart(getDefaultWicCart());

    props.setDisplayStoreBuilder(false);
  };

  return (
    <div className={'store-builder--container'}>
      {displayInfoPopUp ? (
        <div className={'store-wic-validation--container'}>
          <div className={'store-wic-validation--popup'}>
            <span className={'transportation-module__close--icon--container'}>
              <CloseIcon
                name="WIC instructions"
                onClick={handleCloseWICInstructions}
              />
            </span>
            <div className={'board-overview--body'}>
              <img
                className={'board-overview--icon'}
                src={errorX}
                alt="Action Complete Icon"
              />
              <div
                className={
                  'board-overview--body__main-content board-overview--body__text-content'
                }
              >
                <h3
                  style={{
                    justifyContent: 'center',
                    paddingBottom: '15%',
                    textAlign: 'center',
                  }}
                >
                  {option === 0
                    ? `There are multiple ${items} items. WIC is
                                        redeemable for one WIC food item in each category
                                        of fruits, vegetables, grains, protein, and dairy.`
                    : option === 1
                    ? `You may only select
                                    two items from here.`
                    : `You must select an item to complete your visit.`}
                </h3>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={'store-builder--wrapper'}>
        <div className={'location-module--wrapper__close--icon--container'}>
          <CloseIcon name={store.name} onClick={handleCloseStoreBuilder} />
        </div>
        <div className={'store-builder__store--name'}>
          <h3>{store.name}</h3>
          <div
            style={{
              display: store.name !== 'Food Pantry' ? 'block' : 'none',
              textAlign: 'center',
            }}
          >
            <p>
              To use WIC, you may pick 1 item per category (fruit, vegetable,
              protein, grain & dairy). Plus, those
            </p>
            <p>groceries will need to be on a separate transaction.</p>
            <p>
              SNAP benefits cannot be used on a cart containing premade food.
            </p>
          </div>
        </div>
        <div className={'store-builder--body__main-content'}>
          <Products products={products} setProducts={setProducts} />
          <ShoppingCart
            setDisplayStoreBuilder={props.setDisplayStoreBuilder}
            setDisplayCheckout={props.setDisplayCheckout}
            products={products}
            setProducts={setProducts}
            setProductsForPayment={props.setProductsForPayment}
            displayInfoPopUp={displayInfoPopUp}
            setDisplayInfoPopUp={setDisplayInfoPopUp}
            items={items}
            setItems={setItems}
            storeName={store.name}
            setOption={setOption}
            option={option}
            wicCart={props.wicCart}
            setWicCart={props.setWicCart}
          />
        </div>
      </div>
      <span className={'store-builder--background'} />
    </div>
  );
}

export default StoreBuilder;
