export default function CarIcon({
  className,
  color,
  title,
}: {
  className: string;
  color: string;
  title: string;
}) {
  return (
    <svg
      className={className}
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <circle cx="82" cy="82" r="82" fill="#F1F1F1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.739 66.2663V58.9186C114.739 50.7115 108.065 44.0371 99.8575 44.0371H62.6538C54.4467 44.0371 47.7724 50.7115 47.7724 58.9186V66.3519L47.4896 66.3668C46.5513 66.4345 45.6736 66.8552 45.033 67.5441C44.3924 68.233 44.0366 69.139 44.0371 70.0797V77.5204C44.0371 78.5071 44.4291 79.4534 45.1268 80.1511C45.8245 80.8488 46.7708 81.2408 47.7575 81.2408H47.7724V103.563C47.7724 106.298 49.2717 108.667 51.4779 109.962V114.724C51.4779 115.711 51.8698 116.657 52.5675 117.355C53.2652 118.053 54.2115 118.445 55.1982 118.445H58.9186C59.9053 118.445 60.8516 118.053 61.5493 117.355C62.247 116.657 62.639 115.711 62.639 114.724V111.004H99.8427V114.724C99.8427 115.711 100.235 116.657 100.932 117.355C101.63 118.053 102.576 118.445 103.563 118.445H107.283C108.27 118.445 109.216 118.053 109.914 117.355C110.612 116.657 111.004 115.711 111.004 114.724V109.977C112.135 109.33 113.076 108.397 113.731 107.271C114.387 106.145 114.735 104.866 114.739 103.563V81.2408C115.726 81.2408 116.672 80.8488 117.37 80.1511C118.067 79.4534 118.459 78.5071 118.459 77.5204V70.3104C118.502 69.733 118.411 69.1535 118.192 68.6176C117.436 66.8058 115.829 66.3705 114.739 66.2663ZM107.306 103.563H55.2131V84.9612H107.302L107.306 103.563ZM77.5353 77.5204H55.2131V62.639H77.5353V77.5204ZM107.298 77.5204H84.9761V62.639H107.298V77.5204ZM62.6538 51.4779H99.8575C102.596 51.4779 104.973 52.9846 106.264 55.1982H56.2474C57.5383 52.9846 59.9157 51.4779 62.6538 51.4779ZM64.4988 102.879C67.5808 102.879 70.0793 100.381 70.0793 97.2989C70.0793 94.2169 67.5808 91.7184 64.4988 91.7184C61.4167 91.7184 58.9182 94.2169 58.9182 97.2989C58.9182 100.381 61.4167 102.879 64.4988 102.879ZM103.562 97.2989C103.562 100.381 101.064 102.879 97.9818 102.879C94.8998 102.879 92.4013 100.381 92.4013 97.2989C92.4013 94.2169 94.8998 91.7184 97.9818 91.7184C101.064 91.7184 103.562 94.2169 103.562 97.2989Z"
        fill={color}
      />
    </svg>
  );
}
