import './CloseIcon.css';

interface Props {
  color?: string;
  className?: string;
  fill?: string;
  isDelete?: boolean;
  name: string;
  onClick: () => void;
}

const CloseIcon = ({
  color = '#5E6163',
  fill = 'none',
  isDelete,
  name,
  onClick,
}: Props): JSX.Element => {
  return (
    <svg
      className="close-icon"
      fill={fill}
      height="47"
      onClick={onClick}
      style={{ color }}
      viewBox="0 0 47 47"
      width="47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{`${isDelete ? 'Delete' : 'Close'} ${name}`}</title>
      <circle
        r="15.617"
        transform="matrix(0.707106 0.707108 -0.707106 0.707108 23.5 23.5)"
        fill={fill}
        stroke={fill === 'none' ? 'currentColor' : fill}
        strokeWidth="2"
      />
      <path
        d="M29.1808 17.4902L17.7323 28.9387M17.6038 17.3617L29.1808 28.9387"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CloseIcon;
