import Data from '../assets/objects/members.json';
import {
  setMoney,
  setTicketFromCommunityKitchen,
  startingCash,
} from '../assets/functions/PlayerFunctions';
import SessionManager from '../assets/classes/SessionManager';
import { Player, PlayerInfo } from '../types';

export function createSessionMember() {
  const member: Omit<PlayerInfo, 'requiredFat' | 'federalAssistance'> =
    Data[Math.floor(Math.random() * Data.length)];

  let busTickets = member.startingBusTokens;
  if (member.busPass) {
    busTickets += 30;
  }

  const player: Player = {
    playerInfo: {
      firstName: member.firstName,
      middleName: member.middleName,
      lastName: member.lastName,
      gender: member.gender,
      description: member.description,
      fullDescription: member.fullDescription,
      phone: member.phone,
      ssn: member.ssn,
      address: member.address,
      city: member.city,
      state: member.state,
      zip: member.zip,
      primaryLanguage: member.primaryLanguage,
      age: member.age,
      birthMonth: member.birthMonth,
      birthDay: member.birthMonth,
      children: member.children,
      socialSecurityIncome: member.socialSecurityIncome,
      jobIncome: member.jobIncome,
      additionalIncome: member.additionalIncome,
      temporaryAssistance: member.temporaryAssistance,
      numInHouse: member.numInHouse,
      startingBusTokens: member.startingBusTokens,
      busPass: member.busPass,
      hasCar: member.hasCar,
      pregnant: member.pregnant,
      single: member.single,
      married: member.married,
      jointTax: member.jointTax,
      inUSSixMonth: member.inUSSixMonth,
      allIncomeInUS: member.allIncomeInUS,
      hourlyIncome: member.hourlyIncome,
      requiredCalories: member.requiredCalories,
      requiredGrain: member.requiredGrain,
      requiredFat: 1,
      requiredProtein: member.requiredProtein,
      requiredDairy: member.requiredDairy,
      requiredFruit: member.requiredFruit,
      requiredVegetable: member.requiredVegetable,
      requiredSodium: member.requiredSodium,
      requiredSatFat: member.requiredSatFat,
      requiredSugar: member.requiredSugar,
      requiredExtra: member.requiredExtra,
      rentExpense: member.rentExpense,
      utilitiesExpense: member.utilitiesExpense,
      medicalExpense: member.medicalExpense,
      transportationExpense: member.transportationExpense,
      phoneExpense: member.phoneExpense,
      childcareExpense: member.childcareExpense,
      taxesExpense: member.taxesExpense,
      otherExpense: member.otherExpense,
      paysTaxes: member.paysTaxes,
      healthState: member.healthState,
      federalAssistance: false,
      qualifyFor: member.qualifyFor,
    },
    busTickets: busTickets,
    money: 0,
    snapFunds: 0,
    ctcAcquired: 0,
    eitcAcquired: 0,
    snapAcquired: 0,
    voucher: null,
    calories: 0,
    grain: 0,
    fat: 0,
    protein: 0,
    dairy: 0,
    fruit: 0,
    vegetable: 0,
    extra: 0,
    sodium: 0,
    sugar: 0,
    satFat: 0,
    usedVita: false,
    usedFoodPantry: false,
    usedWIC: false,
    usedSnap: false,
    usedCommunityKitchen: false,
    hadWic: false,
    onBus: false,
    hasTemporaryRide: false,
    carBrokenDown: false,
    hasKidBeenSick: false,
    isHome: true,
    location: 0,
    communityKitchenBusTicket: false,
    VITAServiceIsClosed: Math.random() < 0.5, // 50% of probability.
  };

  setMoney(startingCash(player), player);
  setTicketFromCommunityKitchen(player);
  SessionManager.setActive(false);
  sessionStorage.setItem('InSession', '1');

  return player;
}
