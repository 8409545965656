import NutritionChart from '../misc/NutritionChart';

function Slide(props: any) {
  function renderSlice() {
    switch (props.name) {
      case 'NutritionCheck':
        return (
          <div>
            <h1>Nutrition status</h1>
            <NutritionChart />
          </div>
        );
      case 'BudgetCheck':
        // TODO: Get values from localStorage.
        const budget = `$${Math.floor(Math.random() * 8) + 1}`;
        const cash = `Cash: $${Math.floor(Math.random() * 8) + 1}`;
        const eitc = `EITC: $${Math.floor(Math.random() * 8) + 1}`;
        const ctc = `CTC: $${Math.floor(Math.random() * 8) + 1}`;
        const snap = `SNAP: $${Math.floor(Math.random() * 8) + 1}`;

        return (
          <div>
            <h1>Budget</h1>
            <p>{budget}</p>
            <div>
              <p>{cash}</p>
              <p>{eitc}</p>
              <p>{ctc}</p>
              <p>{snap}</p>
            </div>
          </div>
        );
      case 'UserCheck':
        // TODO: Get values from localStorage.
        const info = "The user's description maybe?";

        return (
          <div>
            <h1>Profile</h1>
            <p>{info}</p>
          </div>
        );
    }
  }

  return (
    <div>
      {/* todo: refactor button to allow these styles */}
      <button
        onClick={function () {
          props.blockSlide(false);
        }}
      >
        {' '}
        Close
      </button>
      <p>Slide</p>
      <div>{renderSlice()}</div>
    </div>
  );
}

export default Slide;
