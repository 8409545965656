import CardProduct from './CardProduct';

function Products(props: any) {
  return (
    <div className={'store-builder__store__products--container'}>
      {props.products.map((product: any) => (
        <CardProduct
          key={product.key}
          product={product}
          products={props.products}
          setProducts={props.setProducts}
        />
      ))}
    </div>
  );
}

export default Products;
