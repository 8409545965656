import { formatter } from '../../assets/functions/PlayerFunctions';
import ellipseOrange from '../../assets/icons/wic.svg';
import './CardProduct.css';

function printPrice(price: number): string {
  return `$${formatter.format(price)}`;
}

function displayNutrition(obj: any[]): string {
  let result = '';
  for (const i in obj) {
    result += obj[i] + ' ' + i + ', ';
  }
  return result.substr(0, result.lastIndexOf(','));
}

function displayBadFoodNutrition(obj: any[]): string {
  let result = '';
  //@masaki refactor to use switch?
  for (const i in obj) {
    if (i === 'satFat') {
      result += obj[i] + 'g ' + 'saturated fat' + ', ';
    }
    if (i === 'sodium') {
      result += obj[i] + 'mg ' + i + ', ';
    }
    if (i === 'sugar') {
      result += obj[i] + 'g ' + i + ', ';
    }
  }
  return result.substr(0, result.lastIndexOf(','));
}

function CardProduct(props: any) {
  return (
    <div className={'card-product--wrapper'}>
      <h2 className={'card-product__product--title'}>{props.product.name}</h2>
      <h2 className={'card-product__product--title'}>
        {printPrice(props.product.price)}
      </h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={'card-product__product--icon__container'}>
          {props.product.wicType.length > 0 ? (
            <img
              className={'card-product__product__wic--icon'}
              src={ellipseOrange}
              alt={'Product Icon'}
            />
          ) : null}
          <div className={'card-product__product--icon--container'}>
            <img
              className={'card-product__product--icon'}
              src={props.product.imgPath}
              alt={'Product Icon'}
            />
          </div>
        </div>
      </div>
      <p className={'card-product__product--description'}>
        {props.product.premade ? 'Premade food item' : ''}
      </p>
      {props.product.wicType.length > 0 ? (
        <p className={'card-product__product--description'}>
          WIC: {props.product.wicType.join(', ')}
        </p>
      ) : undefined}
      <p className={'card-product__product--description'}>
        Nutrition: {displayNutrition(props.product.nutrition)} <br />
        {displayBadFoodNutrition(props.product.badFoodNutrition)}
      </p>
      <span
        className={'card-product__button'}
        onClick={() => {
          const products = [];
          products.push(...props.products);
          props.product.quantity += 1;
          props.setProducts(products);
        }}
      >
        Add to cart
      </span>
    </div>
  );
}

export default CardProduct;
