import { Status } from '../../assets/functions/Status';
import errorX from '../../assets/icons/error-x.svg';
import success from '../../assets/icons/success.svg';

import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import './TransportationModule.css';

function CarModule(props: any) {
  function renderCloseButton() {
    return (
      <Button
        arrowLeftShortIcon={true}
        centerMode={true}
        darkMode={true}
        enable={true}
        normalText={true}
        normalWidth={true}
        onClick={() => {
          props.setDisplayCarModule(false);
        }}
        textValue={'Close'}
      />
    );
  }

  const handleClose = () => {
    props.setDisplayCarModule(false);
  };

  return (
    <div style={{ zIndex: 5 }} className={'board-overview--container'}>
      {
        <div className={'board-overview--wrapper'}>
          <span className={'transportation-module__close--icon--container'}>
            <CloseIcon name="car module" onClick={handleClose} />
          </span>
          <div className={'board-overview--body'}>
            <img
              className={'board-overview--icon'}
              src={
                props.carHasIssues && props.carHasBeenRepaired
                  ? success
                  : errorX
              }
              alt="Action Complete Icon"
            />
            <div
              className={
                'board-overview--body__main-content board-overview--body__text-content'
              }
            >
              <h3
                style={{
                  justifyContent: 'center',
                  paddingBottom: '15%',
                  textAlign: 'center',
                }}
              >
                {props.carHasIssues && props.carHasBeenRepaired
                  ? Status.carRepaired
                  : Status.carBrokeDown}
              </h3>
            </div>
            <div
              className={
                'button--container--column board-overview--button--container--normal-height'
              }
            >
              {renderCloseButton()}
            </div>
          </div>
        </div>
      }
      <span className={'board-overview--background'} />
    </div>
  );
}

export default CarModule;
