import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  getNutritionConsumed,
  parsePlayer,
} from '../../assets/functions/PlayerFunctions';
import styles from '../../assets/styles/styles.module.css';
import Button from '../misc/Button';
import HealthStatus from '../misc/HealthStatus';
import NutritionChart from '../misc/NutritionChart';
import Point from '../misc/Point';
import './PostSimulation.css';

function PostSimulation() {
  const history = useHistory();

  if (sessionStorage.getItem('player') === null) {
    history.push('/');
  }

  const [index, setIndex] = useState<number>(0);
  const player = parsePlayer(sessionStorage.getItem('player'));

  function renderNextButton(
    isSolo = false,
    textValue = 'Next',
    onClick = () => {
      setIndex(index + 1);
    },
    darkMode = false
  ) {
    return (
      <Button
        arrowLeftShortIcon
        centerMode={isSolo}
        darkMode={darkMode}
        enable
        normalText
        normalWidth={isSolo}
        onClick={onClick}
        textValue={textValue}
      />
    );
  }

  function renderGoBackButton() {
    return (
      <Button
        darkMode
        enable
        isGoBack
        normalText
        onClick={() => {
          setIndex(index - 1);
        }}
        textValue="Go Back"
      />
    );
  }

  const Summary = () => {
    const Description = () => (
      <>
        <div className={'post-simulation--info-container'}>
          <p>
            The Community Food Experience is designed to explore the impact of
            food insecurity. Just like in real life, it is extremely difficult.
          </p>
        </div>
      </>
    );
    if (Number(getNutritionConsumed(player)) >= 100) {
      return (
        <>
          <div className={'post-simulation--header-container'}>
            <h1>Great job! You successfully made it through the day.</h1>
          </div>
          <Description />
        </>
      );
    } else if (Number(getNutritionConsumed(player)) >= 90) {
      return (
        <>
          <div className={'post-simulation--header-container'}>
            <h1>You came very close to getting the nutrition you needed!</h1>
          </div>
          <div className={'post-simulation--info-container'}>
            <p>
              The Community Food Experience is also designed to explore services
              <br />
              and programs that can help.
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={'post-simulation--header-container'}>
          <h1>
            The day has ended and you did not obtain all the nutritious food you
            need for today
          </h1>
        </div>
        <Description />
      </>
    );
  };

  function renderPostSimulationPage() {
    switch (index) {
      case 0:
        return (
          <div>
            <Summary />
            <div className={'button--container--row'}>
              {renderNextButton(true)}
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>{getNutritionConsumed(player)}% complete</h1>
            </div>
            <div className={'post-simulation--info'}>
              <p>
                Here’s a breakdown of the calories and nutritious food you
                <br />
                were able to acquire.
              </p>
            </div>
            <div className={'post-simulation--chart-container'}>
              <NutritionChart />
            </div>
            <HealthStatus status={player.playerInfo.healthState} />
            <div>
              <p className="post-simulation--health-status-body">
                It&apos;s important to remember that nutrition is more than
                calories and food groups. Is there anything else you noticed
                about the food you accessed today that could either help or hurt
                your health status?
              </p>
              <div className={'button--container--row'}>
                {renderNextButton(true)}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>
                Food insecurity affects millions of <br /> American households.
              </h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                Find the latest food insecurity information at our resource page
                <br />
                following the debrief.
              </p>
            </div>
            <div>
              <div className={'button--container--row'}>
                {renderGoBackButton()}
                {renderNextButton(
                  false,
                  'Retry',
                  () => {
                    history.push('/');
                  },
                  true
                )}
                {renderNextButton(false, 'Debrief', () => {
                  setIndex(index + 1);
                })}
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>
                Review and discuss
                <br />
                the following questions.
              </h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                Reflect on the barriers you experienced and the
                <br />
                assistance you received.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={1} />
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>How do you feel right now?</h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                Emotional stress and trauma are often overlooked
                <br />
                as barriers to food access.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={2} />
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>
                Were you able to access 100% <br />
                of your nutrition needs?
              </h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>&nbsp;</p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={3} />
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>What services helped you?</h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                There are services and programs that help address food <br />
                insecurity but not everyone has access to them.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={4} />
            </div>
          </div>
        );
      case 7:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>What barriers did you face?</h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                Barriers can be a result of individual, community
                <br />
                or systemic factors.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={5} />
            </div>
          </div>
        );
      case 8:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>
                How do you think your income affected your
                <br />
                access to resources?
              </h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                Millions of working Americans are experiencing food insecurity,
                <br />
                and can be ineligible for programs and services.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={6} />
            </div>
          </div>
        );
      case 9:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>
                Did you have a job and/or children? How would this affect your
                ability to navigate this situation in real life?
              </h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                Programs and services are typically not geared towards
                <br />
                working individuals and those with
                <br />
                families, making access to these more difficult.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton()}
            </div>
            <div className={'post-simulation--point-container'}>
              <Point numberOfPoints={7} currentPoint={7} />
            </div>
          </div>
        );
      case 10:
        return (
          <div>
            <div className={'post-simulation--header-container'}>
              <h1>
                Every little bit makes
                <br />a difference.
              </h1>
            </div>
            <div className={'post-simulation--info-container'}>
              <p>
                There are many ways to get involved in the community.
                <br />
                Click below to volunteer, advocate, donate, and educate.
              </p>
            </div>
            <div className={'button--container--row'}>
              {renderGoBackButton()}
              {renderNextButton(
                false,
                'Retry',
                () => {
                  history.push('/');
                },
                true
              )}
              <Button
                arrowLeftShortIcon
                centerMode
                enable
                normalText
                onClick={() => {
                  window.open('https://www.acfb.org/volunteer/', '_blank');
                }}
                textValue={'Get Involved'}
              />
            </div>
          </div>
        );
    }
  }

  return (
    <div className={styles.container}>
      <div className={'post-simulation--wrapper'}>
        {renderPostSimulationPage()}
      </div>
    </div>
  );
}

export default PostSimulation;
