interface Props {
  color?: string;
  className?: string;
}

const CheckmarkIcon = ({ color = '#64a664' }: Props): JSX.Element => {
  return (
    <svg
      className="checkmark-icon"
      fill="none"
      style={{ color }}
      width="47"
      height="47"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Checkmark icon</title>
      <path
        d="M6.04288 28.1287C12.1417 34.2275 22.0298 34.2275 28.1286 28.1287C34.2274 22.0299 34.2274 12.1417 28.1286 6.04291C22.0298 -0.0559226 12.1417 -0.0559226 6.04288 6.04291C-0.0559311 12.1417 -0.0559304 22.0299 6.04288 28.1287Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="10.5065"
        y2="-1"
        transform="matrix(0.742233 0.670142 -0.660876 0.750495 9 17.1097)"
        stroke="#fff"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="15.8465"
        y2="-1"
        transform="matrix(-0.573904 0.818923 -0.811651 -0.584142 24.5929 10)"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CheckmarkIcon;
