import * as React from 'react';
import { Link } from 'react-router-dom';

import SessionManager from '../../assets/classes/SessionManager';
import info from '../../assets/icons/info.svg';
import styles from '../../assets/styles/styles.module.css';

import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import './Home.css';

function Home() {
  const [displayInfoPopUp, setDisplayInfoPopUp] =
    React.useState<boolean>(false);

  sessionStorage.removeItem('player');
  sessionStorage.removeItem('playerInitState');
  sessionStorage.removeItem('meals');
  sessionStorage.removeItem('name');
  sessionStorage.removeItem('InSession');
  SessionManager.setActive(false);
  SessionManager.setHour(8);
  SessionManager.setMinute(0);
  SessionManager.setTime('am');
  SessionManager.setIsOver(false);

  const handleClose = () => {
    setDisplayInfoPopUp(false);
  };

  return (
    <div className={styles.container}>
      <div className={'home--container'}>
        {displayInfoPopUp ? (
          <div className={'home-overview--background--container'}>
            <span
              className={'home-overview--background'}
              onClick={function () {
                setDisplayInfoPopUp(false);
              }}
            />
            <div className={'home-overview--body'}>
              <div
                className={'location-module--wrapper__close--icon--container'}
              >
                <CloseIcon
                  name="Community Food Experience info modal"
                  onClick={handleClose}
                />
              </div>
              <p className={'home-overview__text--body'}>
                This is a digital version of the Atlanta Community Food Bank’s
                in-person Community Food Experience. The Atlanta Community Food
                Bank serves 29 Georgia counties, working with our partner
                network to annually help people struggling with hunger and food
                insecurity. We hope this simulation helps illuminate the
                barriers to food security, and inspire you to join us in our
                mission to fight hunger. To learn more and get involved, please
                visit our website at acfb.org or email education@acfb.org. This
                project could not have been possible without the generous help
                and work of Georgia Tech design students Rachel Tierney, Tommy
                Landman, Tuan Nguyen, Richard Qiu, and Dean Sun, along with
                Victoria Vasys, Goods & Services and NearShore Technology.
              </p>
            </div>
          </div>
        ) : null}
        <img
          className={'home--info__button--container'}
          src={info}
          alt={'Info Icon'}
          onClick={function () {
            setDisplayInfoPopUp(true);
          }}
        />
        <p className="home--subText">
          Understanding the barriers to food security
        </p>
        <div className={'home--button--container'}>
          <Link style={{ textDecoration: 'none' }} to="/game-instructions">
            <Button
              arrowLeftShortIcon={false}
              centerMode={true}
              darkMode={false}
              enable={true}
              normalText={true}
              normalWidth={true}
              onClick={() => {}}
              textValue={'Begin'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
