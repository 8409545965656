import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/styles/styles.module.css';
import './App.css';
import Home from './components/home/Home';
import GameInstructions from './components/game-instructions/GameInstructions';
import GameBoard from './components/game-board/GameBoard';
import PostSimulation from './components/post-simulation/PostSimulation';

function App() {
  return (
    <div>
      <div className={'container'}>
        <div className={'center'}>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={Home} exact />
              <Route
                path="/game-instructions"
                component={GameInstructions}
                exact
              />
              <Route path="/game-board" component={GameBoard} exact />
              <Route path="/post-simulation" component={PostSimulation} exact />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
