class SessionManager {
  constructor() {
    this.active = false;
    this.hour = 8;
    this.minute = 0;
    this.time = 'am';
    this.isOver = false;
  }

  setActive(item) {
    this.active = item;
  }

  isActive() {
    return this.active;
  }

  setHour(militaryTimeHour) {
    let hour = militaryTimeHour;
    if (militaryTimeHour === 12) {
      this.setTime('pm');
    }
    if (militaryTimeHour === 13) {
      hour = 1;
      this.setTime('pm');
    }
    if (militaryTimeHour === 14) {
      hour = 2;
      this.setTime('pm');
    }
    if (militaryTimeHour === 15) {
      hour = 3;
      this.setTime('pm');
    }
    if (militaryTimeHour === 16) {
      hour = 4;
      this.setTime('pm');
    }
    if (militaryTimeHour === 17) {
      hour = 5;
      this.setTime('pm');
    }
    if (militaryTimeHour === 18) {
      hour = 6;
      this.setTime('pm');
    }
    this.hour = hour;

    if (
      (Number(this.hour) >= 6 &&
        Number(this.hour) < 12 &&
        this.time === 'pm') ||
      (Number(this.hour) <= 8 && Number(this.hour) >= 12 && this.time === 'am')
    ) {
      this.setIsOver(true);
      this.hour = 6;
      this.time = 'pm';
    }
  }

  setTime(item) {
    this.time = item;
  }

  setIsOver(item) {
    this.isOver = item;
  }

  getHour() {
    return this.hour;
  }

  setMinute(item) {
    this.minute = item;
  }

  getMinute() {
    return this.minute;
  }

  getTime() {
    return this.time;
  }

  getIsOver() {
    return this.isOver;
  }
}

const instance = new SessionManager();
export default instance;
