import Button from '../misc/Button';
import CloseIcon from '../misc/CloseIcon';
import './TransportationModule.css';

function BusInstructions(props: any) {
  const handleClose = () => {
    props.setShowBusInstructions(false);
  };
  return (
    <div className={'board-overview--container'}>
      <div className={'board-overview--wrapper__small board-overview--wrapper'}>
        <div className={'transportation-module__close--icon--container'}>
          <CloseIcon name="bus instructions" onClick={handleClose} />
        </div>
        <div className={'board-overview--body bus-instructions'}>
          <div style={{ marginTop: '6%' }}>
            <h3 style={{ textAlign: 'center' }}>
              To take the bus, click on the bus stop closest to your
              <br />
              destination. Once the bus has arrived, you have the option
              <br />
              to use a bus ticket, or purchase one bus ticket for $2.50.
            </h3>
          </div>
          <div>
            <Button
              arrowLeftShortIcon={true}
              centerMode={true}
              darkMode={true}
              enable={true}
              normalText={true}
              normalWidth={true}
              onClick={() => {
                props.setShowBusInstructions(false);
              }}
              textValue={'Select a bus stop'}
            />
          </div>
        </div>
      </div>
      <span className={'board-overview--background'} />
    </div>
  );
}

export default BusInstructions;
