import TransportationIcon from './TransportationIcon';
import './TransportationOptions.css';

export default function TrasportationOptions({
  busContent,
  busDisabled,
  carContent,
  carDisabled,
  showIndicators,
  walkContent,
}: {
  busDisabled?: boolean;
  busContent?: any;
  carContent?: any;
  carDisabled?: boolean;
  showIndicators?: boolean;
  walkContent?: any;
}) {
  return (
    <div className="transportation-options">
      <div
        className={`transportation-icon-and-text${
          carDisabled ? ' disabled' : ''
        }`}
      >
        <TransportationIcon
          disabled={carDisabled}
          showIndicators={showIndicators}
          type="car"
        />
        <h2>Car</h2>
        {carContent}
      </div>
      <div
        className={`transportation-icon-and-text${
          busDisabled ? ' disabled' : ''
        }`}
      >
        <div className="transportation-icon-with-indicator">
          <TransportationIcon
            disabled={busDisabled}
            showIndicators={showIndicators}
            type="bus"
          />
        </div>
        <h2>Bus</h2>
        {busContent}
      </div>
      <div className="transportation-icon-and-text">
        <TransportationIcon showIndicators={showIndicators} type="walk" />
        <h2>Walk</h2>
        {walkContent}
      </div>
    </div>
  );
}
