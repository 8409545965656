import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { parsePlayer } from '../../assets/functions/PlayerFunctions';
import homeStroke from '../../assets/images/places/home_stroke.svg';
import communityKitchenStroke from '../../assets/images/places/community_kitchen_stroke.svg';
import cornerStoreStroke from '../../assets/images/places/corner_store_stroke.svg';
import snapOfficeStroke from '../../assets/images/places/snap_office_stroke.svg';
import vitaServicesStroke from '../../assets/images/places/vita_services_stroke.svg';
import foodTigerStroke from '../../assets/images/places/food_tiger_stroke.svg';
import foodPantryStroke from '../../assets/images/places/food_pantry_stroke.svg';
import wicClinicStroke from '../../assets/images/places/wic_clinic_stroke.svg';
import busStopStroke from '../../assets/images/places/bus_stop_stroke.svg';
import snapVitaBusSopStroke from '../../assets/images/places/snap_vita_bus_stop_stroke.svg';
import foodTigerBusStopStroke from '../../assets/images/places/food_tiger_bus_stop_stroke.svg';
import foodPantryWicClincBusStopStroke from '../../assets/images/places/food_pantry_wic_clinc_bus_stop_stroke.svg';
import styles from '../../assets/styles/styles.module.css';
import { LocationID } from '../../constants';
import { Product, WICNutrientsType } from '../../types';
import Avatar from '../avatar/Avatar';
import Checkout from '../checkout/Checkout';
import Dashboard from '../dashboard/Dashboard';
import LedgerSlide from '../dashboard/LedgerSlide';
import LocationModule from '../location-module/LocationModule';
import SlideMenu from '../menu/SlideMenu';
import StoreBuilder from '../store/StoreBuilder';
import BoardOverview from './BoardOverview';
import BusInstructions from './BusInstructions';
import CarModule from './CarModule';
import GameOver from './GameOver';
import HomeModule from './HomeModule';
import TransportationModule from './TransportationModule';
import boardStyles from './BoardOverview.module.css';
import './GameBoard.css';
import { MenuPage } from '../../types';

function Place(props: any) {
  const [hover, setHover] = useState<boolean>(false);

  const handler = useMemo(
    () => ({
      onMouseOver() {
        setHover(true);
      },
      onMouseOut() {
        setHover(false);
      },
    }),
    []
  );

  return (
    <div>
      <img
        {...handler}
        style={{ opacity: hover ? 100 : 0 }}
        className={props.placeIconClassName}
        src={props.placeIcon}
        alt={'Place Icon'}
      />
    </div>
  );
}

export const getDefaultWicCart = (): WICNutrientsType => {
  return {
    dairy: false,
    fruit: false,
    grain: false,
    protein: false,
    vegetable: false,
  };
};

function GameBoard() {
  const history = useHistory();

  if (sessionStorage.getItem('player') === null) {
    history.push('/');
  }

  const [displayOverviews, setDisplayBoardOverviews] = useState<boolean>(true);
  const [displayGameOver, setDisplayGameOver] = useState<boolean>(false);
  const [displayLedgerSlide, setDisplayLedgerSlide] = useState<boolean>(false);
  const [ledgerName, setLedgerName] = useState('');
  const [displayMenuSlide, setDisplayMenuSlide] = useState<boolean>(false);
  const [timeClass, setTimeClass] = useState('');
  const [nutritionClass, setNutritionClass] = useState('');
  const [budgetClass, setBudgetClass] = useState('');
  const [profileClass, setProfileClass] = useState('');
  const [menuClass, setMenuClass] = useState('');
  const [displayTransportationModule, setDisplayTransportationModule] =
    useState<boolean>(false);
  const [displayLocationModule, setDisplayLocationModule] =
    useState<boolean>(false);
  const [displayCheckout, setDisplayCheckout] = useState<boolean>(false);
  const [displayStoreBuilder, setDisplayStoreBuilder] =
    useState<boolean>(false);
  const [displayHomeModule, setDisplayHomeModule] = useState<boolean>(false);
  const [module, setModule] = useState<LocationID | undefined>();
  // Here we store the products for payment.
  const [productsForPayment, setProductsForPayment] = useState<Product[]>([]);
  const [wicCart, setWicCart] = useState<any>(getDefaultWicCart());
  const [timeIsUp, setTimeIsUp] = useState<boolean>(false);
  const [warningTime, setWarningTime] = useState<boolean>(false);
  const [nutrientsSuccessfullyObtained, setNutrientsSuccessfullyObtained] =
    useState<boolean>(false);
  const [carEnabled, setCarEnabled] = useState<boolean>(true);
  const [displayCarModule, setDisplayCarModule] = useState<boolean>(false);
  const [carHasIssues, setCarHasIssues] = useState<boolean>(false);
  const [carHasBeenRepaired, setCarHasBeenRepaired] = useState<boolean>(false);
  const [showBusInstructions, setShowBusInstructions] =
    useState<boolean>(false);
  const [currentMenuPage, setCurrentMenuPage] = useState<MenuPage>(
    MenuPage.Start
  );

  function transparentPods(podName: string, boardOverview?: boolean) {
    switch (podName) {
      case 'TimeCheck':
        if (boardOverview) {
          setTimeClass(boardStyles.pulse);
          setNutritionClass(boardStyles.opacity);
          setBudgetClass(boardStyles.opacity);
          setProfileClass(boardStyles.opacity);
          setMenuClass(boardStyles.opacity);
        }
        break;
      case 'NutritionCheck':
        if (boardOverview) {
          setTimeClass(boardStyles.opacity);
          setNutritionClass(boardStyles.pulse);
          setBudgetClass(boardStyles.opacity);
          setProfileClass(boardStyles.opacity);
          setMenuClass(boardStyles.opacity);
        } else {
          setTimeClass('');
          setNutritionClass(boardStyles.border);
          setBudgetClass('');
          setProfileClass('');
          setMenuClass('');
        }
        break;
      case 'BudgetCheck':
        if (boardOverview) {
          setTimeClass(boardStyles.opacity);
          setNutritionClass(boardStyles.opacity);
          setBudgetClass(boardStyles.pulse);
          setProfileClass(boardStyles.opacity);
          setMenuClass(boardStyles.opacity);
        } else {
          setTimeClass('');
          setNutritionClass('');
          setBudgetClass(boardStyles.border);
          setProfileClass('');
          setMenuClass('');
        }
        break;
      case 'UserCheck':
        if (boardOverview) {
          setTimeClass(boardStyles.opacity);
          setNutritionClass(boardStyles.opacity);
          setBudgetClass(boardStyles.opacity);
          setProfileClass(boardStyles.pulse);
          setMenuClass(boardStyles.opacity);
        } else {
          setTimeClass('');
          setNutritionClass('');
          setBudgetClass('');
          setProfileClass(boardStyles.border);
          setMenuClass('');
        }
        break;
      case 'MenuCheck':
        if (boardOverview) {
          setTimeClass(boardStyles.opacity);
          setNutritionClass(boardStyles.opacity);
          setBudgetClass(boardStyles.opacity);
          setProfileClass(boardStyles.opacity);
          setMenuClass(boardStyles.pulse);
        } else {
          setTimeClass('');
          setNutritionClass('');
          setBudgetClass('');
          setProfileClass('');
          setMenuClass(boardStyles.pulse);
        }
        break;
      default:
        setTimeClass('');
        setNutritionClass('');
        setBudgetClass('');
        setProfileClass('');
        setMenuClass('');
        break;
    }
  }

  function blockLedgerSlide(block: boolean, ledgerName: string) {
    setDisplayLedgerSlide(block);
    setLedgerName(ledgerName);
    transparentPods(ledgerName);
  }

  function blockMenuSlide(block: boolean) {
    setDisplayMenuSlide(block);
    setTimeClass(block ? boardStyles.opacity : '');
    setNutritionClass(block ? boardStyles.opacity : '');
    setBudgetClass(block ? boardStyles.opacity : '');
    setProfileClass(block ? boardStyles.opacity : '');
  }

  function renderLocationEventMap() {
    return (
      <div>
        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.house);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--home__stroke'}
            placeIcon={homeStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.communityKitchen);
          }}
        >
          <Place
            placeIconClassName={
              'game-board--place-icon--community-kitchen__stroke'
            }
            placeIcon={communityKitchenStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.cornerStore);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--corner-store__stroke'}
            placeIcon={cornerStoreStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.snapOffice);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--snap-office__stroke'}
            placeIcon={snapOfficeStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.vitaServices);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--vita-services__stroke'}
            placeIcon={vitaServicesStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.foodTiger);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--food-tiger__stroke'}
            placeIcon={foodTigerStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.foodPantry);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--food-pantry__stroke'}
            placeIcon={foodPantryStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.wicClinic);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--wic-clinic__stroke'}
            placeIcon={wicClinicStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.localNeighborhoodBusStop);
          }}
        >
          <Place
            placeIconClassName={'game-board--place-icon--bus-stop__stroke'}
            placeIcon={busStopStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.snapVitaBusStop);
          }}
        >
          <Place
            placeIconClassName={
              'game-board--place-icon--snap-vita-bus-stop__stroke'
            }
            placeIcon={snapVitaBusSopStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.foodTigerBusStop);
          }}
        >
          <Place
            placeIconClassName={
              'game-board--place-icon--food-tiger-bus-stop__stroke'
            }
            placeIcon={foodTigerBusStopStroke}
          />
        </div>

        <div
          onClick={() => {
            setDisplayTransportationModule(true);
            setModule(LocationID.pantryWicBusStop);
          }}
        >
          <Place
            placeIconClassName={
              'game-board--place-icon--food-pantry-wic-clinc-bus-stop__stroke'
            }
            placeIcon={foodPantryWicClincBusStopStroke}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={'game-board--container'}>
        {displayOverviews ? (
          <BoardOverview
            transparentPods={transparentPods}
            setDisplayBoardOverviews={setDisplayBoardOverviews}
          />
        ) : null}

        {displayLedgerSlide ? (
          <LedgerSlide blockLedgerSlide={blockLedgerSlide} name={ledgerName} />
        ) : null}

        {displayMenuSlide ? (
          <SlideMenu
            setCurrentMenuPage={setCurrentMenuPage}
            currentMenuPage={currentMenuPage}
            blockMenuSlide={blockMenuSlide}
            setTimeIsUp={setTimeIsUp}
            setDisplayGameOver={setDisplayGameOver}
          />
        ) : null}

        {
          <div>
            <Dashboard
              showLedgerInfo={blockLedgerSlide}
              timeClass={timeClass}
              nutritionClass={nutritionClass}
              budgetClass={budgetClass}
              profileClass={profileClass}
              menuClass={menuClass}
              setDisplayGameOver={setDisplayGameOver}
              setDisplayTransportationModule={setDisplayTransportationModule}
              setModule={setModule}
              timeIsUp={timeIsUp}
              setTimeIsUp={setTimeIsUp}
              warningTime={warningTime}
              setWarningTime={setWarningTime}
              nutrientsSuccessfullyObtained={nutrientsSuccessfullyObtained}
              setNutrientsSuccessfullyObtained={
                setNutrientsSuccessfullyObtained
              }
              setCarEnabled={setCarEnabled}
              setDisplayCarModule={setDisplayCarModule}
              carHasIssues={carHasIssues}
              setCarHasIssues={setCarHasIssues}
              carHasBeenRepaired={carHasBeenRepaired}
              setCarHasBeenRepaired={setCarHasBeenRepaired}
              blockMenuSlide={blockMenuSlide}
              currentMenuPage={currentMenuPage}
              setCurrentMenuPage={setCurrentMenuPage}
            />
          </div>
        }

        {displayOverviews
          ? null
          : displayMenuSlide
          ? null
          : displayLedgerSlide
          ? null
          : displayLocationModule
          ? null
          : displayStoreBuilder
          ? null
          : renderLocationEventMap()}

        {displayOverviews ? null : displayMenuSlide ? null : displayLedgerSlide ? null : displayLocationModule ? null : displayStoreBuilder ? null : (
          <Avatar
            avatarModule={
              parsePlayer(sessionStorage.getItem('player')).location
            }
          />
        )}

        {displayTransportationModule ? (
          <TransportationModule
            nutrientsSuccessfullyObtained={nutrientsSuccessfullyObtained}
            setDisplayTransportationModule={setDisplayTransportationModule}
            setDisplayPlace={setDisplayLocationModule}
            setDisplayGameOver={setDisplayGameOver}
            setTimeIsUp={setTimeIsUp}
            setDisplayHomeModule={setDisplayHomeModule}
            setShowBusInstructions={setShowBusInstructions}
            module={module}
            carEnabled={carEnabled}
          />
        ) : null}

        {showBusInstructions ? (
          <BusInstructions setShowBusInstructions={setShowBusInstructions} />
        ) : null}

        {displayCarModule ? (
          <CarModule
            setDisplayCarModule={setDisplayCarModule}
            carHasIssues={carHasIssues}
            carHasBeenRepaired={carHasBeenRepaired}
          />
        ) : null}

        {displayStoreBuilder ? (
          <StoreBuilder
            setProductsForPayment={setProductsForPayment}
            setDisplayStoreBuilder={setDisplayStoreBuilder}
            setDisplayCheckout={setDisplayCheckout}
            module={module}
            wicCart={wicCart}
            setWicCart={setWicCart}
          />
        ) : null}

        {displayLocationModule ? (
          <LocationModule
            setDisplayStoreBuilder={setDisplayStoreBuilder}
            setDisplayLocationModule={setDisplayLocationModule}
            module={module}
          />
        ) : null}

        {displayHomeModule ? (
          <HomeModule
            nutrientsSuccessfullyObtained={nutrientsSuccessfullyObtained}
            setDisplayTransportationModule={setDisplayTransportationModule}
            setDisplayPlace={setDisplayLocationModule}
            setDisplayGameOver={setDisplayGameOver}
            setTimeIsUp={setTimeIsUp}
            setDisplayHomeModule={setDisplayHomeModule}
            module={module}
          />
        ) : null}

        {displayCheckout ? (
          <Checkout
            productsForPayment={productsForPayment}
            setDisplayCheckout={setDisplayCheckout}
            setProductsForPayment={setProductsForPayment}
            setDisplayStoreBuilder={setDisplayStoreBuilder}
            wicCart={wicCart}
            setWicCart={setWicCart}
          />
        ) : null}

        {displayOverviews ? null : displayMenuSlide ? null : displayLedgerSlide ? null : displayLocationModule ? null : displayStoreBuilder ? null : displayGameOver ? (
          <GameOver
            setDisplayGameOver={setDisplayGameOver}
            nutrientsSuccessfullyObtained={nutrientsSuccessfullyObtained}
            timeIsUp={timeIsUp}
            warningTime={warningTime}
          />
        ) : null}
      </div>
    </div>
  );
}

export default GameBoard;
