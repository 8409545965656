import { Product } from '../../types';
import { formatter } from '../../assets/functions/PlayerFunctions';
import CloseIcon from '../misc/CloseIcon';

function ShoppingCart(props: any) {
  let total = 0.0;
  props.products.map((product: any) =>
    product.quantity !== 0 ? (total += product.price * product.quantity) : null
  );

  function countProducts(): number {
    let toBuy = 0;
    props.products.forEach((product: any) => {
      if (product.quantity > 0) {
        toBuy += 1 * product.quantity;
      }
    });
    return toBuy;
  }

  const handleDelete = (product: Product) => {
    const products = [];
    products.push(...props.products);
    product.quantity = 0;
    props.setProducts(products);
    if (product.wicType.length > 0) {
      props.setWicCart({
        ...props.wicCart,
        [product.wicType[0]]: false,
      });
    }
  };

  return (
    <div>
      <div className={'store-builder__store__shopping-cart--container'}>
        <h3 className={'store-builder__store__shopping-cart--small--text'}>
          Cart
        </h3>
        <div
          className={
            'store-builder__store__shopping-cart--container__list-purchased-products--container'
          }
        >
          <table>
            <tbody>
              {props.products.map((product: any, index: number) =>
                product.quantity !== 0 ? (
                  <tr key={index}>
                    <td>{product.quantity}</td>
                    <td
                      className={
                        'store-builder__store__shopping-cart--container__list-purchased-products__product--name'
                      }
                    >
                      {product.name}
                    </td>
                    <td style={{ justifyContent: 'flex-end' }}>
                      ${formatter.format(product.price)}
                    </td>
                    <td
                      className={'store-builder__store__delete-icon--container'}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <CloseIcon
                        isDelete
                        name={product.name}
                        onClick={() => handleDelete(product)}
                      />
                    </td>
                    <td style={{ justifyContent: 'flex-end' }}>
                      {product.wicType.length > 0 ? 'WIC' : undefined}
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
        <p
          style={{ textAlign: 'center' }}
          className={'store-builder__store__shopping-cart--small--text'}
        >
          Total: ${formatter.format(total)}
        </p>
      </div>
      <span
        className={'store-builder__store__purchase--button'}
        onClick={function () {
          const toBuy = countProducts();
          if (toBuy === 0) {
            props.setDisplayInfoPopUp(true);
            props.setOption(2);
          } else if (props.storeName == 'Food Pantry' && toBuy > 2) {
            props.setDisplayInfoPopUp(true);
            props.setOption(1);
          } else {
            props.setProductsForPayment(props.products);
            props.setDisplayStoreBuilder(false);
            props.setDisplayCheckout(true);
          }
        }}
      >
        {props.storeName == 'Food Pantry' ? 'Complete Visit' : 'Purchase'}
      </span>
    </div>
  );
}

export default ShoppingCart;
