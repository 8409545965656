import logo from './../../assets/icons/profile.svg';

function UserCheck(props: any) {
  return (
    <div
      className={`dashboard__user-check__pod--container ${props.class}`}
      onClick={() => {
        props.activateSlide(true, 'UserCheck');
      }}
    >
      <img className={'dashboard__pod__icon'} src={logo} alt="User Icon" />
    </div>
  );
}

export default UserCheck;
