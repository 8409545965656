import { useState } from 'react';

import SessionManager from '../../assets/classes/SessionManager';
import {
  checkVoucher,
  dateOfBirth,
  formatter,
  fullName,
  parsePlayer,
  startingCash,
  totalAvailable,
  totalIncome,
} from '../../assets/functions/PlayerFunctions';
import timeCheck from '../../assets/icons/time-check.svg';
import menu from '../../assets/images/menu-popout.png';
import avatar from '../../assets/images/places/avatar-home.png';
import BudgetCheck from '../dashboard/BudgetCheck';
import NutritionCheck from '../dashboard/NutritionCheck';
import TimeCheck from '../dashboard/TimeCheck';
import UserCheck from '../dashboard/UserCheck';
import NutritionChart from '../misc/NutritionChart';
import Button from '../misc/Button';
import Point from '../misc/Point';
import TrasportationOptions from '../misc/TransportationOptions';
import end from './../../assets/icons/end.svg';
import restart from './../../assets/icons/restart.svg';
import help from './../../assets/icons/help.svg';
import ellipseOrange from '../../assets/icons/ellipse-orange.svg';

import './BoardOverview.css';

function BoardOverview(props: any) {
  const [index, setIndex] = useState<number>(0);

  if (sessionStorage.getItem('InSession') !== '1') {
    // todo(@victoria): figure out why session storage fails ts when player: Player here
    const player: any = parsePlayer(sessionStorage.getItem('player'));

    sessionStorage.setItem(
      'player',
      sessionStorage.getItem('playerInitState')
        ? sessionStorage.getItem('playerInitState')
        : player
    );
    sessionStorage.setItem('meals', '40');
  }

  const player = parsePlayer(sessionStorage.getItem('player'));
  const onClick = () => {
    props.setDisplayBoardOverviews(false);
    SessionManager.setActive(true);
  };

  const renderNextButton = () => (
    <Button
      arrowLeftShortIcon
      enable
      normalText
      onClick={
        index === 6
          ? onClick
          : () => {
              setIndex(index + 1);
            }
      }
      textValue={index === 7 ? 'Begin' : 'Next'}
    />
  );

  const renderBackButton = () => (
    <Button
      darkMode
      enable
      isGoBack
      normalText
      onClick={
        index === 0
          ? onClick
          : () => {
              setIndex(index - 1);
            }
      }
      textValue={index === 0 ? 'Skip' : 'Go Back'}
    />
  );

  function renderBoardOverviewPage() {
    switch (index) {
      case 0:
        props.transparentPods('');

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                How to utilize the simulation
              </h1>
              <img
                className={'board-overview--screenshot'}
                alt="Screenshot of menus"
                src={menu}
              />
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row first-slide'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        props.transparentPods('TimeCheck', true);

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Time check
              </h1>
              <div className={'board-overview--body__main-content tour'}>
                <TimeCheck />
                <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                  The amount of time left in your day will be displayed here.
                  <br />
                  Remember to acquire all of your food before the end of the
                  day.
                </p>
                <img
                  className={'board-overview--icon board-overview--preview'}
                  src={timeCheck}
                  alt="Time Check Icon"
                />
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={1} />
              </div>
            </div>
          </div>
        );
      case 2:
        props.transparentPods('NutritionCheck', true);

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Nutrition status
              </h1>
              <div className={'board-overview--body__main-content tour'}>
                <NutritionCheck />
                <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                  Acquire all of the calories and nutritious food you need for
                  the day.
                  <br />
                  Tap this nutrition meter to monitor your progress.
                </p>
                <NutritionChart className="board-overview--preview" />
                <div className="fade"></div>
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={2} />
              </div>
            </div>
          </div>
        );
      case 3:
        const budget = `$${formatter.format(Number(totalAvailable(player)))}`;
        const cash = `Cash: $${formatter.format(startingCash(player))}`;
        const eitc = `EITC: $${formatter.format(Number(player.eitcAcquired))}`;
        const ctc = `CTC: $${formatter.format(Number(player.ctcAcquired))}`;
        const snap = `SNAP: $${formatter.format(Number(player.snapFunds))}`;
        // const total = `TOTAL: $${formatter.format(
        //   Number(totalAvailable(player))
        // )}`;
        const wic = `WIC: ${checkVoucher(player)}`;
        const busTicket = `Bus ticket: ${player.busTickets}`;
        props.transparentPods('BudgetCheck', true);

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Budget
              </h1>
              <div className={'board-overview--body__main-content tour'}>
                <BudgetCheck />
                <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                  Your budget is reflected here. It could be a combination of
                  cash, SNAP,
                  <br />
                  and WIC benefits.
                </p>
                <div
                  className={
                    'board-overview--body__budget-content board-overview--preview'
                  }
                >
                  <h1>{budget}</h1>
                  <div className={'board-overview--cash-snap-total--container'}>
                    <p className={'board-overview--cash-snap-total'}>{cash}</p>
                    <p className={'board-overview--cash-snap-total'}>{eitc}</p>
                    <p className={'board-overview--cash-snap-total'}>{ctc}</p>
                    <p className={'board-overview--cash-snap-total'}>{snap}</p>
                  </div>
                  <span
                    className={'board-overview--body__budget-content__line'}
                  />
                  <div className={'board-overview--wic-bus-ticket--container'}>
                    <h3>{wic}</h3>
                    <h3>{busTicket}</h3>
                  </div>
                </div>
                <div className="fade"></div>
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={3} />
              </div>
            </div>
          </div>
        );
      case 4:
        props.transparentPods('UserCheck', true);

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Profile
              </h1>
              <div className={'board-overview--body__main-content tour'}>
                <UserCheck />
                <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                  Your profile is personalized to you, and contains important
                  information including your
                  <br />
                  family, income, and health status.
                </p>
                <div
                  className={
                    'ledger-slide__rectangle--body__content__user-check--container location-module__form--container board-overview--preview'
                  }
                >
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Name</h1>
                      <p>{fullName(player)}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>SSN</h1>
                      <p>{player.playerInfo.ssn}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>DOB</h1>
                      <p>{dateOfBirth(player)}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Age</h1>
                      <p>{player.playerInfo.age}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Address</h1>
                      <p>{player.playerInfo.address}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>City</h1>
                      <p>{player.playerInfo.city}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>State</h1>
                      <p>{player.playerInfo.state}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Zip Code</h1>
                      <p>{player.playerInfo.zip}</p>
                    </div>
                  </div>
                  <div className={'location-module__form__group'}>
                    <div className={'location-module__form__field'}>
                      <h1>Monthly Income</h1>
                      <p>{formatter.format(Number(totalIncome(player)))}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Married</h1>
                      <p>{player.playerInfo.married ? 'Yes' : 'No'}</p>
                    </div>
                    <div className={'location-module__form__field'}>
                      <h1>Phone Number</h1>
                      <p>{player.playerInfo.phone}</p>
                    </div>
                  </div>
                </div>
                <div className="fade"></div>
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={4} />
              </div>
            </div>
          </div>
        );
      case 5:
        props.transparentPods('MenuCheck', true);

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Menu Items
              </h1>
              <div className="board-overview--menu-container">
                <div className="board-overview--menu--icon-and-text">
                  <span className={'board-overview--menu--icon--container'}>
                    <img
                      className="board-overview--menu--icon--backdrop"
                      src={ellipseOrange}
                      alt="Help Icon"
                    />
                    <img
                      className="board-overview--menu--icon"
                      src={help}
                      alt="Help Icon"
                    />
                  </span>
                  <p>
                    To get help navigating the community or purchasing food,
                    click here.{' '}
                  </p>
                </div>
                <div className="board-overview--menu--icon-and-text">
                  <span className={'board-overview--menu--icon--container'}>
                    <img
                      className="board-overview--menu--icon--backdrop"
                      src={ellipseOrange}
                      alt="Help Icon"
                    />
                    <img
                      className="board-overview--menu--icon"
                      src={end}
                      alt="End Icon"
                    />
                  </span>
                  <p>To end your day and click here.</p>
                </div>
                <div className="board-overview--menu--icon-and-text">
                  <span className={'board-overview--menu--icon--container'}>
                    <img
                      className="board-overview--menu--icon--backdrop"
                      src={ellipseOrange}
                      alt="Help Icon"
                    />
                    <img
                      className="board-overview--menu--icon"
                      src={restart}
                      alt="Restart Icon"
                    />
                  </span>
                  <p>To restart your day click here.</p>
                </div>
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={5} />
              </div>
            </div>
          </div>
        );
      case 6:
        props.transparentPods('');

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Getting around
              </h1>
              <TrasportationOptions
                carContent={
                  <p>
                    If you have a car, click on where to go and select
                    `&quot;car`&quot;
                  </p>
                }
                busContent={
                  <p>
                    To use the bus, click on and walk to the bus stop nearest
                    you. Then, select the bus stop nearest your destination and
                    ride the bus. Walk from your new bus stop to your
                    destination.
                  </p>
                }
                walkContent={
                  <p>You&apos;re always welcome to walk the community</p>
                }
              />
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={6} />
              </div>
            </div>
          </div>
        );
      case 7:
        props.transparentPods('');

        return (
          <div className={'board-overview--wrapper tour'}>
            <div className={'board-overview--body tour'}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Let&apos;s get started
              </h1>
              <div className={'board-overview--body__main-content tour'}>
                <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                  You&apos;ll begin your experience at home, in the top left
                  <br />
                  corner of the board
                </p>
                <img
                  className={'board-overview--avatar'}
                  alt="Screenshot of avatar"
                  src={avatar}
                />
              </div>
              <div
                className={
                  'button--container--column board-overview--button--container--tour'
                }
              >
                <div className={'button--container--row'}>
                  {renderBackButton()}
                  {renderNextButton()}
                </div>
                <Point numberOfPoints={7} currentPoint={7} />
              </div>
            </div>
          </div>
        );
    }
  }

  return (
    <div className={'board-overview--container'}>
      {renderBoardOverviewPage()}
      <span className={'board-overview--background'} />
    </div>
  );
}

export default BoardOverview;
